import React, { useState } from 'react';
import '../../styles/animations.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMoneyBillWave,
  faCalendarAlt,
  faCheckCircle,
  faExclamationCircle,
  faClock,
  faFileInvoiceDollar,
  faPlus
} from '@fortawesome/free-solid-svg-icons';

const Payments = () => {
  const [showForm, setShowForm] = useState(false);

  // Static EMI data
  const staticPayments = {
    upcomingPayments: [
      { _id: '1', plotNumber: 'A-101', amount: 25000, dueDate: '2025-04-15', status: 'Pending' },
      { _id: '2', plotNumber: 'B-202', amount: 30000, dueDate: '2025-04-20', status: 'Pending' },
    ],
    paymentHistory: [
      { _id: '3', date: '2025-03-15', description: 'EMI for Plot #A-101', amount: 25000, status: 'Paid', transactionId: 'TRX12345' },
      { _id: '4', date: '2025-02-15', description: 'EMI for Plot #B-202', amount: 30000, status: 'Paid', transactionId: 'TRX12346' },
    ]
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(amount);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-IN', { day: 'numeric', month: 'short', year: 'numeric' });
  };

  const getStatusColor = (status) => {
    return status === 'Paid' ? 'text-[#1DCD9F]' : status === 'Pending' ? 'text-yellow-600' : 'text-red-600';
  };

  const getStatusIcon = (status) => {
    return status === 'Paid' ? <FontAwesomeIcon icon={faCheckCircle} className="text-[#1DCD9F]" /> :
           status === 'Pending' ? <FontAwesomeIcon icon={faClock} className="text-yellow-500" /> :
           <FontAwesomeIcon icon={faExclamationCircle} className="text-red-500" />;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowForm(false);
    // Add your form submission logic here
  };

  return (
    <div style={{ backgroundColor: '#F1EFEC', minHeight: '100vh', padding: '2rem' }}>
      <div className="max-w-7xl mx-auto">
        <div className="flex items-center justify-between mb-8">
          <div className="flex items-center space-x-4">
            <h1 style={{ color: '#222222' }} className="text-3xl font-bold">
              EMI & Payments
            </h1>
            <p style={{ color: '#666666' }}>Manage your payments</p>
          </div>
          <button
            onClick={() => setShowForm(true)}
            style={{ backgroundColor: '#1DCD9F', color: '#FFFFFF' }}
            className="flex items-center px-4 py-2 rounded-lg hover:opacity-90 transition-all"
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Add New EMI
          </button>
        </div>

        {/* Upcoming Payments */}
        <div style={{ backgroundColor: '#FFFFFF', borderColor: '#1DCD9F' }} className="border rounded-xl mb-8 p-6">
          <h3 style={{ color: '#222222' }} className="text-xl font-bold flex items-center mb-4">
            <FontAwesomeIcon icon={faCalendarAlt} style={{ color: '#1DCD9F' }} className="mr-3" />
            Upcoming Payments
          </h3>
          <div>
            {staticPayments.upcomingPayments.map((payment) => (
              <div key={payment._id} className="flex items-center justify-between py-3 border-b last:border-b-0">
                <div className="flex items-center">
                  <FontAwesomeIcon icon={faFileInvoiceDollar} style={{ color: '#666666' }} className="mr-3" />
                  <div>
                    <p style={{ color: '#222222' }} className="font-medium">
                      EMI for Plot #{payment.plotNumber}
                    </p>
                    <p style={{ color: '#666666' }} className="text-sm">
                      Due on {formatDate(payment.dueDate)}
                    </p>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <span style={{ color: '#222222' }} className="font-semibold">
                    {formatCurrency(payment.amount)}
                  </span>
                  <button
                    style={{ backgroundColor: '#1DCD9F', color: '#FFFFFF' }}
                    className="px-4 py-2 rounded-lg hover:opacity-90 transition-all"
                  >
                    Pay Now
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Payment History */}
        <div style={{ backgroundColor: '#FFFFFF', borderColor: '#1DCD9F' }} className="border rounded-xl p-6">
          <h3 style={{ color: '#222222' }} className="text-xl font-bold flex items-center mb-4">
            <FontAwesomeIcon icon={faMoneyBillWave} style={{ color: '#1DCD9F' }} className="mr-3" />
            Payment History
          </h3>
          <table style={{ color: '#222222' }} className="w-full">
            <thead style={{ backgroundColor: '#F1EFEC' }}>
              <tr>
                <th className="px-4 py-2 text-left">Date</th>
                <th className="px-4 py-2 text-left">Description</th>
                <th className="px-4 py-2 text-left">Amount</th>
                <th className="px-4 py-2 text-left">Status</th>
                <th className="px-4 py-2 text-left">Transaction ID</th>
              </tr>
            </thead>
            <tbody>
              {staticPayments.paymentHistory.map((payment) => (
                <tr key={payment._id} className="border-b">
                  <td className="px-4 py-3">{formatDate(payment.date)}</td>
                  <td className="px-4 py-3">{payment.description}</td>
                  <td className="px-4 py-3 font-medium">{formatCurrency(payment.amount)}</td>
                  <td className="px-4 py-3">
                    <div className="flex items-center">
                      {getStatusIcon(payment.status)}
                      <span className={`ml-2 ${getStatusColor(payment.status)}`}>
                        {payment.status}
                      </span>
                    </div>
                  </td>
                  <td className="px-4 py-3">{payment.transactionId || '-'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* EMI Form Modal */}
        {showForm && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div style={{ backgroundColor: '#FFFFFF' }} className="rounded-xl p-6 w-full max-w-md">
              <h2 style={{ color: '#222222' }} className="text-2xl font-bold mb-4">Add New EMI</h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label style={{ color: '#222222' }} className="block mb-1">Plot Number</label>
                  <input
                    type="text"
                    style={{ borderColor: '#1DCD9F', color: '#222222' }}
                    className="w-full p-2 border rounded-lg"
                    placeholder="e.g., A-101"
                  />
                </div>
                <div className="mb-4">
                  <label style={{ color: '#222222' }} className="block mb-1">Amount (INR)</label>
                  <input
                    type="number"
                    style={{ borderColor: '#1DCD9F', color: '#222222' }}
                    className="w-full p-2 border rounded-lg"
                    placeholder="e.g., 25000"
                  />
                </div>
                <div className="mb-4">
                  <label style={{ color: '#222222' }} className="block mb-1">Due Date</label>
                  <input
                    type="date"
                    style={{ borderColor: '#1DCD9F', color: '#222222' }}
                    className="w-full p-2 border rounded-lg"
                  />
                </div>
                <div className="flex justify-end space-x-2">
                  <button
                    type="button"
                    onClick={() => setShowForm(false)}
                    style={{ backgroundColor: '#F1EFEC', color: '#222222' }}
                    className="px-4 py-2 rounded-lg"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    style={{ backgroundColor: '#1DCD9F', color: '#FFFFFF' }}
                    className="px-4 py-2 rounded-lg hover:opacity-90 transition-all"
                  >
                    Add EMI
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Payments;