import React from 'react';
import businessPlan from '../assets/images/business-plan.jpeg';

const BusinessPlanChart = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-[#49c2c7]/5 via-[#25b2da]/10 to-[#0d61b5]/5 py-4 sm:py-6 md:py-8">
      <div className="container mx-auto px-3 sm:px-4 md:px-6">
        <div className="bg-white/90 backdrop-blur-sm rounded-lg sm:rounded-xl md:rounded-2xl shadow-xl overflow-hidden border border-[#25b2da]/30">
          {/* Header */}
          <div className="bg-gradient-to-r from-[#13407e] via-[#0d61b5] to-[#25b2da] text-white px-4 sm:px-6 py-4 sm:py-5">
            <h1 className="text-lg sm:text-xl font-semibold">Business Plan Chart</h1>
          </div>

          {/* Content */}
          <div className="p-4 sm:p-6 md:p-8">
            {/* Image Container */}
            <div className="relative group">
              <div className="overflow-hidden rounded-lg sm:rounded-xl shadow-lg">
                <img 
                  src={businessPlan} 
                  alt="Business Plan Chart" 
                  className="w-full h-auto object-cover transform hover:scale-105 transition-transform duration-300"
                />
              </div>
              
              {/* Image Overlay */}
              <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-end">
                <div className="p-4 sm:p-6 text-white w-full">
                  <h2 className="text-lg sm:text-xl font-semibold mb-2">MLM Real Estate Business Plan</h2>
                  <p className="text-sm sm:text-base">Comprehensive chart showing the business structure and growth opportunities</p>
                </div>
              </div>
            </div>

            {/* Additional Information */}
            <div className="mt-6 sm:mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
              <div className="bg-[#49c2c7]/5 p-4 sm:p-5 rounded-lg border border-[#25b2da]/20">
                <h3 className="text-[#13407e] font-semibold mb-2">Investment Plans</h3>
                <p className="text-sm text-gray-600">Explore various investment opportunities and growth potential in real estate.</p>
              </div>
              
              <div className="bg-[#25b2da]/5 p-4 sm:p-5 rounded-lg border border-[#25b2da]/20">
                <h3 className="text-[#13407e] font-semibold mb-2">Commission Structure</h3>
                <p className="text-sm text-gray-600">Understand the detailed commission breakdown and earning potential.</p>
              </div>
              
              <div className="bg-[#0d61b5]/5 p-4 sm:p-5 rounded-lg border border-[#25b2da]/20">
                <h3 className="text-[#13407e] font-semibold mb-2">Growth Trajectory</h3>
                <p className="text-sm text-gray-600">View the projected growth path and advancement opportunities.</p>
              </div>
            </div>

            {/* Download Button */}
            <div className="mt-6 sm:mt-8 flex justify-center">
              <button className="bg-gradient-to-r from-[#13407e] via-[#0d61b5] to-[#25b2da] text-white px-6 sm:px-8 py-2.5 sm:py-3 rounded-lg 
                hover:from-[#0d61b5] hover:via-[#25b2da] hover:to-[#13407e] transition-all duration-500 transform hover:scale-105 
                focus:ring-4 focus:ring-[#25b2da]/20 shadow-md hover:shadow-lg active:scale-95">
                Download Business Plan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessPlanChart;