import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";

const TeamMemberList = () => {
  const teamMembers = [
    {
      id: 1,
      fullName: "Aarav Sharma",
      designation: "Software Engineer",
      photo: "https://randomuser.me/api/portraits/men/1.jpg",
    },
    {
      id: 2,
      fullName: "Ishita Verma",
      designation: "Project Manager",
      photo: "https://randomuser.me/api/portraits/women/2.jpg",
    },
    {
      id: 3,
      fullName: "Rajesh Gupta",
      designation: "UI/UX Designer",
      photo: "https://randomuser.me/api/portraits/men/3.jpg",
    },
    {
      id: 4,
      fullName: "Neha Patel",
      designation: "Data Scientist",
      photo: "https://randomuser.me/api/portraits/women/4.jpg",
    },
    {
      id: 5,
      fullName: "Vikram Singh",
      designation: "DevOps Engineer",
      photo: "https://randomuser.me/api/portraits/men/5.jpg",
    },
    {
      id: 6,
      fullName: "Ananya Das",
      designation: "Marketing Specialist",
      photo: "https://randomuser.me/api/portraits/women/6.jpg",
    },
    {
      id: 7,
      fullName: "Siddharth Malhotra",
      designation: "Backend Developer",
      photo: "https://randomuser.me/api/portraits/men/7.jpg",
    },
    {
      id: 8,
      fullName: "Pooja Reddy",
      designation: "HR Manager",
      photo: "https://randomuser.me/api/portraits/women/8.jpg",
    },
    {
      id: 9,
      fullName: "Arjun Mehta",
      designation: "Product Manager",
      photo: "https://randomuser.me/api/portraits/men/9.jpg",
    },
    {
      id: 10,
      fullName: "Kavita Nair",
      designation: "Financial Analyst",
      photo: "https://randomuser.me/api/portraits/women/10.jpg",
    },
    {
      id: 11,
      fullName: "Rohan Desai",
      designation: "Quality Assurance Engineer",
      photo: "https://randomuser.me/api/portraits/men/11.jpg",
    },
    {
      id: 12,
      fullName: "Meera Iyer",
      designation: "Content Writer",
      photo: "https://randomuser.me/api/portraits/women/12.jpg",
    },
    {
      id: 13,
      fullName: "Amitabh Joshi",
      designation: "Network Administrator",
      photo: "https://randomuser.me/api/portraits/men/13.jpg",
    },
    {
      id: 14,
      fullName: "Sneha Kapoor",
      designation: "Business Analyst",
      photo: "https://randomuser.me/api/portraits/women/14.jpg",
    },
    {
      id: 15,
      fullName: "Nikhil Choudhury",
      designation: "Frontend Developer",
      photo: "https://randomuser.me/api/portraits/men/15.jpg",
    },
    {
      id: 16,
      fullName: "Ritu Saxena",
      designation: "Legal Advisor",
      photo: "https://randomuser.me/api/portraits/women/16.jpg",
    },
    {
      id: 17,
      fullName: "Pranav Bhat",
      designation: "Systems Engineer",
      photo: "https://randomuser.me/api/portraits/men/17.jpg",
    },
    {
      id: 18,
      fullName: "Divya Menon",
      designation: "Graphic Designer",
      photo: "https://randomuser.me/api/portraits/women/18.jpg",
    },
    {
      id: 19,
      fullName: "Karan Thakur",
      designation: "Database Administrator",
      photo: "https://randomuser.me/api/portraits/men/19.jpg",
    },
    {
      id: 20,
      fullName: "Sonal Jain",
      designation: "Public Relations Officer",
      photo: "https://randomuser.me/api/portraits/women/20.jpg",
    },
    {
      id: 21,
      fullName: "Harish Pillai",
      designation: "Security Analyst",
      photo: "https://randomuser.me/api/portraits/men/21.jpg",
    },
    {
      id: 22,
      fullName: "Lata Mukherjee",
      designation: "Operations Manager",
      photo: "https://randomuser.me/api/portraits/women/22.jpg",
    },
    {
      id: 23,
      fullName: "Manoj Kumar",
      designation: "Technical Support Specialist",
      photo: "https://randomuser.me/api/portraits/men/23.jpg",
    },
    {
      id: 24,
      fullName: "Priyanka Sinha",
      designation: "Sales Executive",
      photo: "https://randomuser.me/api/portraits/women/24.jpg",
    },
    {
      id: 25,
      fullName: "Suresh Yadav",
      designation: "Logistics Coordinator",
      photo: "https://randomuser.me/api/portraits/men/25.jpg",
    },
    {
      id: 26,
      fullName: "Anjali Roy",
      designation: "Customer Service Representative",
      photo: "https://randomuser.me/api/portraits/women/26.jpg",
    },
    {
      id: 27,
      fullName: "Tarun Khanna",
      designation: "Mobile App Developer",
      photo: "https://randomuser.me/api/portraits/men/27.jpg",
    },
    {
      id: 28,
      fullName: "Swati Agarwal",
      designation: "SEO Specialist",
      photo: "https://randomuser.me/api/portraits/women/28.jpg",
    },
    {
      id: 29,
      fullName: "Gaurav Mishra",
      designation: "Cloud Solutions Architect",
      photo: "https://randomuser.me/api/portraits/men/29.jpg",
    },
    {
      id: 30,
      fullName: "Rashmi Kaur",
      designation: "Event Coordinator",
      photo: "https://randomuser.me/api/portraits/women/30.jpg",
    },
    {
      id: 31,
      fullName: "Abhishek Dubey",
      designation: "AI/ML Engineer",
      photo: "https://randomuser.me/api/portraits/men/31.jpg",
    },
    {
      id: 32,
      fullName: "Snehal Patil",
      designation: "Copywriter",
      photo: "https://randomuser.me/api/portraits/women/32.jpg",
    },
    {
      id: 33,
      fullName: "Vivek Reddy",
      designation: "IT Support Technician",
      photo: "https://randomuser.me/api/portraits/men/33.jpg",
    },
    {
      id: 34,
      fullName: "Pallavi Joshi",
      designation: "Social Media Manager",
      photo: "https://randomuser.me/api/portraits/women/34.jpg",
    },
    {
      id: 35,
      fullName: "Rahul Nair",
      designation: "Embedded Systems Engineer",
      photo: "https://randomuser.me/api/portraits/men/35.jpg",
    },
    {
      id: 36,
      fullName: "Deepika Rao",
      designation: "Recruitment Specialist",
      photo: "https://randomuser.me/api/portraits/women/36.jpg",
    },
  ];
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const membersPerPage = 5;

  const indexOfLast = currentPage * membersPerPage;
  const indexOfFirst = indexOfLast - membersPerPage;
  const currentMembers = teamMembers.slice(indexOfFirst, indexOfLast);
  const totalPages = Math.ceil(teamMembers.length / membersPerPage);

  const renderPageNumbers = () => {
    const pages = [];

    if (totalPages <= 4) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      pages.push(1);
      if (currentPage > 3) pages.push("...");

      const start = Math.max(2, currentPage - 1);
      const end = Math.min(totalPages - 1, currentPage + 1);

      for (let i = start; i <= end; i++) {
        pages.push(i);
      }

      if (currentPage < totalPages - 2) pages.push("...");
      pages.push(totalPages);
    }

    return pages.map((page, i) =>
      page === "..." ? (
        <span key={i} className="px-2 text-gray-500 select-none">
          ...
        </span>
      ) : (
        <button
          key={i}
          onClick={() => setCurrentPage(page)}
          className={`px-3 py-1 rounded-full text-sm font-medium ${
            currentPage === page
              ? "bg-blue-600 text-white"
              : "bg-gray-200 text-gray-700 hover:bg-gray-300"
          }`}
        >
          {page}
        </button>
      )
    );
  };

  const handlePrev = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNext = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  return (
    <div className="px-4 sm:px-8 py-8 w-full max-w-6xl mx-auto bg-white">
      <h2 className="text-2xl font-bold mb-6 text-center">Team Member List</h2>

      <div className="overflow-x-auto rounded-lg shadow bg-white">
        <table className="min-w-full">
          <thead className="bg-gray-100">
            <tr>
              <th className="text-left py-3 px-4 text-sm font-semibold text-gray-700">
                Photo
              </th>
              <th className="text-left py-3 px-4 text-sm font-semibold text-gray-700">
                Full Name
              </th>
              <th className="text-left py-3 px-4 text-sm font-semibold text-gray-700">
                Designation
              </th>
            </tr>
          </thead>
          <tbody>
            {currentMembers.map((member) => (
              <tr
                key={member.id}
                className="border-t hover:bg-gray-50 transition duration-200"
              >
                <td className="py-3 px-4">
                  <button onClick={() => setSelectedImage(member.photo)}>
                    <img
                      src={member.photo}
                      alt={member.fullName}
                      className="w-12 h-12 rounded-full object-cover hover:scale-105 transition"
                    />
                  </button>
                </td>
                <td className="py-3 px-4 font-medium text-gray-800">
                  {member.fullName}
                </td>
                <td className="py-3 px-4 text-gray-600">
                  {member.designation}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* IMAGE PR CLICK, IMAGE BIG. */}
      {selectedImage && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center z-50">
          {/* Close Button */}
          <button
            onClick={() => setSelectedImage(null)}
            className="absolute top-20 right-32 bg-white p-1 rounded-full shadow hover:bg-gray-100"
          >
            <IoMdClose className="text-black text-xl" />
          </button>
          <div className="relative">
            {/* Enlarged Image */}
            <img
              src={selectedImage}
              alt="Enlarged"
              className="max-w-full max-h-[90vh] rounded-lg shadow-lg border-4 border-white"
            />
          </div>
        </div>
      )}

      {/* Pagination Controls */}
      <div className="flex justify-center items-center mt-6 gap-2 flex-wrap">
        <button
          onClick={handlePrev}
          disabled={currentPage === 1}
          className="p-2 rounded-full bg-gray-200 hover:bg-gray-300 disabled:opacity-50"
        >
          <FaChevronLeft size={14} />
        </button>

        {renderPageNumbers()}

        <button
          onClick={handleNext}
          disabled={currentPage === totalPages}
          className="p-2 rounded-full bg-gray-200 hover:bg-gray-300 disabled:opacity-50"
        >
          <FaChevronRight size={14} />
        </button>
      </div>
    </div>
  );
};

export default TeamMemberList;
