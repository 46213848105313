import React, { useState } from 'react';
import { FiMapPin, FiLayers, FiCalendar, FiFilter, FiX, FiChevronDown, FiCheck } from 'react-icons/fi';
import { FaRupeeSign, FaBed, FaBath, FaRulerCombined } from 'react-icons/fa';
import {Link} from 'react-router-dom'

const PlotAvailability = () => {
  // Sample plot data with Indian prices and locations
  const allPlots = [
    {
      id: 1,
      title: "Premium Residential Plot",
      location: "Whitefield, Bangalore",
      price: "₹75,00,000",
      size: "2400 sq.ft.",
      availability: "Ready for Construction",
      image: "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
      features: ["Gated Community", "24/7 Security", "Water Supply"],
      description: "This premium residential plot is located in the heart of Whitefield, Bangalore's fastest-growing IT corridor. The plot comes with all necessary approvals and is ready for immediate construction. The gated community offers modern amenities and excellent connectivity to major tech parks and schools.",
      contact: "Rajesh Properties - 9876543210",
      approvalStatus: "Approved by BDA",
      facing: "East",
      soilType: "Red Soil",
      distance: {
        metro: "2 km",
        hospital: "5 km",
        school: "3 km"
      }
    },
    {
      id: 2,
      title: "Luxury Farm Plot",
      location: "Lonavala, Maharashtra",
      price: "₹1,20,00,000",
      size: "1 Acre",
      availability: "Available Now",
      image: "https://images.unsplash.com/photo-1580587771525-78b9dba3b914?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
      features: ["Scenic Views", "Organic Farming", "Peaceful Location"],
      description: "A luxury farm plot in the lush greenery of Lonavala, perfect for weekend homes or organic farming. The location provides stunning scenic views and a peaceful atmosphere away from city chaos.",
      contact: "Vikram Estates - 9823456789",
      approvalStatus: "Approved by Maharashtra Land Authority",
      facing: "North-East",
      soilType: "Black Soil",
      distance: {
        metro: "N/A",
        hospital: "8 km",
        school: "6 km"
      }
    },
    {
      id: 3,
      title: "Commercial Plot",
      location: "Gurugram, Haryana",
      price: "₹2,50,00,000",
      size: "3000 sq.ft.",
      availability: "Coming Soon",
      image: "https://images.unsplash.com/photo-1512917774080-9991f1c4c750?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
      features: ["Prime Location", "High Visibility", "Commercial Zone"],
      description: "A high-demand commercial plot in the heart of Gurugram, ideal for retail or office spaces. Located in a bustling area with excellent connectivity and high ROI potential.",
      contact: "Kumar Developers - 9812345678",
      approvalStatus: "Approved by HUDA",
      facing: "West",
      soilType: "Mixed Soil",
      distance: {
        metro: "1.5 km",
        hospital: "4 km",
        school: "2 km"
      }
    },
    {
      id: 4,
      title: "Affordable Plot",
      location: "Chennai OMR",
      price: "₹35,00,000",
      size: "1200 sq.ft.",
      availability: "Ready for Construction",
      image: "https://images.unsplash.com/photo-1564013799919-ab600027ffc6?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
      features: ["Near IT Park", "Good Connectivity", "Approved Layout"],
      description: "Affordable plot near Chennai's IT corridor, ideal for young professionals and families. The plot is well-connected and offers a secure investment opportunity.",
      contact: "Sundar Real Estates - 9945123456",
      approvalStatus: "DTCP Approved",
      facing: "South",
      soilType: "Clay Soil",
      distance: {
        metro: "3 km",
        hospital: "5 km",
        school: "2.5 km"
      }
    },
    {
      id: 5,
      title: "Beachfront Plot",
      location: "Goa",
      price: "₹3,50,00,000",
      size: "5000 sq.ft.",
      availability: "Limited Availability",
      image: "https://images.unsplash.com/photo-1512917774080-9991f1c4c750?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
      features: ["Direct Beach Access", "Tourist Zone", "High Appreciation"],
      description: "A rare opportunity to own a beachfront plot in Goa with direct access to the sea. Perfect for luxury villas or resorts with excellent appreciation potential.",
      contact: "Goa Coastal Properties - 9934567890",
      approvalStatus: "CRZ Approved",
      facing: "West",
      soilType: "Sandy Soil",
      distance: {
        metro: "N/A",
        hospital: "6 km",
        school: "4 km"
      }
    },
    {
      id: 6,
      title: "Hill Station Plot",
      location: "Coorg, Karnataka",
      price: "₹90,00,000",
      size: "2 Acres",
      availability: "Available Now",
      image: "https://images.unsplash.com/photo-1605146769289-440113cc3d00?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60",
      features: ["Coffee Estate", "Cool Climate", "Eco-Friendly"],
      description: "A picturesque hill station plot in Coorg, ideal for nature lovers and coffee estate enthusiasts. Enjoy a cool climate and serene surroundings.",
      contact: "Green Valley Estates - 9876123456",
      approvalStatus: "Approved by Karnataka Land Authority",
      facing: "North",
      soilType: "Loamy Soil",
      distance: {
        metro: "N/A",
        hospital: "10 km",
        school: "8 km"
      }
    }
  ];


  // State for filters
  const [priceRange, setPriceRange] = useState([0, 50000000]);
  const [sizeFilter, setSizeFilter] = useState('');
  const [locationFilter, setLocationFilter] = useState('');
  const [availabilityFilter, setAvailabilityFilter] = useState('all');
  const [showFilters, setShowFilters] = useState(false);
  const [sortOption, setSortOption] = useState('');
  const [selectedPlot, setSelectedPlot] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Filter plots based on selected filters
  let filteredPlots = allPlots.filter(plot => {
    const plotPrice = Number(plot.price.replace(/[^0-9.-]+/g, ""));
    const priceMatch = plotPrice >= priceRange[0] && plotPrice <= priceRange[1];
    const sizeMatch = sizeFilter === '' || plot.size.includes(sizeFilter);
    const locationMatch =
      locationFilter === '' ||
      plot.location.toLowerCase().includes(locationFilter.toLowerCase());
    const availabilityMatch =
      availabilityFilter === 'all' ||
      plot.availability.toLowerCase().includes(availabilityFilter.toLowerCase());
    return priceMatch && sizeMatch && locationMatch && availabilityMatch;
  });

  // Sort plots
  if (sortOption === 'price-low-high') {
    filteredPlots = [...filteredPlots].sort((a, b) => {
      const priceA = Number(a.price.replace(/[^0-9.-]+/g, ""));
      const priceB = Number(b.price.replace(/[^0-9.-]+/g, ""));
      return priceA - priceB;
    });
  } else if (sortOption === 'price-high-low') {
    filteredPlots = [...filteredPlots].sort((a, b) => {
      const priceA = Number(a.price.replace(/[^0-9.-]+/g, ""));
      const priceB = Number(b.price.replace(/[^0-9.-]+/g, ""));
      return priceB - priceA;
    });
  }

  // Format price range for display
  const formatPrice = (amount) => {
    if (amount >= 10000000) {
      return `₹${(amount / 10000000).toFixed(2)} Cr`;
    } else if (amount >= 100000) {
      return `₹${(amount / 100000).toFixed(2)} Lakh`;
    }
    return `₹${amount.toLocaleString('en-IN')}`;
  };

  // Reset all filters
  const resetFilters = () => {
    setPriceRange([0, 50000000]);
    setSizeFilter('');
    setLocationFilter('');
    setAvailabilityFilter('all');
    setSortOption('');
  };

  // Open modal with plot details
  const openPlotModal = (plot) => {
    setSelectedPlot(plot);
    setShowModal(true);
    document.body.style.overflow = 'hidden'; // Prevent scrolling when modal is open
  };

  // Close modal
  const closeModal = () => {
    setShowModal(false);
    document.body.style.overflow = 'auto'; // Re-enable scrolling
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 font-montserrat">
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Discover Your Perfect Plot</h1>
          <p className="text-lg text-gray-600 max-w-3xl mx-auto">
            Browse premium residential and commercial plots across India's most sought-after locations
          </p>
        </div>

        {/* Filter Section */}
        <div className="mb-8 bg-white p-6 rounded-xl shadow-sm border border-gray-100">
          <div className="flex justify-between items-center mb-4">
            <button
              onClick={() => setShowFilters(!showFilters)}
              className="flex items-center gap-2 text-gray-700 font-medium px-4 py-2 bg-gray-100 hover:bg-gray-200 rounded-lg transition-colors"
            >
              <FiFilter className="text-gray-600" />
              {showFilters ? 'Hide Filters' : 'Show Filters'}
              <FiChevronDown className={`transition-transform ${showFilters ? 'rotate-180' : ''}`} />
            </button>

            {showFilters && (
              <button
                onClick={resetFilters}
                className="text-sm text-blue-600 hover:text-blue-800 font-medium flex items-center gap-1"
              >
                <FiX size={16} />
                Reset All
              </button>
            )}
          </div>

          {showFilters && (
            <div className="grid grid-cols-1 md:grid-cols-4 gap-6 pt-4 border-t border-gray-200">
              {/* Price Range Filter */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-3">Price Range</label>
                <div className="space-y-4">
                  <div className="flex items-center gap-4">
                    <input
                      type="range"
                      min="0"
                      max="50000000"
                      step="500000"
                      value={priceRange[0]}
                      onChange={(e) => setPriceRange([parseInt(e.target.value), priceRange[1]])}
                      className="w-full h-2 bg-blue-100 rounded-lg appearance-none cursor-pointer"
                    />
                  </div>
                  <div className="flex justify-between text-sm text-gray-600">
                    <span>{formatPrice(priceRange[0])}</span>
                    <span>{formatPrice(priceRange[1])}</span>
                  </div>
                </div>
              </div>

              {/* Size Filter */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Plot Size</label>
                <div className="relative">
                  <select
                    value={sizeFilter}
                    onChange={(e) => setSizeFilter(e.target.value)}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 appearance-none bg-white"
                  >
                    <option value="">All Sizes</option>
                    <option value="1200">1200 sq.ft.</option>
                    <option value="2400">2400 sq.ft.</option>
                    <option value="3000">3000 sq.ft.</option>
                    <option value="5000">5000 sq.ft.</option>
                    <option value="Acre">Acre+</option>
                  </select>
                  <FiChevronDown className="absolute right-3 top-3 text-gray-400" />
                </div>
              </div>

              {/* Location Filter */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Location</label>
                <div className="relative">
                  <select
                    value={locationFilter}
                    onChange={(e) => setLocationFilter(e.target.value)}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 appearance-none bg-white"
                  >
                    <option value="">All Locations</option>
                    <option value="Bangalore">Bangalore</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Gurugram">Gurugram</option>
                    <option value="Chennai">Chennai</option>
                    <option value="Goa">Goa</option>
                    <option value="Karnataka">Karnataka</option>
                  </select>
                  <FiChevronDown className="absolute right-3 top-3 text-gray-400" />
                </div>
              </div>

              {/* Availability Filter */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">Availability</label>
                <div className="relative">
                  <select
                    value={availabilityFilter}
                    onChange={(e) => setAvailabilityFilter(e.target.value)}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 appearance-none bg-white"
                  >
                    <option value="all">All Statuses</option>
                    <option value="available">Available Now</option>
                    <option value="ready">Ready for Construction</option>
                    <option value="coming">Coming Soon</option>
                    <option value="limited">Limited Availability</option>
                  </select>
                  <FiChevronDown className="absolute right-3 top-3 text-gray-400" />
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Results Count and Sorting */}
        <div className="mb-6 flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
          <p className="text-gray-600">
            Found <span className="font-semibold text-blue-600">{filteredPlots.length}</span> {filteredPlots.length === 1 ? 'plot' : 'plots'} matching your criteria
          </p>
          <div className="flex gap-2">
            <select
              value={sortOption}
              onChange={(e) => setSortOption(e.target.value)}
              className="px-4 py-2 border border-gray-300 rounded-lg text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            >
              <option value="">Sort By</option>
              <option value="price-low-high">Price: Low to High</option>
              <option value="price-high-low">Price: High to Low</option>
            </select>
          </div>
        </div>

        {/* Plots Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredPlots.map((plot) => (
            <div key={plot.id} className="bg-white rounded-xl overflow-hidden shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1">
              {/* Plot Image */}
              <div className="h-48 overflow-hidden relative">
                <img
                  src={plot.image}
                  alt={plot.title}
                  className="w-full h-full object-cover transition-transform duration-500 hover:scale-110"
                />
                <span className="absolute top-3 right-3 bg-white text-gray-800 text-xs font-semibold px-3 py-1 rounded-full shadow">
                  {plot.availability}
                </span>
              </div>

              {/* Plot Details */}
              <div className="p-6">
                <div className="mb-3">
                  <h3 className="text-xl font-bold text-gray-900 mb-1">{plot.title}</h3>
                  <p className="text-gray-600 flex items-center gap-1 text-sm">
                    <FiMapPin className="text-gray-400" />
                    {plot.location}
                  </p>
                </div>

                <div className="grid grid-cols-2 gap-4 mb-4">
                  <div className="flex items-center gap-2">
                    <div className="p-2 bg-blue-50 rounded-lg text-blue-600">
                      <FaRupeeSign />
                    </div>
                    <div>
                      <p className="text-xs text-gray-500">Price</p>
                      <p className="font-semibold text-gray-900">{plot.price}</p>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="p-2 bg-green-50 rounded-lg text-green-600">
                      <FiLayers />
                    </div>
                    <div>
                      <p className="text-xs text-gray-500">Size</p>
                      <p className="font-semibold text-gray-900">{plot.size}</p>
                    </div>
                  </div>
                </div>

                {/* Features */}
                <div className="mb-6">
                  <h4 className="text-sm font-semibold text-gray-700 mb-2">Features</h4>
                  <div className="flex flex-wrap gap-2">
                    {plot.features.map((feature, index) => (
                      <span key={index} className="bg-gray-100 text-gray-800 text-xs px-3 py-1 rounded-full">
                        {feature}
                      </span>
                    ))}
                  </div>
                </div>

                {/* Action Buttons */}
                <div className="flex gap-3">
                  <Link to='/contact-us' className="flex-1 bg-blue-600 hover:bg-blue-700 text-white py-3 px-4 rounded-lg font-medium transition-colors flex items-center justify-center gap-2">
                    Contact Seller
                  </Link>
                  <button
                    onClick={() => openPlotModal(plot)}
                    className="flex-1 border border-blue-600 text-blue-600 hover:bg-blue-50 py-3 px-4 rounded-lg font-medium transition-colors flex items-center justify-center gap-2"
                  >
                    View Details
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* No Results */}
        {filteredPlots.length === 0 && (
          <div className="text-center py-16 bg-white rounded-xl shadow-sm">
            <div className="max-w-md mx-auto">
              <svg className="mx-auto h-16 w-16 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <h3 className="text-xl font-medium text-gray-700 mb-2 mt-4">No plots match your filters</h3>
              <p className="text-gray-500 mb-6">Try adjusting your search criteria or reset all filters</p>
              <button
                onClick={resetFilters}
                className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 font-medium transition-colors"
              >
                Reset All Filters
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Plot Details Modal */}
      {showModal && selectedPlot && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            {/* Background overlay */}
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
              onClick={closeModal}
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            {/* Modal container */}
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    {/* Modal header */}
                    <div className="flex justify-between items-start">
                      <div>
                        <h3 className="text-2xl leading-6 font-bold text-gray-900 mb-1">
                          {selectedPlot.title}
                        </h3>
                        <p className="text-gray-600 flex items-center gap-1">
                          <FiMapPin className="text-gray-400" />
                          {selectedPlot.location}
                        </p>
                      </div>
                      <button
                        onClick={closeModal}
                        className="ml-4 bg-gray-100 hover:bg-gray-200 p-2 rounded-full"
                      >
                        <FiX className="text-gray-500" />
                      </button>
                    </div>

                    <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-8">
                      {/* Left column - Image and basic info */}
                      <div>
                        <div className="h-64 w-full rounded-lg overflow-hidden mb-4">
                          <img
                            src={selectedPlot.image}
                            alt={selectedPlot.title}
                            className="w-full h-full object-cover"
                          />
                        </div>

                        <div className="grid grid-cols-2 gap-4 mb-6">
                          <div className="bg-blue-50 p-3 rounded-lg">
                            <div className="flex items-center gap-2 text-blue-600 mb-1">
                              <FaRupeeSign />
                              <span className="font-medium">Price</span>
                            </div>
                            <p className="text-gray-900 font-semibold">{selectedPlot.price}</p>
                          </div>
                          <div className="bg-green-50 p-3 rounded-lg">
                            <div className="flex items-center gap-2 text-green-600 mb-1">
                              <FiLayers />
                              <span className="font-medium">Size</span>
                            </div>
                            <p className="text-gray-900 font-semibold">{selectedPlot.size}</p>
                          </div>
                          <div className="bg-purple-50 p-3 rounded-lg">
                            <div className="flex items-center gap-2 text-purple-600 mb-1">
                              <FiCalendar />
                              <span className="font-medium">Availability</span>
                            </div>
                            <p className="text-gray-900 font-semibold">{selectedPlot.availability}</p>
                          </div>
                          <div className="bg-yellow-50 p-3 rounded-lg">
                            <div className="flex items-center gap-2 text-yellow-600 mb-1">
                              <FiCheck />
                              <span className="font-medium">Approval</span>
                            </div>
                            <p className="text-gray-900 font-semibold">{selectedPlot.approvalStatus}</p>
                          </div>
                        </div>

                        <div className="mb-6">
                          <h4 className="text-lg font-semibold text-gray-900 mb-3">Key Features</h4>
                          <div className="flex flex-wrap gap-2">
                            {selectedPlot.features.map((feature, index) => (
                              <span
                                key={index}
                                className="bg-blue-50 text-blue-700 px-4 py-2 rounded-full text-sm font-medium"
                              >
                                {feature}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>

                      {/* Right column - Detailed information */}
                      <div>
                        <div className="mb-6">
                          <h4 className="text-lg font-semibold text-gray-900 mb-3">Description</h4>
                          <p className="text-gray-600">{selectedPlot.description}</p>
                        </div>

                        <div className="mb-6">
                          <h4 className="text-lg font-semibold text-gray-900 mb-3">Plot Details</h4>
                          <div className="grid grid-cols-2 gap-4">
                            <div>
                              <p className="text-sm text-gray-500">Facing Direction</p>
                              <p className="font-medium">{selectedPlot.facing}</p>
                            </div>
                            <div>
                              <p className="text-sm text-gray-500">Soil Type</p>
                              <p className="font-medium">{selectedPlot.soilType}</p>
                            </div>
                          </div>
                        </div>

                        <div className="mb-6">
                          <h4 className="text-lg font-semibold text-gray-900 mb-3">Distance From</h4>
                          <div className="grid grid-cols-2 gap-4">
                            <div className="flex items-center gap-2">
                              <div className="p-2 bg-blue-100 rounded-lg text-blue-600">
                                <FiMapPin />
                              </div>
                              <div>
                                <p className="text-sm text-gray-500">Nearest Metro</p>
                                <p className="font-medium">{selectedPlot.distance.metro}</p>
                              </div>
                            </div>
                            <div className="flex items-center gap-2">
                              <div className="p-2 bg-green-100 rounded-lg text-green-600">
                                <FiMapPin />
                              </div>
                              <div>
                                <p className="text-sm text-gray-500">Nearest Hospital</p>
                                <p className="font-medium">{selectedPlot.distance.hospital}</p>
                              </div>
                            </div>
                            <div className="flex items-center gap-2">
                              <div className="p-2 bg-purple-100 rounded-lg text-purple-600">
                                <FiMapPin />
                              </div>
                              <div>
                                <p className="text-sm text-gray-500">Nearest School</p>
                                <p className="font-medium">{selectedPlot.distance.school}</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="bg-gray-50 p-4 rounded-lg">
                          <h4 className="text-lg font-semibold text-gray-900 mb-2">Contact Information</h4>
                          <p className="text-gray-600 mb-3">{selectedPlot.contact}</p>
                          <button className="w-full bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-lg font-medium transition-colors">
                            Call Now
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Add the font import in your CSS or use a style tag */}
      <style jsx global>{`
        @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
        body {
          font-family: 'Montserrat', sans-serif;
        }
      `}</style>
    </div>
  );
};

export default PlotAvailability;