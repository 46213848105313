import React, { useState, useEffect } from 'react';
import '../../styles/animations.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faMoneyBillWave,
  faCalendarAlt,
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { sendApiRequest } from '../../utils';

const Overview = () => {
  const [dashboardData, setDashboardData] = useState({
    properties: [
      { name: "Sunset Villa", location: "Mumbai", value: 7500000, status: "Active" },
      { name: "Ocean Breeze", location: "Goa", value: 5200000, status: "Pending" },
    ],
    totalAmount: 12700000,
    paidAmount: 6500000,
    remainingAmount: 6200000,
    nextEmiDate: "2025-04-15",
    nextEmiAmount: 150000,
    recentTransactions: [
      { type: "EMI Payment", date: "2025-03-15", amount: 150000, status: "Success" },
      { type: "Down Payment", date: "2025-03-01", amount: 2000000, status: "Success" },
      { type: "Processing Fee", date: "2025-02-28", amount: 25000, status: "Failed" },
    ]
  });

  const [isLoading, setIsLoading] = useState(false);

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR'
    }).format(amount);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-IN', {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    });
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[60vh]">
        <div className="relative">
          <div className="animate-spin rounded-full h-12 w-12 md:h-16 md:w-16 border-4 border-[#1DCD9F]/20 border-l-[#1DCD9F]"></div>
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="h-6 w-6 md:h-8 md:w-8 rounded-full bg-[#1DCD9F]/10"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div style={{ backgroundColor: '#F1EFEC' }} className="min-h-screen py-4 md:py-6 lg:py-8">
      <div className="max-w-full md:max-w-4xl lg:max-w-7xl mx-auto px-2 sm:px-4 md:px-6 lg:px-8">
        <h1 style={{ color: '#222222' }} className="text-xl sm:text-2xl md:text-3xl font-semibold mb-4 md:mb-6">
          Dashboard Overview
        </h1>

        {/* Stats Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
          {/* Total Property Value */}
          <div className="bg-white/95 rounded-xl border border-[#222222]/20 hover:shadow-lg hover:border-[#1DCD9F]/50 transition-all duration-300 group">
            <div className="p-4 sm:p-5 md:p-6">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <div className="h-10 w-10 sm:h-12 sm:w-12 rounded-xl bg-[#1DCD9F]/10 flex items-center justify-center group-hover:scale-110 transition-transform duration-300">
                    <FontAwesomeIcon icon={faHome} style={{ color: '#1DCD9F' }} className="h-5 w-5 sm:h-6 sm:w-6" />
                  </div>
                </div>
                <div className="ml-3 sm:ml-4 flex-1">
                  <dl>
                    <dt style={{ color: '#222222' }} className="text-xs sm:text-sm font-medium truncate mb-1">
                      Total Property Value
                    </dt>
                    <dd style={{ color: '#1DCD9F' }} className="text-lg sm:text-xl md:text-2xl font-bold truncate">
                      {formatCurrency(dashboardData.totalAmount)}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>

          {/* Amount Paid */}
          <div className="bg-white rounded-lg shadow">
            <div className="p-4 sm:p-5">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <FontAwesomeIcon icon={faCheckCircle} style={{ color: '#1DCD9F' }} className="h-5 w-5 sm:h-6 sm:w-6" />
                </div>
                <div className="ml-3 sm:ml-4 flex-1">
                  <dl>
                    <dt style={{ color: '#222222' }} className="text-xs sm:text-sm font-medium truncate">
                      Amount Paid
                    </dt>
                    <dd style={{ color: '#222222' }} className="text-base sm:text-lg font-semibold mt-1 truncate">
                      {formatCurrency(dashboardData.paidAmount)}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>

          {/* Remaining Amount */}
          <div className="bg-white rounded-lg shadow">
            <div className="p-4 sm:p-5">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <FontAwesomeIcon icon={faMoneyBillWave} style={{ color: '#1DCD9F' }} className="h-5 w-5 sm:h-6 sm:w-6" />
                </div>
                <div className="ml-3 sm:ml-4 flex-1">
                  <dl>
                    <dt style={{ color: '#222222' }} className="text-xs sm:text-sm font-medium truncate">
                      Remaining Amount
                    </dt>
                    <dd style={{ color: '#222222' }} className="text-base sm:text-lg font-semibold mt-1 truncate">
                      {formatCurrency(dashboardData.remainingAmount)}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>

          {/* Next EMI */}
          <div className="bg-white rounded-lg shadow">
            <div className="p-4 sm:p-5">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <FontAwesomeIcon icon={faCalendarAlt} style={{ color: '#1DCD9F' }} className="h-5 w-5 sm:h-6 sm:w-6" />
                </div>
                <div className="ml-3 sm:ml-4 flex-1">
                  <dl>
                    <dt style={{ color: '#222222' }} className="text-xs sm:text-sm font-medium truncate">
                      Next EMI Due
                    </dt>
                    <dd style={{ color: '#222222' }} className="text-base sm:text-lg font-semibold mt-1 truncate">
                      {formatCurrency(dashboardData.nextEmiAmount)}
                    </dd>
                    <dd style={{ color: '#222222' }} className="text-xs sm:text-sm">
                      {dashboardData.nextEmiDate ? formatDate(dashboardData.nextEmiDate) : 'N/A'}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Properties List */}
        <div className="mt-6 md:mt-8">
          <div className="bg-white shadow rounded-lg">
            <div style={{ backgroundColor: '#F1EFEC', borderColor: '#222222' }} className="px-4 py-4 sm:px-6 sm:py-5 border-b">
              <h3 style={{ color: '#222222' }} className="text-base sm:text-lg md:text-xl font-medium">
                My Properties
              </h3>
            </div>
            <div style={{ borderColor: '#222222' }} className="divide-y">
              {dashboardData.properties.map((property, index) => (
                <div key={index} style={{ backgroundColor: '#FFFFFF' }} className="px-4 py-3 sm:px-6 sm:py-4 hover:bg-[#F1EFEC]">
                  <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between space-y-2 sm:space-y-0">
                    <div className="flex items-center">
                      <FontAwesomeIcon icon={faHome} style={{ color: '#1DCD9F' }} className="h-4 w-4 sm:h-5 sm:w-5 mr-2 sm:mr-3" />
                      <div>
                        <p style={{ color: '#1DCD9F' }} className="text-sm sm:text-base font-medium truncate">
                          {property.name}
                        </p>
                        <p style={{ color: '#222222' }} className="text-xs sm:text-sm truncate">
                          {property.location}
                        </p>
                      </div>
                    </div>
                    <div className="text-left sm:text-right">
                      <p style={{ color: '#222222' }} className="text-sm sm:text-base font-semibold truncate">
                        {formatCurrency(property.value)}
                      </p>
                      <p style={{ color: property.status === 'Active' ? '#1DCD9F' : '#222222' }} className="text-xs sm:text-sm">
                        {property.status}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Recent Transactions */}
        <div className="mt-6 md:mt-8">
          <div className="bg-white shadow rounded-lg">
            <div style={{ backgroundColor: '#F1EFEC', borderColor: '#222222' }} className="px-4 py-4 sm:px-6 sm:py-5 border-b">
              <h3 style={{ color: '#222222' }} className="text-base sm:text-lg md:text-xl font-medium">
                Recent Transactions
              </h3>
            </div>
            <div style={{ borderColor: '#222222' }} className="divide-y">
              {dashboardData.recentTransactions.map((transaction, index) => (
                <div key={index} style={{ backgroundColor: '#FFFFFF' }} className="px-4 py-3 sm:px-6 sm:py-4 hover:bg-[#F1EFEC]">
                  <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between space-y-2 sm:space-y-0">
                    <div className="flex items-center">
                      <FontAwesomeIcon 
                        icon={transaction.status === 'Success' ? faCheckCircle : faExclamationCircle} 
                        style={{ color: transaction.status === 'Success' ? '#1DCD9F' : '#222222' }}
                        className="h-4 w-4 sm:h-5 sm:w-5 mr-2 sm:mr-3" 
                      />
                      <div>
                        <p style={{ color: '#222222' }} className="text-sm sm:text-base font-medium truncate">
                          {transaction.type}
                        </p>
                        <p style={{ color: '#222222' }} className="text-xs sm:text-sm truncate">
                          {formatDate(transaction.date)}
                        </p>
                      </div>
                    </div>
                    <div className="text-left sm:text-right">
                      <p style={{ color: '#222222' }} className="text-sm sm:text-base font-semibold truncate">
                        {formatCurrency(transaction.amount)}
                      </p>
                      <p style={{ color: transaction.status === 'Success' ? '#1DCD9F' : '#222222' }} className="text-xs sm:text-sm">
                        {transaction.status}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;