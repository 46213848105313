import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn, FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaArrowRight } from 'react-icons/fa';
import { FaThreads } from "react-icons/fa6";

const Footer = () => {
  return (
    <footer className="relative bg-gradient-to-b from-gray-900 via-gray-800 to-black text-white overflow-hidden">
      {/* Decorative Elements */}
      <div className="absolute inset-0 bg-[url('data:image/svg+xml,...')] opacity-5"></div>
      <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-yellow-400 via-transparent to-yellow-400"></div>

      {/* Main Footer */}
      <div className="relative max-w-7xl mx-auto px-4 pt-24 pb-16">
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-16">
          {/* Company Info */}
          <div className="relative">
            <div className="absolute -inset-1 bg-gradient-to-r from-yellow-400 to-yellow-600 rounded-xl blur-xl opacity-10 animate-pulse"></div>
            <h3 className="text-3xl font-bold mb-6 bg-gradient-to-r from-yellow-400 via-yellow-200 to-yellow-400 bg-clip-text text-transparent">
              Prayag Properties
            </h3>
            <p className="text-gray-300 mb-8 leading-relaxed backdrop-blur-sm">
              Join our network of successful real estate professionals and unlock unlimited earning potential.
            </p>
            <div className="flex space-x-4">
              {[{ icon: FaFacebookF, link: 'https://www.facebook.com/share/1BqRbib6Dc/' }, { icon: FaTwitter, link: 'https://x.com/propertyprayag' }, , { icon: FaThreads, link: 'https://www.threads.net/@_prayagproperties_' }, { icon: FaInstagram, link: 'https://www.instagram.com/_prayagproperties_' }].map((Icon, index) => (
                <a
                  key={index}
                  href={Icon.link}
                  target='blank'
                  className="group relative bg-gray-800/50 backdrop-blur-sm w-12 h-12 rounded-xl flex items-center justify-center overflow-hidden border border-gray-700/50 hover:border-yellow-400/50 transition-all duration-300"
                >
                  <div className="absolute inset-0 bg-gradient-to-br from-yellow-400 to-yellow-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                  <Icon.icon className="relative text-lg group-hover:scale-110 transition-transform duration-300" />
                </a>
              ))}
            </div>
          </div>

          {/* Quick Links */}
          <div className="backdrop-blur-sm">
            <h4 className="text-xl font-semibold mb-8 relative inline-block">
              Quick Links
              <span className="absolute -bottom-2 left-0 w-12 h-0.5 bg-gradient-to-r from-yellow-400 to-yellow-600 rounded-full"></span>
            </h4>
            <ul className="space-y-4">
              {['About Us', 'Plot Availability', 'Gallery', 'Legal', 'Contact Us'].map((item) => (
                <li key={item}>
                  <Link
                    to={`/${item.toLowerCase().replace(' ', '-')}`}
                    className="group text-gray-300 hover:text-yellow-400 flex items-center transition-all duration-300"
                  >
                    <span className="relative overflow-hidden pr-4">
                      <FaArrowRight className="transform group-hover:translate-x-1 transition-transform duration-300" />
                      <FaArrowRight className="absolute top-0 -right-4 opacity-0 group-hover:opacity-100 transform group-hover:translate-x-1 transition-all duration-300" />
                    </span>
                    {item}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Info */}
          <div className="backdrop-blur-sm">
            <h4 className="text-xl font-semibold mb-8 relative inline-block">
              Contact Info
              <span className="absolute -bottom-2 left-0 w-12 h-0.5 bg-gradient-to-r from-yellow-400 to-yellow-600 rounded-full"></span>
            </h4>
            <ul className="space-y-6">
              <li className="group flex items-start">
                <div className="bg-gray-800/50 p-3 rounded-xl group-hover:bg-yellow-400 transition-all duration-300 border border-gray-700/50 group-hover:border-yellow-400/50">
                  <FaMapMarkerAlt className="text-yellow-400 group-hover:text-gray-900" />
                </div>
                <span className="text-gray-300 ml-4 mt-1 group-hover:text-yellow-400 transition-colors duration-300">
                  567 Saha Urf Pipalgaon
                  Near IIIT-A Chouraha
                  210112
                </span>
              </li>
              <li className="group flex items-center transform hover:-translate-y-1 transition-transform duration-300">
                <div className="bg-gray-800 p-3 rounded-lg group-hover:bg-yellow-400 transition-colors duration-300">
                  <FaPhoneAlt className="text-yellow-400 group-hover:text-gray-900 transition-colors duration-300" />
                </div>
                <a href="tel:+919555562580" className="text-gray-300 ml-4 hover:text-yellow-400">
                  +91 9555562580 , +91 6394342072
                </a>
              </li>
              <li className="group flex items-center transform hover:-translate-y-1 transition-transform duration-300">
                <div className="bg-gray-800 p-3 rounded-lg group-hover:bg-yellow-400 transition-colors duration-300">
                  <FaEnvelope className="text-yellow-400 group-hover:text-gray-900 transition-colors duration-300" />
                </div>
                <a href="mailto:prayagproperties2020@gmail.com" className="text-gray-300 ml-4 hover:text-yellow-400">
                  prayagproperties2020@gmail.com , mishraconstruction2904@gmail.com
                </a>
              </li>
            </ul>
          </div>

          {/* Newsletter */}
          {/* <div className="backdrop-blur-sm">
            <h4 className="text-xl font-semibold mb-8 relative inline-block">
              Newsletter
              <span className="absolute -bottom-2 left-0 w-12 h-0.5 bg-gradient-to-r from-yellow-400 to-yellow-600 rounded-full"></span>
            </h4>
            <p className="text-gray-300 mb-6 leading-relaxed">
              Subscribe to our newsletter for the latest updates and opportunities.
            </p>
            <form className="relative group">
              <input
                type="email"
                placeholder="Your Email"
                className="w-full px-6 py-4 bg-gray-800/50 rounded-xl border border-gray-700/50 focus:border-yellow-400/50 focus:outline-none focus:ring-2 focus:ring-yellow-400/20 transition-all duration-300"
              />
              <button
                type="submit"
                className="absolute right-2 top-1/2 -translate-y-1/2 bg-gradient-to-r from-yellow-400 to-yellow-600 text-gray-900 font-semibold px-6 py-2 rounded-lg opacity-90 hover:opacity-100 hover:shadow-lg hover:shadow-yellow-400/20 transform hover:-translate-y-px transition-all duration-300"
              >
                Subscribe
              </button>
            </form>
          </div> */}
        </div>
      </div>

      {/* Bottom Bar with enhanced styling */}
      <div className="relative border-t border-gray-800/50 backdrop-blur-sm">
        <div className="max-w-7xl mx-auto px-4 py-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-gray-400 text-sm">
              © {new Date().getFullYear()} Prayag Properties.
              <span className="text-gray-500"> All rights reserved.</span>
            </p>
            <div className="flex space-x-8 mt-4 md:mt-0">
              <Link to="/" className="text-gray-400 hover:text-yellow-400 text-sm relative group">
                Privacy Policy
                <span className="absolute -bottom-1 left-0 w-0 h-px bg-gradient-to-r from-yellow-400 to-yellow-600 group-hover:w-full transition-all duration-300"></span>
              </Link>
              <Link to="/" className="text-gray-400 hover:text-yellow-400 text-sm relative group">
                Terms & Conditions
                <span className="absolute -bottom-1 left-0 w-0 h-px bg-gradient-to-r from-yellow-400 to-yellow-600 group-hover:w-full transition-all duration-300"></span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;