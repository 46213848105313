import React from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import { sendApiRequest } from "../utils";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import loginBg from '../assets/images/loginbg.jpg';
import logo from '../assets/images/logo.png';
import { useDispatch } from "react-redux";
import { setValue } from '../features/UserInfo/userSlice';

const CustomerLogin = ({setIsRefresh}) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm();

    const navigate = useNavigate();
     const dispatch = useDispatch();

    const onSubmit = async (data) => {
        try {
            const response = await sendApiRequest('api/customers/login', {
                loginId: data.loginId,
                password: data.password
            }, "POST");

            if (response.data.success) {
                Swal.fire({
                    title: 'Login Successful!',
                    html: `Welcome back, ${response.data.customer.customerName}!`,
                    icon: 'success',
                    confirmButtonText: 'Continue',
                    confirmButtonColor: '#2AADCC',
                    background: '#ffffff'
                }).then(() => {
                    // Store token and customer data
                    localStorage.setItem('auth_token', response.data.token);
                    console.log(response.data.customer)
                    dispatch(setValue(response.data.customer));
                    setIsRefresh("yescheck");
                    // Navigate to customer dashboard
                    navigate('/');
                });
            } else {
                Swal.fire({
                    title: 'Login Failed',
                    text: response.data.message || 'Invalid credentials',
                    icon: 'error',
                    confirmButtonText: 'Try Again',
                    confirmButtonColor: '#2AADCC'
                });
            }
        } catch (error) {
            console.error("Login error:", error);
            Swal.fire({
                title: 'Error',
                text: error.response?.data?.message || 'Something went wrong. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: '#2AADCC'
            });
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center p-10 relative">
            {/* Background Image */}
            <div
                className="absolute inset-0 bg-cover bg-center bg-no-repeat"
                style={{
                    backgroundImage: `url(${loginBg})`,
                    opacity: '1'
                }}
            >
            </div>

            {/* Floating Glass Card */}
            <div className="relative bg-white/95 backdrop-blur-lg rounded-2xl shadow-2xl p-10 w-full max-w-md mx-4 overflow-hidden border border-[#2AADCC]/20 transform transition-all duration-300 hover:shadow-3xl z-10">
                {/* Decorative Elements */}
                <div className="absolute -top-20 -right-20 w-48 h-48 rounded-full bg-[#30AEB8]/20 blur-3xl -z-10"></div>
                <div className="absolute -bottom-20 -left-20 w-48 h-48 rounded-full bg-[#2AADCC]/20 blur-3xl -z-10"></div>

                {/* Company Branding */}
                <div className="flex flex-col items-center mb-8">
                    <div className="mb-4">
                        <img
                            src={logo}
                            alt="Company Logo"
                            className="h-28 w-auto object-contain"
                        />
                    </div>
                    <h2 className="text-3xl font-bold bg-gradient-to-r from-[#262362] to-[#2AADCC] bg-clip-text text-transparent">
                        Customer Login
                    </h2>
                    <p className="text-gray-600 mt-2">Welcome back!</p>
                </div>

                <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                    {/* Email/Phone Field */}
                    <div className="relative group">
                        <FontAwesomeIcon 
                            icon={faEnvelope} 
                            className="absolute top-4 left-4 text-gray-400 group-hover:text-[#2AADCC] transition-colors duration-300 text-lg" 
                        />
                        <input
                            type="text"
                            placeholder="Email or Phone Number"
                            {...register("loginId", { 
                                required: "Email or Phone number is required"
                            })}
                            className="w-full pl-12 pr-4 py-4 rounded-2xl border border-gray-200 bg-white/50 focus:bg-white focus:ring-4 focus:ring-blue-100 focus:border-blue-600 transition-all duration-300 text-gray-700 text-lg hover:border-blue-400 placeholder:text-gray-400"
                        />
                        {errors.loginId && 
                            <p className="text-red-500 text-sm mt-2 ml-2 flex items-center">
                                <span className="mr-1">⚠</span> {errors.loginId.message}
                            </p>
                        }
                    </div>

                    {/* Password Field */}
                    <div className="relative group">
                        <FontAwesomeIcon 
                            icon={faLock} 
                            className="absolute top-4 left-4 text-gray-400 group-hover:text-[#2AADCC] transition-colors duration-300 text-lg" 
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            {...register("password", { 
                                required: "Password is required",
                                minLength: {
                                    value: 6,
                                    message: "Password must be at least 6 characters"
                                }
                            })}
                            className="w-full pl-12 pr-4 py-4 rounded-2xl border border-gray-200 bg-white/50 focus:bg-white focus:ring-4 focus:ring-blue-100 focus:border-blue-600 transition-all duration-300 text-gray-700 text-lg hover:border-blue-400 placeholder:text-gray-400"
                        />
                        {errors.password && 
                            <p className="text-red-500 text-sm mt-2 ml-2 flex items-center">
                                <span className="mr-1">⚠</span> {errors.password.message}
                            </p>
                        }
                    </div>

                    {/* Submit Button */}
                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className="w-full bg-gradient-to-r from-[#262362] to-[#2AADCC] text-white py-4 px-6 rounded-2xl text-lg font-semibold hover:opacity-90 transition-all duration-300 transform hover:scale-[1.02] disabled:opacity-70 disabled:cursor-not-allowed disabled:hover:scale-100"
                    >
                        {isSubmitting ? "Logging in..." : "Login"}
                    </button>

                    {/* Register Link */}
                    <div className="text-center mt-6">
                        <p className="text-gray-600">
                            Don't have an account?{" "}
                            <button
                                type="button"
                                onClick={() => navigate('/customer-register')}
                                className="text-[#2AADCC] hover:text-[#262362] font-semibold transition-colors duration-300"
                            >
                                Register here
                            </button>
                        </p>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CustomerLogin;
