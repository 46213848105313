// import { sendApiRequest } from './index';
import { sendApiRequest } from '../utils';

export const checkAuthStatus = async () => {
    try {
        const token = localStorage.getItem('token');
        if (!token) {
            return { isAuthenticated: false, user: null };
        }

        const response = await sendApiRequest('api/auth/verify-token', null, 'GET');
        
        if (response.data.success) {
            return {
                isAuthenticated: true,
                user: response.data.user
            };
        } else {
            localStorage.removeItem('token');
            return { isAuthenticated: false, user: null };
        }
    } catch (error) {
        localStorage.removeItem('token');
        return { isAuthenticated: false, user: null };
    }
};
