import React from 'react'
import logo from '../assets/images/logo.png';

const InvoicePage = () => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-[#49c2c7] to-[#13407e] py-8">
      <div className="max-w-5xl mx-auto bg-white shadow-2xl rounded-lg overflow-hidden">
        {/* Header with Logo */}
        <div className="bg-[#0d61b5] text-white p-8">
          <div className="flex justify-between items-start">
            <div>
              <img src={logo} alt="RV Infra Heights" className="h-20 object-contain bg-white p-2 rounded-lg" />
              <p className="mt-2 text-sm text-[#49c2c7]">A DIVISION OF EBIZKART PVT. LTD.</p>
            </div>
            <div className="text-right">
              <h1 className="text-3xl font-bold mb-2">Booking Invoice</h1>
              <p className="font-semibold">RV INFRA HEIGHTS</p>
              <p className="text-sm text-[#25b2da]">224/16A/16 Vrindavan vihar colony T.B. Sapru Marg</p>
              <p className="text-sm text-[#25b2da]">civil lines prayagraj 211001</p>
              <p className="text-sm text-[#25b2da]">Phone: 7307448197</p>
              <p className="text-sm text-[#25b2da]">Email: info@rvinfraheights.com</p>
            </div>
          </div>
        </div>

        <div className="p-8">
          {/* Details Grid */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {/* Referral Details */}
            <div className="bg-gradient-to-br from-white to-[#49c2c7]/10 rounded-lg p-6 shadow-md border border-[#49c2c7]/20">
              <h2 className="font-bold text-[#13407e] mb-3 text-lg">Referral Details:</h2>
              <div className="text-sm space-y-2">
                <p className="font-semibold">ARCHANA DEVI</p>
                <p>PRAYAGRAJ, UTTAR PRADESH, 211013</p>
                <p>Phone: 8808613763</p>
                <p>Email: SUNLKR.ALLD@GMAIL.COM</p>
              </div>
            </div>

            {/* Customer Details */}
            <div className="bg-gradient-to-br from-white to-[#2ba974]/10 rounded-lg p-6 shadow-md border border-[#2ba974]/20">
              <h2 className="font-bold text-[#13407e] mb-3 text-lg">Customer Details:</h2>
              <div className="text-sm space-y-2">
                <p className="font-semibold">SUSHMA BHARTIYA</p>
                <p>Vill.- Shahari Bojh Sirsa Chauraha</p>
                <p>Handia</p>
                <p>PRAYAGRAJ, UTTAR PRADESH</p>
                <p>Phone: 9455294720</p>
              </div>
            </div>

            {/* Invoice Details */}
            <div className="bg-gradient-to-br from-white to-[#25b2da]/10 rounded-lg p-6 shadow-md border border-[#25b2da]/20">
              <h2 className="font-bold text-[#13407e] mb-3 text-lg">Invoice For: Booking</h2>
              <div className="text-sm space-y-2">
                <p>Referral Code: RVI865271</p>
                <p>Invoice No.: RV-0010811</p>
                <p>Invoice Date: 09-03-2025</p>
              </div>
            </div>
          </div>

          {/* Description */}
          <div className="mt-8 bg-gradient-to-r from-[#0d61b5]/5 to-transparent p-6 rounded-lg border-l-4 border-[#0d61b5]">
            <h2 className="font-bold text-[#13407e] mb-3 text-lg">DESCRIPTION</h2>
            <div className="text-gray-700">
              <p>Plot Panchvati D, Chitrakoot, Uttar Pradesh</p>
              <p>Plot No. D431, Area 600, Size 15x40</p>
            </div>
          </div>

          {/* Payment Details */}
          <div className="mt-8 flex justify-between items-start">
            <div className="space-y-3">
              <div className="bg-[#13407e] text-white px-4 py-2 rounded-lg">
                <p><span className="font-bold">TOTAL PAYABLE:</span> Rs.90600</p>
                <p><span className="font-bold">TOTAL PAID:</span> Rs.53327.00</p>
                <p><span className="font-bold">TOTAL BALANCE:</span> Rs.37273</p>
              </div>
              <p className="text-sm text-gray-600 mt-4">
                <span className="font-bold">Amount in words:</span> Two Thousand Four Hundred And Eighty Seven Rupees & Zero Paisa Only
              </p>
            </div>
            <div className="text-right">
              <div className="bg-[#2ba974] text-white px-6 py-3 rounded-lg inline-block">
                <p className="font-bold">RECEIVED</p>
                <p className="text-2xl font-bold mt-1">Rs. 2487.00</p>
              </div>
              <div className="mt-8">
                <p className="text-gray-600">For,</p>
                <p className="font-bold text-[#13407e]">RV INFRA HEIGHTS</p>
                <div className="mt-8 border-t-2 border-[#49c2c7] pt-2">
                  <p className="text-gray-600">Authorized Signatory</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InvoicePage