import React, { useState } from 'react';
import '../../styles/animations.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faRulerCombined,
  faMoneyBillWave,
  faCalendarAlt,
  faMapMarkerAlt,
  faFileDownload,
  faPlus
} from '@fortawesome/free-solid-svg-icons';

const Properties = () => {
  // Static property data
  const [properties, setProperties] = useState([
    {
      _id: '1',
      plotNumber: 'A-101',
      location: 'Sector 45, Gurgaon',
      area: '1200',
      areaUnit: 'sq.ft',
      value: 4500000,
      emiAmount: 25000,
      emiFrequency: 'month',
      startDate: '2023-01-15',
      status: 'Active'
    },
    {
      _id: '2',
      plotNumber: 'B-205',
      location: 'Noida Extension',
      area: '1800',
      areaUnit: 'sq.ft',
      value: 6500000,
      emiAmount: 35000,
      emiFrequency: 'month',
      startDate: '2023-03-22',
      status: 'Pending'
    }
  ]);

  const [selectedProperty, setSelectedProperty] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [newProperty, setNewProperty] = useState({
    plotNumber: '',
    location: '',
    area: '',
    areaUnit: 'sq.ft',
    value: '',
    emiAmount: '',
    emiFrequency: 'month',
    startDate: '',
    status: 'Active'
  });

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR'
    }).format(amount);
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-IN', {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    });
  };

  const viewPropertyDetails = (property) => {
    setSelectedProperty(property);
  };

  const handleAddProperty = () => {
    setShowAddForm(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProperty(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const propertyWithId = {
      ...newProperty,
      _id: `prop-${Date.now()}`,
      value: parseInt(newProperty.value),
      emiAmount: parseInt(newProperty.emiAmount)
    };
    setProperties([...properties, propertyWithId]);
    setShowAddForm(false);
    setNewProperty({
      plotNumber: '',
      location: '',
      area: '',
      areaUnit: 'sq.ft',
      value: '',
      emiAmount: '',
      emiFrequency: 'month',
      startDate: '',
      status: 'Active'
    });
  };

  return (
    <div className="min-h-screen bg-[#F1EFEC] p-6">
      <div className="max-w-7xl mx-auto">
        <div className="flex items-center justify-between mb-8">
          <div className="flex items-center space-x-4">
            <h1 className="text-3xl font-bold text-[#222222]">
              My Properties
            </h1>
            <div className="h-8 w-px bg-[#1DCD9F] opacity-50"></div>
            <p className="text-[#222222]/80">{properties.length} Properties</p>
          </div>
          <button
            onClick={handleAddProperty}
            className="flex items-center px-4 py-2 bg-[#1DCD9F] text-white rounded-lg hover:bg-[#18b58c] transition-colors shadow-md"
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Add New Property
          </button>
        </div>

        {/* Add Property Form Modal */}
        {showAddForm && (
          <div className="fixed inset-0 bg-[#222222]/80 overflow-y-auto h-full w-full z-50 flex items-center justify-center">
            <div className="relative p-8 w-full max-w-2xl shadow-lg rounded-lg bg-[#F1EFEC] border border-[#1DCD9F]/30">
              <div className="flex justify-between items-start mb-6">
                <h2 className="text-2xl font-semibold text-[#222222]">Add New Property</h2>
                <button
                  onClick={() => setShowAddForm(false)}
                  className="text-[#222222]/50 hover:text-[#1DCD9F] text-2xl transition-colors"
                >
                  ×
                </button>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                  <div>
                    <label className="block text-sm font-medium text-[#222222]/80 mb-2">Plot Number</label>
                    <input
                      type="text"
                      name="plotNumber"
                      value={newProperty.plotNumber}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-[#222222]/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#1DCD9F]/50 focus:border-transparent"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-[#222222]/80 mb-2">Location</label>
                    <input
                      type="text"
                      name="location"
                      value={newProperty.location}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-[#222222]/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#1DCD9F]/50 focus:border-transparent"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-[#222222]/80 mb-2">Area</label>
                    <input
                      type="number"
                      name="area"
                      value={newProperty.area}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-[#222222]/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#1DCD9F]/50 focus:border-transparent"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-[#222222]/80 mb-2">Area Unit</label>
                    <select
                      name="areaUnit"
                      value={newProperty.areaUnit}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-[#222222]/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#1DCD9F]/50 focus:border-transparent"
                    >
                      <option value="sq.ft">sq.ft</option>
                      <option value="sq.m">sq.m</option>
                      <option value="acre">acre</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-[#222222]/80 mb-2">Property Value (₹)</label>
                    <input
                      type="number"
                      name="value"
                      value={newProperty.value}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-[#222222]/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#1DCD9F]/50 focus:border-transparent"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-[#222222]/80 mb-2">EMI Amount (₹)</label>
                    <input
                      type="number"
                      name="emiAmount"
                      value={newProperty.emiAmount}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-[#222222]/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#1DCD9F]/50 focus:border-transparent"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-[#222222]/80 mb-2">EMI Frequency</label>
                    <select
                      name="emiFrequency"
                      value={newProperty.emiFrequency}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-[#222222]/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#1DCD9F]/50 focus:border-transparent"
                    >
                      <option value="month">Monthly</option>
                      <option value="quarter">Quarterly</option>
                      <option value="year">Yearly</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-[#222222]/80 mb-2">Start Date</label>
                    <input
                      type="date"
                      name="startDate"
                      value={newProperty.startDate}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-[#222222]/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#1DCD9F]/50 focus:border-transparent"
                      required
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-[#222222]/80 mb-2">Status</label>
                    <select
                      name="status"
                      value={newProperty.status}
                      onChange={handleInputChange}
                      className="w-full px-4 py-2 border border-[#222222]/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#1DCD9F]/50 focus:border-transparent"
                    >
                      <option value="Active">Active</option>
                      <option value="Pending">Pending</option>
                      <option value="Closed">Closed</option>
                    </select>
                  </div>
                </div>
                <div className="flex justify-end space-x-4">
                  <button
                    type="button"
                    onClick={() => setShowAddForm(false)}
                    className="px-6 py-2 border border-[#222222]/30 rounded-lg hover:bg-[#222222]/10 transition-colors text-[#222222]"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="px-6 py-2 bg-[#1DCD9F] text-white rounded-lg hover:bg-[#18b58c] transition-colors shadow-md"
                  >
                    Add Property
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        <div>
          <div className="grid grid-cols-1 gap-6 lg:grid-cols-2">
            {properties.map((property) => (
              <div
                key={property._id}
                className="bg-white rounded-xl border border-[#222222]/10 hover:shadow-lg hover:border-[#1DCD9F]/30 group transition-all duration-300"
              >
                {/* Property Card Header */}
                <div className="px-6 py-5 border-b border-[#222222]/10">
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="text-xl font-bold text-[#222222] flex items-center group-hover:text-[#1DCD9F] transition-colors">
                        <div className="h-10 w-10 rounded-lg bg-[#1DCD9F]/10 flex items-center justify-center mr-3 group-hover:scale-110 transition-transform">
                          <FontAwesomeIcon icon={faHome} className="text-[#1DCD9F]" />
                        </div>
                        Plot #{property.plotNumber}
                      </h3>
                      <p className="text-sm text-[#222222]/70 flex items-center mt-2 ml-13">
                        <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2 text-[#1DCD9F]" />
                        {property.location}
                      </p>
                    </div>
                    <span className={`px-4 py-1.5 rounded-full text-sm font-medium ${
                      property.status === 'Active'
                        ? 'bg-[#1DCD9F]/10 text-[#1DCD9F]'
                        : property.status === 'Pending'
                        ? 'bg-amber-100 text-amber-800'
                        : 'bg-blue-100 text-blue-800'
                    }`}>
                      {property.status}
                    </span>
                  </div>
                </div>

                {/* Property Details */}
                <div className="px-6 py-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="flex items-center">
                      <FontAwesomeIcon icon={faRulerCombined} className="text-[#1DCD9F] mr-3" />
                      <div>
                        <p className="text-sm text-[#222222]/70">Area</p>
                        <p className="font-medium text-[#222222]">{property.area} {property.areaUnit}</p>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <FontAwesomeIcon icon={faMoneyBillWave} className="text-[#1DCD9F] mr-3" />
                      <div>
                        <p className="text-sm text-[#222222]/70">Value</p>
                        <p className="font-medium text-[#222222]">{formatCurrency(property.value)}</p>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <FontAwesomeIcon icon={faCalendarAlt} className="text-[#1DCD9F] mr-3" />
                      <div>
                        <p className="text-sm text-[#222222]/70">EMI</p>
                        <p className="font-medium text-[#222222]">{formatCurrency(property.emiAmount)}/{property.emiFrequency}</p>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <FontAwesomeIcon icon={faCalendarAlt} className="text-[#1DCD9F] mr-3" />
                      <div>
                        <p className="text-sm text-[#222222]/70">Start Date</p>
                        <p className="font-medium text-[#222222]">{formatDate(property.startDate)}</p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Actions */}
                <div className="px-6 py-4 bg-[#F1EFEC] rounded-b-lg border-t border-[#222222]/10">
                  <div className="flex justify-between items-center">
                    <button
                      onClick={() => viewPropertyDetails(property)}
                      className="text-[#1DCD9F] hover:text-[#18b58c] font-medium text-sm"
                    >
                      View Details
                    </button>
                    <div className="flex space-x-2">
                      <button
                        className="flex items-center px-3 py-1.5 bg-[#1DCD9F]/10 text-[#1DCD9F] rounded-lg hover:bg-[#1DCD9F]/20 transition-colors text-sm font-medium"
                      >
                        <FontAwesomeIcon icon={faFileDownload} className="mr-1" />
                        Agreement
                      </button>
                      <button
                        className="flex items-center px-3 py-1.5 bg-[#1DCD9F]/10 text-[#1DCD9F] rounded-lg hover:bg-[#1DCD9F]/20 transition-colors text-sm font-medium"
                      >
                        <FontAwesomeIcon icon={faFileDownload} className="mr-1" />
                        Receipt
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Property Details Modal */}
          {selectedProperty && (
            <div className="fixed inset-0 bg-[#222222]/80 overflow-y-auto h-full w-full z-50 flex items-center justify-center">
              <div className="relative p-8 w-full max-w-4xl shadow-lg rounded-lg bg-[#F1EFEC] border border-[#1DCD9F]/30">
                <div className="flex justify-between items-start">
                  <h2 className="text-2xl font-semibold text-[#222222]">
                    Property Details - Plot #{selectedProperty.plotNumber}
                  </h2>
                  <button
                    onClick={() => setSelectedProperty(null)}
                    className="text-[#222222]/50 hover:text-[#1DCD9F] transition-colors"
                  >
                    ×
                  </button>
                </div>
                <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="bg-white p-5 rounded-lg border border-[#222222]/10">
                    <h3 className="font-medium text-lg text-[#222222] mb-3 pb-2 border-b border-[#222222]/10">Basic Information</h3>
                    <div className="space-y-3">
                      <p className="flex justify-between"><span className="text-[#222222]/70">Plot Number:</span> <span className="font-medium text-[#222222]">{selectedProperty.plotNumber}</span></p>
                      <p className="flex justify-between"><span className="text-[#222222]/70">Location:</span> <span className="font-medium text-[#222222]">{selectedProperty.location}</span></p>
                      <p className="flex justify-between"><span className="text-[#222222]/70">Area:</span> <span className="font-medium text-[#222222]">{selectedProperty.area} {selectedProperty.areaUnit}</span></p>
                    </div>
                  </div>
                  <div className="bg-white p-5 rounded-lg border border-[#222222]/10">
                    <h3 className="font-medium text-lg text-[#222222] mb-3 pb-2 border-b border-[#222222]/10">Financial Information</h3>
                    <div className="space-y-3">
                      <p className="flex justify-between"><span className="text-[#222222]/70">Property Value:</span> <span className="font-medium text-[#222222]">{formatCurrency(selectedProperty.value)}</span></p>
                      <p className="flex justify-between"><span className="text-[#222222]/70">EMI Amount:</span> <span className="font-medium text-[#222222]">{formatCurrency(selectedProperty.emiAmount)}</span></p>
                      <p className="flex justify-between"><span className="text-[#222222]/70">EMI Frequency:</span> <span className="font-medium text-[#222222]">{selectedProperty.emiFrequency}</span></p>
                    </div>
                  </div>
                  <div className="bg-white p-5 rounded-lg border border-[#222222]/10">
                    <h3 className="font-medium text-lg text-[#222222] mb-3 pb-2 border-b border-[#222222]/10">Dates</h3>
                    <div className="space-y-3">
                      <p className="flex justify-between"><span className="text-[#222222]/70">Start Date:</span> <span className="font-medium text-[#222222]">{formatDate(selectedProperty.startDate)}</span></p>
                    </div>
                  </div>
                  <div className="bg-white p-5 rounded-lg border border-[#222222]/10">
                    <h3 className="font-medium text-lg text-[#222222] mb-3 pb-2 border-b border-[#222222]/10">Status</h3>
                    <div className="mt-2">
                      <span className={`px-4 py-2 rounded-full text-sm font-medium ${
                        selectedProperty.status === 'Active'
                          ? 'bg-[#1DCD9F]/10 text-[#1DCD9F]'
                          : selectedProperty.status === 'Pending'
                          ? 'bg-amber-100 text-amber-800'
                          : 'bg-blue-100 text-blue-800'
                      }`}>
                        {selectedProperty.status}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mt-6 flex justify-end">
                  <button
                    onClick={() => setSelectedProperty(null)}
                    className="px-6 py-2 bg-[#1DCD9F] text-white rounded-lg hover:bg-[#18b58c] transition-colors shadow-md"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Properties;