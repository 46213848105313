import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const location = useLocation();

  if (!localStorage.getItem('auth_token') ) {
    // Redirect to login if not authenticated
    return <Navigate to="/customer-login" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;
