import React, { useState } from 'react';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faFileInvoiceDollar,
  faHistory,
  faUserCircle,
  faCog,
  faSignOutAlt,
  faBars,
  faTimes,
  faChartLine
} from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/images/logo.png';
import '../../styles/scrollbar.css';
import '../../styles/animations.css';

const CustomerDashboardLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('customerToken');
    localStorage.removeItem('customerData');
    navigate('/customer-login');
  };

  const menuItems = [
    { path: '/customer/dashboard', icon: faHome, label: 'Overview' },
    { path: '/customer/properties', icon: faChartLine, label: 'My Properties' },
    { path: '/customer/payments', icon: faFileInvoiceDollar, label: 'EMI & Payments' },
    { path: '/customer/profile', icon: faUserCircle, label: 'My Profile' },
  ];

  const customerData = JSON.parse(localStorage.getItem('customerData') || '{}');

  return (
    <div className="min-h-screen" style={{ background: '#F1EFEC' }}>
      {/* Backdrop for mobile sidebar */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-gray-900/60 backdrop-blur-sm z-30 sm:hidden"
          onClick={toggleSidebar}
        />
      )}

      {/* Sidebar */}
      <aside
        className={`fixed top-0 left-0 z-40 h-full transition-all duration-300 ease-in-out transform ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } sm:translate-x-0 w-72 shadow-xl`}
        style={{ background: 'linear-gradient(180deg, #1DCD9F 0%, #1DCD9F 100%)' }}
      >
        {/* Logo */}
        <div className="flex items-center justify-between h-20 px-6 border-b" style={{ borderColor: 'rgba(255, 255, 255, 0.2)' }}>
          <img src={logo} alt="Logo" className="h-12 w-auto brightness-0 invert" />
          <button
            onClick={toggleSidebar}
            className="sm:hidden p-2 rounded-lg text-white/90 hover:bg-white/10 transition-colors"
          >
            <FontAwesomeIcon icon={faTimes} className="w-6 h-6" />
          </button>
        </div>

        {/* Navigation */}
        <div className="py-6 px-4">
          <nav className="space-y-2">
            {menuItems.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                className={`flex items-center px-4 py-3 rounded-xl transition-all duration-300 ${
                  location.pathname === item.path
                    ? 'bg-white/20 text-white shadow-md'
                    : 'text-white/90 hover:bg-white/10 hover:text-white'
                }`}
              >
                <FontAwesomeIcon
                  icon={item.icon}
                  className={`w-5 h-5 transition-colors duration-300 ${
                    location.pathname === item.path ? 'text-white' : 'text-white/80'
                  }`}
                />
                <span className="ml-4 font-medium">{item.label}</span>
              </Link>
            ))}

            {/* Logout Button */}
            <button
              onClick={handleLogout}
              className="flex w-full items-center px-4 py-3 text-white/90 rounded-xl hover:bg-red-500/20 hover:text-white transition-all duration-300"
            >
              <FontAwesomeIcon
                icon={faSignOutAlt}
                className="w-5 h-5 text-white/80"
              />
              <span className="ml-4 font-medium">Logout</span>
            </button>
          </nav>
        </div>
      </aside>

      {/* Main Content */}
      <div
        className={`flex-1 transition-all duration-300 ${
          isSidebarOpen ? 'sm:ml-[280px]' : 'sm:ml-0'
        }`}
      >
        {/* Header */}
        <header
          className="fixed top-0 left-0 right-0 z-30 shadow-sm"
          style={{ background: '#F1EFEC', color: '#222222' }}
        >
          <div className="flex items-center justify-between h-20 px-6 sm:ml-72">
            <button
              onClick={toggleSidebar}
              className="sm:hidden p-2 rounded-xl hover:bg-[#1DCD9F]/10 transition-colors"
            >
              <FontAwesomeIcon icon={faBars} className="w-6 h-6" style={{ color: '#222222' }} />
            </button>
            <div className="text-2xl font-bold" style={{ color: '#222222' }}>
              {location.pathname.split('/').pop().charAt(0).toUpperCase() +
                location.pathname.split('/').pop().slice(1)}
            </div>
            <div className="w-10" />
          </div>
        </header>

        {/* Page Content */}
        <main className="flex-1 relative">
          <div className="py-8 px-4 sm:px-6 lg:px-8 mt-20">
            <div className="max-w-7xl mx-auto">
              <div
                className="rounded-2xl shadow-md border transition-all duration-300"
                style={{
                  background: 'white',
                  borderColor: 'rgba(29, 205, 159, 0.1)',
                  boxShadow: '0 4px 6px rgba(34, 34, 34, 0.03)'
                }}
              >
                <div className="p-8">
                  <div className="animate-fadeIn">
                    <Outlet />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default CustomerDashboardLayout;