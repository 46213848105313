import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faEnvelope,
  faPhone,
  faKey,
  faEdit
} from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { sendApiRequest } from '../../utils';
import Swal from 'sweetalert2';

const Profile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);
  const customerData = JSON.parse(localStorage.getItem('customerData') || '{}');

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm({
    defaultValues: {
      customerName: customerData.customerName || 'John Doe',
      customerEmail: customerData.customerEmail || 'john.doe@example.com',
      customerPhone: customerData.customerPhone || '9876543210'
    }
  });

  const {
    register: registerPassword,
    handleSubmit: handlePasswordSubmit,
    formState: { errors: passwordErrors },
    reset: resetPassword
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const response = await sendApiRequest('api/customers/profile', data, 'PUT');
      if (response.data.success) {
        localStorage.setItem('customerData', JSON.stringify({ ...customerData, ...data }));
        Swal.fire({
          title: 'Success!',
          text: 'Profile updated successfully',
          icon: 'success',
          confirmButtonColor: '#1DCD9F'
        });
        setIsEditing(false);
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.response?.data?.message || 'Failed to update profile',
        icon: 'error',
        confirmButtonColor: '#1DCD9F'
      });
    }
  };

  const onPasswordSubmit = async (data) => {
    try {
      const response = await sendApiRequest('api/customers/change-password', data, 'PUT');
      if (response.data.success) {
        Swal.fire({
          title: 'Success!',
          text: 'Password changed successfully',
          icon: 'success',
          confirmButtonColor: '#1DCD9F'
        });
        setIsChangingPassword(false);
        resetPassword();
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.response?.data?.message || 'Failed to change password',
        icon: 'error',
        confirmButtonColor: '#1DCD9F'
      });
    }
  };

  return (
    <div style={{ backgroundColor: '#F1EFEC', minHeight: '100vh', padding: '2rem' }}>
      <div className="max-w-3xl mx-auto">
        <h1
          style={{ color: '#222222' }}
          className="text-3xl font-bold mb-8 text-center"
        >
          My Profile
        </h1>

        {/* Profile Information */}
        <div
          style={{ backgroundColor: '#FFFFFF', borderColor: '#1DCD9F' }}
          className="border rounded-xl mb-8 p-6 shadow-lg hover:shadow-xl transition-all duration-300"
        >
          <div className="flex justify-between items-center mb-6">
            <h3 style={{ color: '#222222' }} className="text-xl font-semibold">
              Profile Information
            </h3>
            <button
              onClick={() => setIsEditing(!isEditing)}
              style={{ backgroundColor: '#1DCD9F', color: '#FFFFFF' }}
              className="flex items-center px-4 py-2 rounded-lg hover:opacity-90 transition-all"
            >
              <FontAwesomeIcon icon={faEdit} className="mr-2" />
              {isEditing ? 'Cancel' : 'Edit Profile'}
            </button>
          </div>

          {isEditing ? (
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              <div>
                <label style={{ color: '#222222' }} className="block text-sm font-medium mb-1">
                  Full Name
                </label>
                <div className="relative">
                  <FontAwesomeIcon
                    icon={faUser}
                    style={{ color: '#1DCD9F' }}
                    className="absolute top-1/2 left-3 transform -translate-y-1/2"
                  />
                  <input
                    type="text"
                    {...register('customerName', { required: 'Name is required' })}
                    style={{ borderColor: '#1DCD9F', color: '#222222' }}
                    className="pl-10 w-full p-2 border rounded-lg focus:ring-2 focus:ring-[#1DCD9F] focus:outline-none"
                  />
                </div>
                {errors.customerName && (
                  <p className="mt-1 text-sm text-red-600">{errors.customerName.message}</p>
                )}
              </div>

              <div>
                <label style={{ color: '#222222' }} className="block text-sm font-medium mb-1">
                  Email Address
                </label>
                <div className="relative">
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    style={{ color: '#1DCD9F' }}
                    className="absolute top-1/2 left-3 transform -translate-y-1/2"
                  />
                  <input
                    type="email"
                    {...register('customerEmail', {
                      required: 'Email is required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Invalid email address'
                      }
                    })}
                    style={{ borderColor: '#1DCD9F', color: '#222222' }}
                    className="pl-10 w-full p-2 border rounded-lg focus:ring-2 focus:ring-[#1DCD9F] focus:outline-none"
                  />
                </div>
                {errors.customerEmail && (
                  <p className="mt-1 text-sm text-red-600">{errors.customerEmail.message}</p>
                )}
              </div>

              <div>
                <label style={{ color: '#222222' }} className="block text-sm font-medium mb-1">
                  Phone Number
                </label>
                <div className="relative">
                  <FontAwesomeIcon
                    icon={faPhone}
                    style={{ color: '#1DCD9F' }}
                    className="absolute top-1/2 left-3 transform -translate-y-1/2"
                  />
                  <input
                    type="tel"
                    {...register('customerPhone', {
                      required: 'Phone number is required',
                      pattern: { value: /^[0-9]{10}$/, message: 'Invalid phone number' }
                    })}
                    style={{ borderColor: '#1DCD9F', color: '#222222' }}
                    className="pl-10 w-full p-2 border rounded-lg focus:ring-2 focus:ring-[#1DCD9F] focus:outline-none"
                  />
                </div>
                {errors.customerPhone && (
                  <p className="mt-1 text-sm text-red-600">{errors.customerPhone.message}</p>
                )}
              </div>

              <div className="flex justify-end">
                <button
                  type="submit"
                  style={{ backgroundColor: '#1DCD9F', color: '#FFFFFF' }}
                  className="px-6 py-2 rounded-lg hover:opacity-90 transition-all shadow-md"
                >
                  Save Changes
                </button>
              </div>
            </form>
          ) : (
            <div className="space-y-6">
              <div className="flex items-center">
                <FontAwesomeIcon icon={faUser} style={{ color: '#1DCD9F' }} className="mr-4" />
                <div>
                  <p style={{ color: '#666666' }} className="text-sm">Full Name</p>
                  <p style={{ color: '#222222' }} className="text-lg font-medium">
                    {customerData.customerName}
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <FontAwesomeIcon icon={faEnvelope} style={{ color: '#1DCD9F' }} className="mr-4" />
                <div>
                  <p style={{ color: '#666666' }} className="text-sm">Email Address</p>
                  <p style={{ color: '#222222' }} className="text-lg font-medium">
                    {customerData.customerEmail}
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <FontAwesomeIcon icon={faPhone} style={{ color: '#1DCD9F' }} className="mr-4" />
                <div>
                  <p style={{ color: '#666666' }} className="text-sm">Phone Number</p>
                  <p style={{ color: '#222222' }} className="text-lg font-medium">
                    {customerData.customerPhone}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Change Password */}
        <div
          style={{ backgroundColor: '#FFFFFF', borderColor: '#1DCD9F' }}
          className="border rounded-xl p-6 shadow-lg hover:shadow-xl transition-all duration-300"
        >
          <div className="flex justify-between items-center mb-6">
            <h3 style={{ color: '#222222' }} className="text-xl font-semibold">
              Change Password
            </h3>
            <button
              onClick={() => setIsChangingPassword(!isChangingPassword)}
              style={{ backgroundColor: '#1DCD9F', color: '#FFFFFF' }}
              className="flex items-center px-4 py-2 rounded-lg hover:opacity-90 transition-all"
            >
              <FontAwesomeIcon icon={faKey} className="mr-2" />
              {isChangingPassword ? 'Cancel' : 'Change Password'}
            </button>
          </div>

          {isChangingPassword && (
            <form onSubmit={handlePasswordSubmit(onPasswordSubmit)} className="space-y-6">
              <div>
                <label style={{ color: '#222222' }} className="block text-sm font-medium mb-1">
                  Current Password
                </label>
                <input
                  type="password"
                  {...registerPassword('currentPassword', { required: 'Current password is required' })}
                  style={{ borderColor: '#1DCD9F', color: '#222222' }}
                  className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-[#1DCD9F] focus:outline-none"
                />
                {passwordErrors.currentPassword && (
                  <p className="mt-1 text-sm text-red-600">{passwordErrors.currentPassword.message}</p>
                )}
              </div>

              <div>
                <label style={{ color: '#222222' }} className="block text-sm font-medium mb-1">
                  New Password
                </label>
                <input
                  type="password"
                  {...registerPassword('newPassword', {
                    required: 'New password is required',
                    minLength: { value: 6, message: 'Password must be at least 6 characters' }
                  })}
                  style={{ borderColor: '#1DCD9F', color: '#222222' }}
                  className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-[#1DCD9F] focus:outline-none"
                />
                {passwordErrors.newPassword && (
                  <p className="mt-1 text-sm text-red-600">{passwordErrors.newPassword.message}</p>
                )}
              </div>

              <div>
                <label style={{ color: '#222222' }} className="block text-sm font-medium mb-1">
                  Confirm New Password
                </label>
                <input
                  type="password"
                  {...registerPassword('confirmPassword', {
                    required: 'Please confirm your password',
                    validate: (value) =>
                      value === document.querySelector('input[name="newPassword"]').value ||
                      'Passwords do not match'
                  })}
                  style={{ borderColor: '#1DCD9F', color: '#222222' }}
                  className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-[#1DCD9F] focus:outline-none"
                />
                {passwordErrors.confirmPassword && (
                  <p className="mt-1 text-sm text-red-600">{passwordErrors.confirmPassword.message}</p>
                )}
              </div>

              <div className="flex justify-end">
                <button
                  type="submit"
                  style={{ backgroundColor: '#1DCD9F', color: '#FFFFFF' }}
                  className="px-6 py-2 rounded-lg hover:opacity-90 transition-all shadow-md"
                >
                  Update Password
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;