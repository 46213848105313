import React, { useState, useEffect } from "react";
import { Link, useLocation, Outlet, useNavigate } from "react-router-dom";
import {
  FiHome,
  FiLogOut,
  FiMenu,
  FiX,
  FiBell,
  FiSettings,
  FiChevronDown,
  FiSearch,
  FiArrowLeft,
} from "react-icons/fi";
import {
  FaUserCircle,
  FaMoneyBillWave,
  FaNetworkWired,
  FaCrown,
} from "react-icons/fa";
import { AiOutlineIdcard, AiFillStar } from "react-icons/ai";
import {
  BsFileEarmarkText,
  BsBox,
  BsMoon,
  BsSun,
  BsGiftFill,
} from "react-icons/bs";
import { HiUserGroup, HiSparkles } from "react-icons/hi";
import { GiWallet, GiDiamondTrophy } from "react-icons/gi";
import { BiTransfer } from "react-icons/bi";
import { motion, AnimatePresence } from "framer-motion";
import { RiCoinsLine } from "react-icons/ri";
import { MdOutlineEditCalendar, MdPersonAdd } from "react-icons/md";
import { sendApiRequest } from "../utils";

const DashboardLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const fetchMyMLMTree = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = fetch("http://localhost:8000/api/users/my-mlm-tree", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((error) => console.error("Error:", error));

      // const data = await response.json();

      // if (data.success) {
      //   // Use the MLM tree data
      //   console.log('My MLM Tree:', data.data);
      //   return data.data;
      // } else {
      //   console.error('Error:', data.message);
      //   return null;
      // }
    } catch (error) {
      console.error("Fetch error:", error);
      return null;
    }
  };

  // Usage:
  // const myTree = await fetchMyMLMTree();

  // Set active submenu based on current path
  useEffect(() => {
    menuItems.forEach((item, index) => {
      if (
        item.submenu &&
        item.submenu.some((sub) => location.pathname === sub.path)
      ) {
        setActiveSubmenu(index);
      }
    });
  }, [location.pathname]);

  useEffect(() => {
    fetchMyMLMTree();
  }, []);

  const menuItems = [
    { path: "/dashboard", icon: <FiHome className="w-5 h-5" />, title: "Home" },
    {
      path: "",
      icon: <FaUserCircle className="w-5 h-5" />,
      title: "My Profile",
      submenu: [
        { path: "/dashboard/show-profile", title: "Show Profile" },
        { path: "/dashboard/update-profile", title: "Update Profile" },
        {
          path: "/dashboard/update-login-password",
          title: "Update Login Password",
        },
      ],
    },
    {
      path: "/dashboard/update-kyc",
      icon: <AiOutlineIdcard className="w-5 h-5" />,
      title: "Update KYC",
    },
    {
      path: "",
      icon: <BsFileEarmarkText className="w-5 h-5" />,
      title: "Plan Chart & Letters",
      submenu: [
        { path: "/dashboard/show-business-plan", title: "Business Plan Chart" },
      ],
    },
    {
      path: "",
      icon: <BsBox className="w-5 h-5" />,
      title: "Package",
      submenu: [
        { path: "/dashboard/buy-package", title: "Buy Package" },
        { path: "/dashboard/list-invoices", title: "List Invoices" },
      ],
    },
    {
      path: "",
      icon: <HiUserGroup className="w-5 h-5" />,
      title: "My Team",
      submenu: [
        { path: "/dashboard/team/structure", title: "My Direct Network" },
        { path: "/dashboard/team/performance", title: "My Total Network" },
      ],
    },
    {
      path: "",
      icon: <FaMoneyBillWave className="w-5 h-5" />,
      title: "Income",
      submenu: [
        { path: "/dashboard/monthly-income", title: "Monthly Income" },
        {
          path: "/dashboard/incentive-income",
          title: "New Business Incentive Income",
        },
        { path: "/dashboard/bdi-income", title: "BDI Income" },
      ],
    },
    {
      path: "/dashboard/network-explorer",
      icon: <FaNetworkWired className="w-5 h-5" />,
      title: "Network Explorer",
    },
    {
      path: "",
      icon: <GiWallet className="w-5 h-5" />,
      title: "Fund Manager",
      submenu: [
        { path: "/dashboard/withdrawal-request", title: "Withdrawal Request" },
        { path: "/dashboard/withdrawal-status", title: "Withdrawal Status" },
      ],
    },
    {
      path: "",
      icon: <BiTransfer className="w-5 h-5" />,
      title: "Transactions",
      submenu: [
        {
          path: "/dashboard/wallet-transactions",
          title: "Main Wallet Transactions",
        },
      ],
    },
  ];

  const toggleSubmenu = (index) => {
    setActiveSubmenu(activeSubmenu === index ? null : index);
  };

  const handleBack = () => {
    navigate(-1); // Go back to previous page
  };

  return (
    <div className="flex h-screen bg-gray-50">
      {/* Sidebar Backdrop (Mobile) */}
      <AnimatePresence>
        {sidebarOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setSidebarOpen(false)}
            className="fixed inset-0 z-20 bg-black bg-opacity-50 lg:hidden"
          />
        )}
      </AnimatePresence>

      {/* Sidebar */}
      <motion.div
        initial={{ x: -300 }}
        animate={{ x: sidebarOpen ? 0 : -300 }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }}
        className={`fixed inset-y-0 left-0 z-30 w-72 bg-gradient-to-br from-[#252362] via-[#1f1d4d] to-[#1a1b3d] text-white shadow-2xl transform transition-transform duration-300 ease-in-out lg:translate-x-0 lg:static lg:inset-0 flex flex-col h-full`}
        style={{ height: "100vh" }}
      >
        <div className="flex flex-col h-full">
          {/* Profile Section */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="p-6 border-b border-gray-700/50"
          >
            <motion.div
              className="flex items-center space-x-4"
              whileHover={{ y: -2 }}
              transition={{ type: "spring", stiffness: 400 }}
            >
              <div className="relative group">
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  className="relative cursor-pointer"
                >
                  <img
                    src="/assets/profile-placeholder.jpg"
                    alt="Profile"
                    className="w-16 h-16 rounded-full ring-2 ring-blue-500 p-[2px] bg-gradient-to-r from-blue-500 to-purple-500"
                  />
                  <div className="absolute -top-1 -right-1 bg-yellow-400 text-xs font-bold px-1.5 py-0.5 rounded-full flex items-center justify-center text-gray-900">
                    <FaCrown className="mr-0.5 w-3 h-3" /> Pro
                  </div>
                </motion.div>
                <div className="absolute bottom-0 right-0 w-4 h-4 bg-green-500 rounded-full border-2 border-[#1a1b3d] animate-pulse"></div>
              </div>

              <div className="flex-1">
                <motion.div
                  whileHover={{ x: 2 }}
                  className="flex items-center space-x-2"
                >
                  <h3 className="font-semibold text-lg text-white">John Doe</h3>
                  <AiFillStar className="w-4 h-4 text-yellow-400" />
                </motion.div>
                <p className="text-sm text-gray-400">ID: MLM123456</p>
              </div>
            </motion.div>

            <div className="grid grid-cols-2 gap-3 mt-4">
              <motion.div
                whileHover={{ scale: 1.03 }}
                className="bg-gradient-to-r from-blue-500/10 to-blue-600/10 p-3 rounded-xl"
              >
                <p className="text-sm text-gray-400">Total Earnings</p>
                <p className="text-lg font-semibold text-white">₹12,450</p>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.03 }}
                className="bg-gradient-to-r from-purple-500/10 to-purple-600/10 p-3 rounded-xl"
              >
                <p className="text-sm text-gray-400">Team Members</p>
                <p className="text-lg font-semibold text-white">45</p>
              </motion.div>
            </div>
          </motion.div>

          {/* Navigation Menu */}
          <nav
            className="flex-1 px-4 pt-6 pb-4 overflow-y-auto"
            style={{
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {menuItems.map((item, index) => (
              <motion.div
                key={item.path}
                className="mb-2"
                whileHover={{ x: 4 }}
                transition={{ type: "spring", stiffness: 400, damping: 10 }}
              >
                <Link
                  to={item.path}
                  onClick={(e) => {
                    if (item.submenu) {
                      e.preventDefault();
                      toggleSubmenu(index);
                    }
                  }}
                  className={`flex items-center justify-between px-4 py-3 transition-all duration-200 rounded-xl group relative overflow-hidden ${
                    location.pathname === item.path ||
                    (item.submenu &&
                      item.submenu.some(
                        (sub) => location.pathname === sub.path
                      ))
                      ? "bg-gradient-to-r from-blue-600 to-blue-700 text-white shadow-lg shadow-blue-500/30"
                      : "text-gray-300 hover:bg-gray-700/30 hover:text-white"
                  }`}
                >
                  <div className="flex items-center relative z-10">
                    <div
                      className={`p-1.5 rounded-lg transition-all duration-200 ${
                        location.pathname === item.path ||
                        (item.submenu &&
                          item.submenu.some(
                            (sub) => location.pathname === sub.path
                          ))
                          ? "bg-white/20"
                          : "group-hover:bg-white/10"
                      }`}
                    >
                      {item.icon}
                    </div>
                    <span className="ml-3 font-medium">{item.title}</span>
                  </div>
                  {item.submenu && (
                    <FiChevronDown
                      className={`w-4 h-4 transition-transform duration-300 ${
                        activeSubmenu === index ? "transform rotate-180" : ""
                      }`}
                    />
                  )}
                  {/* Hover effect background */}
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left" />
                </Link>

                {/* Submenu with improved animation */}
                {item.submenu && activeSubmenu === index && (
                  <motion.div
                    initial={{ opacity: 0, y: -10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.2 }}
                    className="pl-12 mt-1 space-y-1"
                  >
                    {item.submenu.map((subItem) => (
                      <motion.div
                        key={subItem.path}
                        whileHover={{ x: 4 }}
                        transition={{
                          type: "spring",
                          stiffness: 400,
                          damping: 10,
                        }}
                      >
                        <Link
                          to={subItem.path}
                          className={`block px-3 py-2 text-sm rounded-lg transition-all duration-200 ${
                            location.pathname === subItem.path
                              ? "bg-blue-800/40 text-blue-300 shadow-sm shadow-blue-900/20"
                              : "text-gray-400 hover:bg-gray-700/30 hover:text-white"
                          }`}
                        >
                          {subItem.title}
                        </Link>
                      </motion.div>
                    ))}
                  </motion.div>
                )}
              </motion.div>
            ))}
          </nav>

          {/* Remove the custom scrollbar styles */}
          {/* <style jsx>{`
            .custom-scrollbar::-webkit-scrollbar {...}
          `}</style> */}

          {/* Sidebar Footer */}
          <div className="p-4 border-t border-gray-700/50 mt-auto">
            {" "}
            {/* Added mt-auto to push footer to bottom */}
            <button className="flex items-center w-full px-4 py-3 text-gray-300 hover:bg-red-500/10 hover:text-red-500 rounded-xl transition-colors">
              <FiLogOut className="w-5 h-5" />
              <span className="ml-3 font-medium">Logout</span>
            </button>
          </div>
        </div>
      </motion.div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Top Header */}
        <header className="fixed top-0 right-0 left-0 lg:left-72 z-20 flex items-center justify-between h-20 px-6 bg-white dark:bg-gray-800 shadow-sm backdrop-blur-sm bg-white/90 dark:bg-gray-800/90 border-b border-gray-200 dark:border-gray-700">
          <div className="flex items-center">
            <button
              onClick={() => setSidebarOpen(true)}
              className="lg:hidden text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white mr-4"
            >
              <FiMenu className="w-6 h-6" />
            </button>

            {/* Back Button - Only show when not on dashboard home */}
            {!location.pathname.match(/^\/dashboard$/) && (
              <button
                onClick={handleBack}
                className="flex items-center text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white mr-4"
              >
                <FiArrowLeft className="w-5 h-5 mr-1" />
                <span className="text-sm">Back</span>
              </button>
            )}

            {/* Search Bar */}
            <motion.div
              whileHover={{ scale: 1.01 }}
              className="hidden md:block relative"
            >
              <input
                type="text"
                placeholder="Search..."
                className="w-64 px-4 py-2 rounded-full bg-gray-100 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent text-sm transition-all duration-200 pl-10"
              />
              <div className="absolute left-3 top-2.5 text-gray-400 dark:text-gray-500">
                <FiSearch className="w-4 h-4" />
              </div>
            </motion.div>
          </div>

          <div className="flex items-center space-x-6">
            {/* Quick Stats */}
            <div className="hidden lg:flex items-center space-x-4">
              <div className="flex items-center space-x-2 bg-[#252362]/10 dark:bg-blue-900/30 px-3 py-1.5 rounded-full">
                <GiDiamondTrophy className="text-[#252362] dark:text-blue-400" />
                <span className="text-sm font-medium text-[#252362] dark:text-blue-400">
                  Rank: Associate
                </span>
              </div>
              <div className="flex items-center space-x-2 bg-[#252362]/5 dark:bg-green-900/30 px-3 py-1.5 rounded-full">
                <RiCoinsLine className="text-[#252362] dark:text-green-400" />
                <span className="text-sm font-medium text-[#252362] dark:text-green-400">
                  +$245 Today
                </span>
              </div>
            </div>
          </div>
        </header>

        {/* Main Content Area */}
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-[#FFFFFF] dark:bg-[#262362] p-6 mt-20 transition-colors duration-300">
          <div className="max-w-7xl mx-auto">
            {/* Content Card */}
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="bg-[#DBE2F2] dark:bg-gray-800 rounded-xl shadow-sm p-6 mb-6 transition-all duration-300 hover:shadow-lg border border-[#DDE4F4] dark:border-gray-700"
            >
              <Outlet />
            </motion.div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default DashboardLayout;
