import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaEnvelope, FaMapMarkerAlt, FaBars, FaTimes, FaUserCircle } from 'react-icons/fa';
import { motion, AnimatePresence } from 'framer-motion';
import { useSelector, useDispatch } from 'react-redux';
import logo from '../assets/images/logo.png';
import { useNavigate } from 'react-router-dom';
import { setValue } from '../features/UserInfo/userSlice';

const Header = ({ isRefresh, setIsRefresh }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const profileRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch(); 
  // console.log(isRefresh, "isRefresh", setIsRefresh, "setIsRefresh")
  
  // Get user data from Redux store
  const userData = useSelector(state => state.user_info);
  const isLoggedIn = userData && userData.length > 0;
  console.log(isLoggedIn, "isLoggedIn", userData[0], "userData")

  const menuItems = [
    { name: 'Home', path: '/' },
    { name: 'About Us', path: '/about-us' },
    { name: 'Plan', path: '/plan' },
    { name: 'Legal', path: '/legal' },
    { name: 'Gallery', path: '/gallery' },
    { name: 'Plot Availability', path: '/plot-availability' },
    ...(!isLoggedIn ? [
      { name: 'Register', path: '/register' },
      { name: 'Login', path: '/login' },
    ] : []),
    { name: 'Contact Us', path: '/contact-us' }
  ];

  useEffect(() => {
    const handleScroll = () => setIsScrolled(window.scrollY > 10);
    window.addEventListener('scroll', handleScroll);
    
    // Handle click outside profile dropdown
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        setIsProfileOpen(false);
      }
    };
    
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    // Implement your logout logic here
    console.log('User logged out');
    localStorage.removeItem('auth_token');
    setIsProfileOpen(false);
    setIsMenuOpen(false);
    setIsRefresh("again");
    dispatch(setValue("Logout"));
    navigate('/login');
  };

  return (
    <>
      {/* Top Contact Bar */}
      <div className="bg-[#1A1F36] text-white shadow-lg font-['Montserrat']">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row justify-center md:justify-between items-center py-2 px-6 bg-[#1A1F36] text-[#DBE2F2] rounded-lg shadow-lg">
            <div className="flex flex-col md:flex-row items-center gap-6 md:gap-12 text-sm font-normal">
              <motion.div
                whileHover={{ scale: 1.08 }}
                whileTap={{ scale: 0.95 }}
                className="flex items-center gap-3 hover:text-[#FACC15] transition-all duration-300 group"
              >
                <FaMapMarkerAlt className="text-[#FACC15] group-hover:scale-110 transition-transform" />
                <span className="group-hover:underline decoration-2 decoration-[#FACC15]/80">
                  567 Saha Urf Pipalgaon
                  Near IIIT-A Chouraha
                  210112
                </span>
              </motion.div>

              <motion.a
                whileHover={{ scale: 1.08 }}
                whileTap={{ scale: 0.95 }}
                href="mailto:prayagproperties2020@gmail.com"
                className="flex items-center gap-3 hover:text-[#FACC15] transition-all duration-300 group"
              >
                <FaEnvelope className="text-[#FACC15] group-hover:scale-110 transition-transform" />
                <span className="group-hover:underline decoration-2 decoration-[#FACC15]/80">
                  prayagproperties2020@gmail.com 
                </span>
              </motion.a>
            </div>
          </div>
        </div>
      </div>

      {/* Main Header */}
      <motion.header
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className={`sticky top-0 z-50 transition-all duration-500 font-['Montserrat'] ${
          isScrolled ? 'bg-white/95 backdrop-blur-sm shadow-xl' : 'bg-white/95 shadow-lg'
        }`}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-40 md:h-44">
            {/* Logo */}
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              transition={{ type: "spring", stiffness: 400, damping: 10 }}
              className="flex-shrink-0 relative group py-2"
            >
              <Link to="/" className="flex items-center">
                <img
                  src={logo}
                  alt="RealMLM"
                  className={`transition-all duration-500 ${
                    isScrolled ? 'h-28 md:h-32' : 'h-36 md:h-40'
                  } rounded-2xl group-hover:shadow-2xl group-hover:ring-4 group-hover:ring-[#252362]/20 object-contain`}
                />
                <div className="absolute inset-0 bg-gradient-to-r from-transparent via-[#DBE2F2]/40 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-700 rounded-2xl"></div>
              </Link>
            </motion.div>

            {/* Desktop Navigation */}
            <nav className="hidden md:flex space-x-2 items-center">
              {menuItems.map((item) => (
                <motion.div
                  key={item.name}
                  whileHover={{ y: -3 }}
                  className="relative group"
                >
                  <Link
                    to={item.path}
                    className={`px-5 py-3 rounded-xl transition-all duration-300 font-semibold text-[0.99rem] tracking-wide ${
                      isScrolled
                        ? 'text-[#252362] hover:text-[#1a1a4a] hover:bg-[#252362]/5'
                        : 'text-[#252362] hover:text-[#1a1a4a] hover:bg-white/30'
                    }`}
                  >
                    {item.name}
                    <span className="absolute left-1/2 -bottom-1 w-0 h-[2px] bg-gradient-to-r from-[#252362] to-[#3a3a8a] transform -translate-x-1/2 transition-all duration-500 group-hover:w-4/5"></span>
                  </Link>
                </motion.div>
              ))}

              {/* Profile Dropdown */}
              {isLoggedIn && (
                <div className="relative ml-4" ref={profileRef}>
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => setIsProfileOpen(!isProfileOpen)}
                    className={`p-2 rounded-full ${
                      isScrolled ? 'text-[#252362]' : 'text-[#252362]'
                    }`}
                    aria-label="User profile"
                    aria-expanded={isProfileOpen}
                  >
                    <FaUserCircle className="h-8 w-8" />
                  </motion.button>

                  <AnimatePresence>
                    {isProfileOpen && (
                      <motion.div
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -10 }}
                        transition={{ duration: 0.2 }}
                        className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-50 border border-gray-100"
                      >
                        <Link
                          to={userData[0].type === 'user' ? '/dashboard' : '/customer/dashboard'}
                          onClick={() => setIsProfileOpen(false)}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-[#252362]/10 transition-colors"
                        >
                          Dashboard
                        </Link>
                        <button
                          onClick={handleLogout}
                          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-[#252362]/10 transition-colors"
                        >
                          Logout
                        </button>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              )}
            </nav>

            {/* Mobile Menu Button */}
            <div className="md:hidden flex items-center">
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className={`p-3 rounded-xl focus:outline-none ${
                  isScrolled
                    ? 'text-[#252362] hover:bg-[#252362]/10'
                    : 'text-[#252362] hover:bg-white/30'
                }`}
                aria-label="Menu"
                aria-expanded={isMenuOpen}
              >
                {isMenuOpen ? (
                  <motion.div
                    initial={{ rotate: 0 }}
                    animate={{ rotate: 180 }}
                    transition={{ duration: 0.4 }}
                  >
                    <FaTimes className="h-7 w-7" />
                  </motion.div>
                ) : (
                  <motion.div
                    animate={{
                      scale: [1, 1.1, 1],
                      transition: { repeat: Infinity, duration: 2.5 }
                    }}
                  >
                    <FaBars className="h-7 w-7" />
                  </motion.div>
                )}
              </motion.button>
            </div>
          </div>
        </div>

        {/* Mobile Navigation */}
        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.4, ease: "easeInOut" }}
              className={`md:hidden overflow-hidden font-['Montserrat'] ${
                isScrolled
                  ? 'bg-white/95 backdrop-blur-sm shadow-inner'
                  : 'bg-gradient-to-b from-[#DEE7F6] to-[#DDE4F4] shadow-inner'
              }`}
            >
              <div className="px-4 pt-2 pb-6 space-y-2">
                {menuItems.map((item, index) => (
                  <motion.div
                    key={item.name}
                    initial={{ x: -30, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: index * 0.07, type: "spring" }}
                    whileHover={{ x: 5 }}
                    className="border-b border-[#DBE2F2]/40 last:border-0"
                  >
                    <Link
                      to={item.path}
                      onClick={() => setIsMenuOpen(false)}
                      className={`block px-5 py-4 rounded-xl font-medium transition-all text-[#252362] ${
                        isScrolled
                          ? 'hover:bg-[#252362]/10 hover:pl-7'
                          : 'hover:bg-white/30 hover:pl-7'
                      }`}
                    >
                      {item.name}
                    </Link>
                  </motion.div>
                ))}
                
                {/* Mobile Profile Options */}
                {isLoggedIn && (
                  <>
                    <motion.div
                      initial={{ x: -30, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: menuItems.length * 0.07, type: "spring" }}
                      whileHover={{ x: 5 }}
                      className="border-b border-[#DBE2F2]/40"
                    >
                      <Link
                        to="/dashboard"
                        onClick={() => {
                          setIsMenuOpen(false);
                          setIsProfileOpen(false);
                        }}
                        className={`block px-5 py-4 rounded-xl font-medium transition-all text-[#252362] ${
                          isScrolled
                            ? 'hover:bg-[#252362]/10 hover:pl-7'
                            : 'hover:bg-white/30 hover:pl-7'
                        }`}
                      >
                        Dashboard
                      </Link>
                    </motion.div>
                    <motion.div
                      initial={{ x: -30, opacity: 0 }}
                      animate={{ x: 0, opacity: 1 }}
                      transition={{ delay: (menuItems.length + 1) * 0.07, type: "spring" }}
                      whileHover={{ x: 5 }}
                    >
                      <button
                        onClick={() => {
                          handleLogout();
                          setIsMenuOpen(false);
                        }}
                        className={`block w-full text-left px-5 py-4 rounded-xl font-medium transition-all text-[#252362] ${
                          isScrolled
                            ? 'hover:bg-[#252362]/10 hover:pl-7'
                            : 'hover:bg-white/30 hover:pl-7'
                        }`}
                      >
                        Logout
                      </button>
                    </motion.div>
                  </>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.header>

      {/* Font Import */}
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
        `}
      </style>
    </>
  );
};

export default Header;