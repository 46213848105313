import React, { useState } from 'react'
import CustomerRegistration from './CustomerRegistration'
import SignUpForm from './PartnerRegistration'
import logo from '../assets/images/logo.png'
import bglogo from '../assets/images/loginbg.jpg'
import { motion, AnimatePresence } from 'framer-motion'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserTie, faUsers } from "@fortawesome/free-solid-svg-icons"

const RegistrationPage = () => {
  const [activeTab, setActiveTab] = useState('partner');

  return (
    <div className="min-h-screen relative">
      {/* Background Image */}
      <div 
        className="absolute inset-0 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${bglogo})`,
          opacity: '1',
        }}
      >
        {/* Overlay */}
        <div className="absolute inset-0 bg-white/90"></div>
      </div>

      {/* Content Container */}
      <div className="relative z-10 py-8 px-4">
        {/* Logo Section */}
        <div className="text-center mb-8">
          <img 
            src={logo} 
            alt="Prayag Properties Logo" 
            className="h-28 w-auto mx-auto object-contain"
          />
        </div>

        {/* Header Section */}
        <div className="text-center mb-12">
          <p className="text-[#3aafdd] text-xl font-semibold">Select the type of account you want to create</p>
        </div>

        {/* Tab Buttons */}
        <div className="max-w-3xl mx-auto">
          <div className="bg-white/90 backdrop-blur-xl p-3 rounded-3xl flex gap-3 shadow-xl border border-white/60">
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => setActiveTab('partner')}
              className={`flex-1 py-5 px-8 rounded-2xl text-lg font-semibold transition-all duration-500 flex items-center justify-center gap-3 ${
                activeTab === 'partner'
                  ? 'bg-gradient-to-r from-blue-600 to-indigo-600 text-white shadow-lg'
                  : 'text-gray-600 hover:bg-gray-50/80'
              }`}
            >
              <FontAwesomeIcon icon={faUserTie} className="text-xl" />
              <span>Partner Registration</span>
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => setActiveTab('customer')}
              className={`flex-1 py-5 px-8 rounded-2xl text-lg font-semibold transition-all duration-500 flex items-center justify-center gap-3 ${
                activeTab === 'customer'
                  ? 'bg-gradient-to-r from-blue-600 to-indigo-600 text-white shadow-lg'
                  : 'text-gray-600 hover:bg-gray-50/80'
              }`}
            >
              <FontAwesomeIcon icon={faUsers} className="text-xl" />
              <span>Customer Registration</span>
            </motion.button>
          </div>
        </div>

        {/* Content Area with Animation */}
        <AnimatePresence mode="wait">
          <motion.div
            key={activeTab}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="mt-8"
          >
            {activeTab === 'partner' ? <SignUpForm /> : <CustomerRegistration />}
          </motion.div>
        </AnimatePresence>

        {/* Info Cards */}
        <div className="max-w-6xl mx-auto mt-12 grid grid-cols-1 md:grid-cols-2 gap-6 px-4">
          <div className="bg-white/80 backdrop-blur-xl p-6 rounded-2xl shadow-lg border border-white/60">
            <h3 className="text-xl font-semibold text-blue-600 mb-3">Partner Benefits</h3>
            <ul className="text-gray-600 space-y-2">
              <li>• Access to exclusive property listings</li>
              <li>• Higher commission rates</li>
              <li>• Professional marketing tools</li>
              <li>• Dedicated support team</li>
            </ul>
          </div>
          <div className="bg-white/80 backdrop-blur-xl p-6 rounded-2xl shadow-lg border border-white/60">
            <h3 className="text-xl font-semibold text-blue-600 mb-3">Customer Benefits</h3>
            <ul className="text-gray-600 space-y-2">
              <li>• Priority property access</li>
              <li>• Personalized property alerts</li>
              <li>• Expert consultation</li>
              <li>• Special offers and discounts</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegistrationPage