import axios from "axios";

export const setItemWithExpiration = (key, value, expirationInDays) => {
	const now = new Date();
	const expirationTime = now.getTime() + expirationInDays * 24 * 60 * 60 * 1000; // Adding 5 days in milliseconds * 24 * 60 * 60
	const item = {
		value: value,
		expiration: expirationTime,
	};

	localStorage.setItem(key, JSON.stringify(item));
};


export const getItemWithExpiration = (key) => {
	const item = localStorage.getItem(key);
	if (!item) return null; // Item doesn't exist

	const parsedItem = JSON.parse(item);
	const now = new Date();

	// Check if the item has expired
	if (now.getTime() > parsedItem.expiration) {
		localStorage.removeItem(key); // Remove the expired item
		return null; // Item expired
	}

	return parsedItem.value; // Return the value if not expired
};

export function fileToBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        // Event triggered when the file is successfully read
        reader.onload = () => {
            resolve(reader.result); // Extract the Base64 part
        };

        // Event triggered when there is an error reading the file
        reader.onerror = (error) => {
            reject(error);
        };

        // Read the file as a Data URL
        reader.readAsDataURL(file);
    });
}

/**
 * Function to send API requests using axios
 * @param {string} api_name - The API endpoint (relative to base URL)
 * @param {object} params - The request parameters or data
 * @param {string} method - The HTTP method (GET, POST, PUT, DELETE)
 * @returns {Promise} - A Promise that resolves to the API response
 */
export async function sendApiRequest(api_name, params = {}, method = "GET") {
	axios.defaults.withCredentials = true;
	const url = `${process.env.REACT_APP_API_BASE_URL}/${api_name}`;

	const token = localStorage.getItem("auth_token")
	try {

		const options = {
			url: url,
			method: method.toUpperCase(),
			headers: {
				'Content-Type': 'application/json',
				"Authorization": `Bearer ${token}`
			},
		};

		if (method.toUpperCase() === 'GET' || method.toUpperCase() === 'DELETE') {
			options.params = params;
		} else {
			options.data = params;
		}


		const response = await axios(options);
		return response;
	} catch (error) {
		return { message: error.response.data.message, status: false };
	}
}



export const countryCodes = Object.freeze([
	{ name: "Afghanistan", code: "+93" },
	{ name: "Albania", code: "+355" },
	{ name: "Algeria", code: "+213" },
	{ name: "Andorra", code: "+376" },
	{ name: "Angola", code: "+244" },
	{ name: "Argentina", code: "+54" },
	{ name: "Armenia", code: "+374" },
	{ name: "Australia", code: "+61" },
	{ name: "Austria", code: "+43" },
	{ name: "Azerbaijan", code: "+994" },
	{ name: "Bahamas", code: "+1-242" },
	{ name: "Bahrain", code: "+973" },
	{ name: "Bangladesh", code: "+880" },
	{ name: "Barbados", code: "+1-246" },
	{ name: "Belarus", code: "+375" },
	{ name: "Belgium", code: "+32" },
	{ name: "Belize", code: "+501" },
	{ name: "Benin", code: "+229" },
	{ name: "Bhutan", code: "+975" },
	{ name: "Bolivia", code: "+591" },
	{ name: "Bosnia and Herzegovina", code: "+387" },
	{ name: "Botswana", code: "+267" },
	{ name: "Brazil", code: "+55" },
	{ name: "Brunei", code: "+673" },
	{ name: "Bulgaria", code: "+359" },
	{ name: "Burkina Faso", code: "+226" },
	{ name: "Burundi", code: "+257" },
	{ name: "Cambodia", code: "+855" },
	{ name: "Cameroon", code: "+237" },
	{ name: "Canada", code: "+1" },
	{ name: "Cape Verde", code: "+238" },
	{ name: "Central African Republic", code: "+236" },
	{ name: "Chad", code: "+235" },
	{ name: "Chile", code: "+56" },
	{ name: "China", code: "+86" },
	{ name: "Colombia", code: "+57" },
	{ name: "Comoros", code: "+269" },
	{ name: "Congo", code: "+242" },
	{ name: "Costa Rica", code: "+506" },
	{ name: "Croatia", code: "+385" },
	{ name: "Cuba", code: "+53" },
	{ name: "Cyprus", code: "+357" },
	{ name: "Czech Republic", code: "+420" },
	{ name: "Denmark", code: "+45" },
	{ name: "Djibouti", code: "+253" },
	{ name: "Dominica", code: "+1-767" },
	{ name: "Dominican Republic", code: "+1-809" },
	{ name: "Ecuador", code: "+593" },
	{ name: "Egypt", code: "+20" },
	{ name: "El Salvador", code: "+503" },
	{ name: "Equatorial Guinea", code: "+240" },
	{ name: "Eritrea", code: "+291" },
	{ name: "Estonia", code: "+372" },
	{ name: "Eswatini", code: "+268" },
	{ name: "Ethiopia", code: "+251" },
	{ name: "Fiji", code: "+679" },
	{ name: "Finland", code: "+358" },
	{ name: "France", code: "+33" },
	{ name: "Gabon", code: "+241" },
	{ name: "Gambia", code: "+220" },
	{ name: "Georgia", code: "+995" },
	{ name: "Germany", code: "+49" },
	{ name: "Ghana", code: "+233" },
	{ name: "Greece", code: "+30" },
	{ name: "Grenada", code: "+1-473" },
	{ name: "Guatemala", code: "+502" },
	{ name: "Guinea", code: "+224" },
	{ name: "Guyana", code: "+592" },
	{ name: "Haiti", code: "+509" },
	{ name: "Honduras", code: "+504" },
	{ name: "Hungary", code: "+36" },
	{ name: "Iceland", code: "+354" },
	{ name: "India", code: "+91" },
	{ name: "Indonesia", code: "+62" },
	{ name: "Iran", code: "+98" },
	{ name: "Iraq", code: "+964" },
	{ name: "Ireland", code: "+353" },
	{ name: "Israel", code: "+972" },
	{ name: "Italy", code: "+39" },
	{ name: "Jamaica", code: "+1-876" },
	{ name: "Japan", code: "+81" },
	{ name: "Jordan", code: "+962" },
	{ name: "Kazakhstan", code: "+7" },
	{ name: "Kenya", code: "+254" },
	{ name: "Kuwait", code: "+965" },
	{ name: "Kyrgyzstan", code: "+996" },
	{ name: "Laos", code: "+856" },
	{ name: "Latvia", code: "+371" },
	{ name: "Lebanon", code: "+961" },
	{ name: "Lesotho", code: "+266" },
	{ name: "Liberia", code: "+231" },
	{ name: "Libya", code: "+218" },
	{ name: "Lithuania", code: "+370" },
	{ name: "Luxembourg", code: "+352" },
	{ name: "Madagascar", code: "+261" },
	{ name: "Malawi", code: "+265" },
	{ name: "Malaysia", code: "+60" },
	{ name: "Maldives", code: "+960" },
	{ name: "Mali", code: "+223" },
	{ name: "Malta", code: "+356" },
	{ name: "Mexico", code: "+52" },
	{ name: "Moldova", code: "+373" },
	{ name: "Monaco", code: "+377" },
	{ name: "Mongolia", code: "+976" },
	{ name: "Montenegro", code: "+382" },
	{ name: "Morocco", code: "+212" },
	{ name: "Mozambique", code: "+258" },
	{ name: "Myanmar", code: "+95" },
	{ name: "Namibia", code: "+264" },
	{ name: "Nepal", code: "+977" },
	{ name: "Netherlands", code: "+31" },
	{ name: "New Zealand", code: "+64" },
	{ name: "Nicaragua", code: "+505" },
	{ name: "Niger", code: "+227" },
	{ name: "Nigeria", code: "+234" },
	{ name: "Norway", code: "+47" },
	{ name: "Oman", code: "+968" },
	{ name: "Pakistan", code: "+92" },
	{ name: "Palestine", code: "+970" },
	{ name: "Panama", code: "+507" },
	{ name: "Papua New Guinea", code: "+675" },
	{ name: "Paraguay", code: "+595" },
	{ name: "Peru", code: "+51" },
	{ name: "Philippines", code: "+63" },
	{ name: "Poland", code: "+48" },
	{ name: "Portugal", code: "+351" },
	{ name: "Qatar", code: "+974" },
	{ name: "Romania", code: "+40" },
	{ name: "Russia", code: "+7" },
	{ name: "Rwanda", code: "+250" },
	{ name: "Saudi Arabia", code: "+966" },
	{ name: "Senegal", code: "+221" },
	{ name: "Serbia", code: "+381" },
	{ name: "Singapore", code: "+65" },
	{ name: "South Africa", code: "+27" },
	{ name: "South Korea", code: "+82" },
	{ name: "Spain", code: "+34" },
	{ name: "Sri Lanka", code: "+94" },
	{ name: "Sudan", code: "+249" },
	{ name: "Sweden", code: "+46" },
	{ name: "Switzerland", code: "+41" },
	{ name: "Syria", code: "+963" },
	{ name: "Taiwan", code: "+886" },
	{ name: "Tanzania", code: "+255" },
	{ name: "Thailand", code: "+66" },
	{ name: "Turkey", code: "+90" },
	{ name: "Uganda", code: "+256" },
	{ name: "Ukraine", code: "+380" },
	{ name: "United Arab Emirates", code: "+971" },
	{ name: "United Kingdom", code: "+44" },
	{ name: "United States", code: "+1" },
	{ name: "Uruguay", code: "+598" },
	{ name: "Uzbekistan", code: "+998" },
	{ name: "Venezuela", code: "+58" },
	{ name: "Vietnam", code: "+84" },
	{ name: "Yemen", code: "+967" },
	{ name: "Zambia", code: "+260" },
	{ name: "Zimbabwe", code: "+263" }
]
)


