import React from "react";
import { motion } from "framer-motion";
import { FaCheck, FaGem, FaCrown, FaStar, FaRocket, FaUsers, FaChartLine, FaHandshake } from "react-icons/fa";

const OurPlan = () => {
  const plans = [
    {
      name: "Starter",
      price: "₹25,000",
      icon: FaStar,
      features: [
        "Basic MLM Dashboard",
        "5 Direct Referrals",
        "2 Level Deep Network",
        "Basic Commission Rates",
        "Email Support"
      ],
      color: "from-blue-500 to-blue-600"
    },
    {
      name: "Professional",
      price: "₹50,000",
      icon: FaGem,
      popular: true,
      features: [
        "Advanced MLM Dashboard",
        "Unlimited Direct Referrals",
        "5 Level Deep Network",
        "Higher Commission Rates",
        "Priority Support",
        "Training Materials"
      ],
      color: "from-purple-500 to-indigo-600"
    },
    {
      name: "Enterprise",
      price: "₹1,00,000",
      icon: FaCrown,
      features: [
        "Premium MLM Dashboard",
        "Unlimited Network Depth",
        "Highest Commission Rates",
        "24/7 VIP Support",
        "Exclusive Training Sessions",
        "Leadership Bonuses",
        "Real Estate Consultation"
      ],
      color: "from-yellow-500 to-yellow-600"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Hero Section */}
      <section className="relative py-20 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-blue-50/50 to-transparent"></div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-7xl mx-auto px-4 text-center relative z-10"
        >
          <h1 className="text-5xl md:text-6xl font-bold mb-6 bg-gradient-to-r from-blue-600 via-purple-600 to-indigo-600 bg-clip-text text-transparent">
            Choose Your Success Path
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto mb-12">
            Join our revolutionary real estate MLM network and unlock unlimited earning potential through our carefully designed plans
          </p>
        </motion.div>
      </section>

      {/* Features Section */}
      <section className="py-16 px-4">
        <div className="max-w-7xl mx-auto mb-20">
          <div className="grid md:grid-cols-4 gap-8">
            {[
              { icon: FaRocket, title: "Quick Start", desc: "Begin earning immediately after registration" },
              { icon: FaUsers, title: "Unlimited Growth", desc: "Build your network without restrictions" },
              { icon: FaChartLine, title: "High Returns", desc: "Earn attractive commissions on referrals" },
              { icon: FaHandshake, title: "Full Support", desc: "Get guidance at every step of your journey" }
            ].map((feature, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-white p-6 rounded-2xl shadow-xl hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2"
              >
                <feature.icon className="text-4xl text-blue-600 mb-4" />
                <h3 className="text-xl font-bold text-gray-800 mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.desc}</p>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Pricing Cards */}
        <div className="max-w-7xl mx-auto grid md:grid-cols-3 gap-8 px-4">
          {plans.map((plan, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
              className={`relative bg-white rounded-2xl shadow-xl overflow-hidden ${
                plan.popular ? 'ring-2 ring-purple-500 transform scale-105' : ''
              }`}
            >
              {plan.popular && (
                <div className="absolute top-0 right-0 bg-gradient-to-r from-purple-500 to-indigo-600 text-white px-4 py-1 rounded-bl-lg text-sm font-semibold">
                  Most Popular
                </div>
              )}
              <div className={`bg-gradient-to-r ${plan.color} p-8 text-white`}>
                <plan.icon className="text-4xl mb-4" />
                <h3 className="text-2xl font-bold mb-2">{plan.name}</h3>
                <div className="text-4xl font-bold mb-2">{plan.price}</div>
                <p className="text-white/80">One-time investment</p>
              </div>
              <div className="p-8">
                <ul className="space-y-4">
                  {plan.features.map((feature, idx) => (
                    <li key={idx} className="flex items-center text-gray-600">
                      <FaCheck className="text-green-500 mr-3" />
                      {feature}
                    </li>
                  ))}
                </ul>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className={`w-full mt-8 bg-gradient-to-r ${plan.color} text-white py-4 rounded-xl font-semibold hover:shadow-lg transition-shadow duration-300`}
                >
                  Get Started
                </motion.button>
              </div>
            </motion.div>
          ))}
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-20 px-4">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-gray-800 mb-12">Frequently Asked Questions</h2>
          <div className="grid gap-6">
            {[
              { q: "How do I start earning?", a: "Register, choose a plan, and start referring people to join our network." },
              { q: "When do I receive commissions?", a: "Commissions are credited directly to your account within 24-48 hours of successful referrals." },
              { q: "Is there a monthly fee?", a: "No, we only require a one-time investment based on your chosen plan." }
            ].map((faq, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                className="bg-white p-6 rounded-xl shadow-lg text-left"
              >
                <h3 className="text-xl font-semibold text-gray-800 mb-2">{faq.q}</h3>
                <p className="text-gray-600">{faq.a}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default OurPlan;
