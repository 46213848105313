import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import {
  FaHome, FaUsers, FaChartLine, FaHandshake, FaArrowRight,
  FaStar, FaChevronLeft, FaChevronRight, FaMapMarkerAlt,
  FaBed, FaBath, FaRulerCombined, FaRupeeSign, FaShieldAlt,
  FaBuilding, FaUserTie, FaChartBar, FaMedal
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

// Import your images
import heroBg1 from '../assets/images/hero-bg1.jpg';
import heroBg2 from '../assets/images/hero-bg2.jpg';
import heroBg3 from '../assets/images/hero-bg3.jpg';
import property1 from '../assets/images/property1.jpg';
import property2 from '../assets/images/property2.jpg';
import property3 from '../assets/images/property3.jpg';
import avatar1 from '../assets/images/avatar1.jpg';
import avatar2 from '../assets/images/avatar2.jpg';
import avatar3 from '../assets/images/avatar3.jpg';
import mlmDiagram from '../assets/images/mlm-diagram.jpg';
import teamMeeting from '../assets/images/team-meeting.jpg';
import commissionChart from '../assets/images/commission-chart.jpg';
// import { FaBed, FaBath, FaRulerCombined } from 'react-icons/fa';

const Home = () => {
  // Hero background images array
  const heroBackgrounds = [heroBg1, heroBg2, heroBg3];
  const [currentBgIndex, setCurrentBgIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  // Auto-change background
  useEffect(() => {
    const interval = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentBgIndex((prevIndex) =>
          prevIndex === heroBackgrounds.length - 1 ? 0 : prevIndex + 1
        );
        setIsTransitioning(false);
      }, 500);
    }, 5000);
    return () => clearInterval(interval);
  }, [heroBackgrounds.length]);

  // Manual background change
  const changeBackground = (direction) => {
    setIsTransitioning(true);
    setTimeout(() => {
      setCurrentBgIndex(prevIndex => {
        if (direction === 'next') {
          return prevIndex === heroBackgrounds.length - 1 ? 0 : prevIndex + 1;
        } else {
          return prevIndex === 0 ? heroBackgrounds.length - 1 : prevIndex - 1;
        }
      });
      setIsTransitioning(false);
    }, 500);
  };

  // Data arrays
  const stats = [
    { number: "1000+", label: "Properties", icon: FaHome },
    { number: "500+", label: "Happy Clients", icon: FaUsers },
    { number: "50+", label: "Cities", icon: FaMapMarkerAlt },
    { number: "100M+", label: "Property Value", icon: FaChartLine }
  ];

  return (
    <div className="min-h-screen font-['Montserrat']">
      {/* Font and Style Imports */}
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
          .text-gradient {
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
          }
          .hero-gradient {
            background: linear-gradient(135deg, rgba(37, 35, 98, 0.85) 0%, rgba(37, 35, 98, 0.7) 100%);
          }
          .text-stroke {
            -webkit-text-stroke: 1px white;
            text-stroke: 1px white;
            color: transparent;
          }
          .animate-gradient {
            background-size: 300% 300%;
            animation: gradient-shift 6s ease infinite;
          }
          @keyframes gradient-shift {
            0% { background-position: 0% 50%; }
            50% { background-position: 100% 50%; }
            100% { background-position: 0% 50%; }
          }
          .card-hover {
            transition: all 0.3s ease;
          }
          .card-hover:hover {
            transform: translateY(-5px);
            box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
          }
        `}
      </style>

      {/* Enhanced Hero Section */}
      <section className="relative h-screen flex items-center justify-center overflow-hidden">
        {/* Background Images */}
        <div className="absolute inset-0 w-full h-full transition-opacity duration-1000">
          {heroBackgrounds.map((bg, index) => (
            <div
              key={index}
              className={`absolute inset-0 bg-cover bg-center transition-opacity duration-1000 ${index === currentBgIndex ? 'opacity-100' : 'opacity-0'}`}
              style={{
                backgroundImage: `url(${bg})`,
                zIndex: index === currentBgIndex ? 1 : 0
              }}
            />
          ))}
          <div className="absolute inset-0 hero-gradient z-2"></div>
        </div>

        {/* Hero Content */}
        <div className="container mx-auto px-6 relative z-10 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-4xl mx-auto"
          >
            <motion.span
              className="inline-block bg-gradient-to-r from-[#DBE2F2] to-[#DDE4F4] text-[#252362] px-6 py-2 rounded-full text-sm mb-6 shadow-lg font-semibold tracking-wider uppercase"
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ delay: 0.3 }}
            >
              Premium Real Estate Network
            </motion.span>

            <motion.h1
              className="text-5xl sm:text-6xl md:text-7xl font-bold text-white mb-6 leading-tight tracking-tight drop-shadow-2xl px-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
            >
              <span className="text-stroke">Build Your</span>{' '}
              <span className="text-gradient bg-gradient-to-r from-[#FFD700] via-[#DBE2F2] to-[#DDE5F5] animate-gradient">
                Real Estate Empire
              </span>
            </motion.h1>

            <motion.p
              className="text-xl md:text-2xl text-white/90 mb-10 max-w-3xl mx-auto font-medium drop-shadow-md px-4 leading-relaxed"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.7 }}
            >
              Join our <span className="font-semibold text-white">elite network</span> of successful real estate professionals and unlock <span className="font-semibold text-white">unlimited earning potential</span> through our innovative MLM platform.
            </motion.p>

            <motion.div
              className="flex flex-col sm:flex-row justify-center gap-4 sm:gap-6 px-4"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.9 }}
            >
              <Link
                to="/register"
                className="group px-8 py-4 bg-gradient-to-r from-[#FFD700] to-[#DBE2F2] text-[#252362] font-semibold rounded-full hover:shadow-2xl hover:scale-105 transition-all duration-300 shadow-lg text-base flex items-center justify-center gap-2"
              >
                Join Now
                <FaArrowRight className="transition-transform duration-300 group-hover:translate-x-1" />
              </Link>
              <Link
                to="/about"
                className="group px-8 py-4 bg-transparent text-white font-semibold rounded-full border-2 border-white hover:bg-white/20 hover:shadow-xl transition-all duration-300 text-base flex items-center justify-center gap-2"
              >
                Learn More
                <FaArrowRight className="transition-transform duration-300 group-hover:translate-x-1" />
              </Link>
            </motion.div>
          </motion.div>
        </div>
      </section>



      {/* Stats Section */}
      <section className="py-16 bg-gradient-to-r from-[#DDE5F5] to-[#E0E8F8]">
        <div className="container mx-auto px-6">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 sm:gap-6">
            {stats.map((stat, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="text-center p-4 sm:p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-all card-hover"
              >
                <stat.icon className="text-2xl sm:text-3xl text-[#252362] mx-auto mb-3 sm:mb-4" />
                <h3 className="text-2xl sm:text-3xl font-bold text-[#252362] mb-1 sm:mb-2">{stat.number}</h3>
                <p className="text-[#252362]/80 font-medium text-sm sm:text-base">{stat.label}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Mission, Vision & Philosophy Section */}
      <section className="py-24 bg-white">
        <div className="container mx-auto px-6">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-20"
          >
            <h2 className="text-4xl font-bold text-[#252362] mb-4">Our Core Values</h2>
            <p className="text-lg text-gray-600 max-w-2xl mx-auto">Building trust, creating value, and fostering growth in every relationship.</p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Mission */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1 }}
              className="relative group"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-[#252362] to-[#3A3785] rounded-3xl transform -rotate-1 group-hover:rotate-0 transition-transform duration-300"></div>
              <div className="relative bg-white p-8 rounded-3xl shadow-lg hover:shadow-xl transition-all duration-300 h-full border border-gray-100">
                <div className="bg-gradient-to-r from-[#252362] to-[#3A3785] w-16 h-16 rounded-2xl flex items-center justify-center mb-6 transform -rotate-6 group-hover:rotate-0 transition-transform duration-300">
                  <FaStar className="text-2xl text-white" />
                </div>
                <h3 className="text-2xl font-bold text-[#252362] mb-4">Our Mission</h3>
                <p className="text-gray-600 leading-relaxed">
                  To revolutionize the real estate industry by creating opportunities for growth and success through our innovative MLM platform, while delivering exceptional value to our customers and partners.
                </p>
              </div>
            </motion.div>

            {/* Vision */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="relative group"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-[#252362] to-[#3A3785] rounded-3xl transform rotate-1 group-hover:rotate-0 transition-transform duration-300"></div>
              <div className="relative bg-white p-8 rounded-3xl shadow-lg hover:shadow-xl transition-all duration-300 h-full border border-gray-100">
                <div className="bg-gradient-to-r from-[#252362] to-[#3A3785] w-16 h-16 rounded-2xl flex items-center justify-center mb-6 transform rotate-6 group-hover:rotate-0 transition-transform duration-300">
                  <FaChartLine className="text-2xl text-white" />
                </div>
                <h3 className="text-2xl font-bold text-[#252362] mb-4">Our Vision</h3>
                <p className="text-gray-600 leading-relaxed">
                  To become the leading real estate network in India, empowering thousands of individuals to achieve financial independence while transforming the landscape of property investment and development.
                </p>
              </div>
            </motion.div>

            {/* Philosophy */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.3 }}
              className="relative group"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-[#252362] to-[#3A3785] rounded-3xl transform -rotate-1 group-hover:rotate-0 transition-transform duration-300"></div>
              <div className="relative bg-white p-8 rounded-3xl shadow-lg hover:shadow-xl transition-all duration-300 h-full border border-gray-100">
                <div className="bg-gradient-to-r from-[#252362] to-[#3A3785] w-16 h-16 rounded-2xl flex items-center justify-center mb-6 transform -rotate-6 group-hover:rotate-0 transition-transform duration-300">
                  <FaHandshake className="text-2xl text-white" />
                </div>
                <h3 className="text-2xl font-bold text-[#252362] mb-4">Our Philosophy</h3>
                <p className="text-gray-600 leading-relaxed">
                  We believe in fostering a culture of collaboration, integrity, and continuous growth. Our success is built on transparency, mutual respect, and a commitment to excellence in every aspect of our business.
                </p>
              </div>
            </motion.div>
          </div>

          {/* Core Principles */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mt-16">
            {[
              { label: "Integrity", value: "100%" },
              { label: "Customer Satisfaction", value: "98%" },
              { label: "Growth Rate", value: "85%" },
              { label: "Partner Success", value: "95%" }
            ].map((stat, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, scale: 0.9 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-[#F8FAFF] p-6 rounded-2xl text-center"
              >
                <h4 className="text-3xl font-bold text-[#252362] mb-2">{stat.value}</h4>
                <p className="text-gray-600 font-medium">{stat.label}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>


      {/* Property Amenities Section */}
      <section className="py-20 bg-gradient-to-r from-[#F8FAFF] to-white">
        <div className="container mx-auto px-6">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl font-bold text-[#252362] mb-4">Premium Amenities</h2>
            <p className="text-lg text-gray-600 max-w-2xl mx-auto">Experience luxury living with our world-class amenities designed for your comfort and convenience.</p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              { icon: FaShieldAlt, title: "24/7 Security", desc: "Round-the-clock security with CCTV surveillance" },
              { icon: FaBuilding, title: "Club House", desc: "Modern clubhouse with recreational facilities" },
              { icon: FaMedal, title: "Sports Complex", desc: "Multi-sport facilities for active lifestyle" },
              { icon: FaUsers, title: "Community Events", desc: "Regular community gatherings and festivities" }
            ].map((amenity, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-white p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 group hover:-translate-y-2"
              >
                <div className="bg-[#252362]/10 p-4 rounded-xl w-16 h-16 flex items-center justify-center mb-6 group-hover:bg-[#252362] transition-all duration-300">
                  <amenity.icon className="text-2xl text-[#252362] group-hover:text-white transition-all duration-300" />
                </div>
                <h3 className="text-xl font-bold text-[#252362] mb-3">{amenity.title}</h3>
                <p className="text-gray-600">{amenity.desc}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-6">
          <motion.div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-[#252362] mb-4">Why Choose Our Network?</h2>
            <p className="text-lg text-gray-600 max-w-2xl mx-auto">Join the fastest-growing real estate network in India</p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                title: "Proven Business Model",
                desc: "Our MLM structure has helped hundreds achieve financial freedom",
                icon: FaChartLine
              },
              {
                title: "Comprehensive Training",
                desc: "Get access to industry-leading training and development programs",
                icon: FaUserTie
              },
              {
                title: "Marketing Support",
                desc: "Receive professional marketing materials and digital tools",
                icon: FaChartBar
              },
              {
                title: "Exclusive Properties",
                desc: "Access to premium properties not available elsewhere",
                icon: FaHome
              },
              {
                title: "Team Support",
                desc: "Dedicated support team to help you succeed",
                icon: FaUsers
              },
              {
                title: "Rewards Program",
                desc: "Earn additional bonuses and incentives for performance",
                icon: FaMedal
              }
            ].map((item, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-[#F8FAFF] p-8 rounded-2xl hover:shadow-xl transition-all duration-300 group hover:-translate-y-2"
              >
                <div className="bg-[#252362] rounded-xl w-14 h-14 flex items-center justify-center mb-6 group-hover:scale-110 transition-transform duration-300">
                  <item.icon className="text-2xl text-white" />
                </div>
                <h3 className="text-xl font-bold text-[#252362] mb-3">{item.title}</h3>
                <p className="text-gray-600">{item.desc}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Investment Benefits */}
      <section className="py-20 bg-gradient-to-r from-[#252362] to-[#3A3785] text-white">
        <div className="container mx-auto px-6">
          <motion.div className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-4">Investment Benefits</h2>
            <p className="text-lg opacity-90 max-w-2xl mx-auto">Secure your future with our premium real estate opportunities</p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              { number: "25%", text: "Average Annual Returns" },
              { number: "18%", text: "Property Value Growth" },
              { number: "12%", text: "Rental Yield" },
              { number: "40%", text: "Capital Appreciation" }
            ].map((item, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, scale: 0.9 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-white/10 backdrop-blur-sm p-8 rounded-2xl text-center hover:bg-white/20 transition-all duration-300"
              >
                <h3 className="text-4xl font-bold mb-3">{item.number}</h3>
                <p className="text-lg opacity-90">{item.text}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Success Stories */}
      <section className="py-16 sm:py-20 bg-[#F8FAFF]">
        <div className="container mx-auto px-6">
          <div className="flex flex-col lg:flex-row items-center gap-12">
            <motion.div
              className="lg:w-1/2"
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
            >
              <img
                src={teamMeeting}
                alt="Successful business meeting"
                className="w-full h-auto rounded-xl shadow-xl"
              />
            </motion.div>

            <motion.div
              className="lg:w-1/2"
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
            >
              <h2 className="text-3xl sm:text-4xl font-bold text-[#8B4513] mb-6">
                Real <span className="text-gradient bg-gradient-to-r from-[#FF4500] to-[#8B4513]">Success Stories</span>
              </h2>

              <div className="space-y-6">
                <div className="bg-white/80 backdrop-blur-sm p-6 rounded-xl shadow-sm">
                  <div className="flex items-center mb-4">
                    <img src={avatar1} alt="Amit Sharma" className="w-12 h-12 rounded-full object-cover mr-4" />
                    <div>
                      <h4 className="font-semibold text-[#8B4513]">Amit Sharma</h4>
                      <p className="text-[#8B4513]/70 text-sm">Team Leader, 2 years in network</p>
                    </div>
                  </div>
                  <p className="text-[#8B4513]/80 italic mb-4">
                    "When I started, I had no prior experience in real estate. Within 6 months, I was earning ₹1,25,000 per month, thanks to expert training and mentorship."
                  </p>
                  <div className="flex items-center text-yellow-400">
                    <FaStar className="w-4 h-4" />
                    <FaStar className="w-4 h-4" />
                    <FaStar className="w-4 h-4" />
                    <FaStar className="w-4 h-4" />
                    <FaStar className="w-4 h-4" />
                  </div>
                </div>

                <div className="bg-white/80 backdrop-blur-sm p-6 rounded-xl shadow-sm">
                  <div className="flex items-center mb-4">
                    <img src={avatar2} alt="Priya Verma" className="w-12 h-12 rounded-full object-cover mr-4" />
                    <div>
                      <h4 className="font-semibold text-[#8B4513]">Priya Verma</h4>
                      <p className="text-[#8B4513]/70 text-sm">Top Producer, 1 year in network</p>
                    </div>
                  </div>
                  <p className="text-[#8B4513]/80 italic mb-4">
                    "The network model helped me earn a passive income while growing my own sales. Last quarter alone, I made ₹3,50,000 in team commissions."
                  </p>
                  <div className="flex items-center text-yellow-400">
                    <FaStar className="w-4 h-4" />
                    <FaStar className="w-4 h-4" />
                    <FaStar className="w-4 h-4" />
                    <FaStar className="w-4 h-4" />
                    <FaStar className="w-4 h-4" />
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-20 bg-white">
        <div className="container mx-auto px-6">
          <motion.div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-[#252362] mb-4">Frequently Asked Questions</h2>
            <p className="text-lg text-gray-600 max-w-2xl mx-auto">Find answers to common questions about our properties and services</p>
          </motion.div>

          <div className="max-w-3xl mx-auto space-y-6">
            {[
              {
                q: "What types of properties do you offer?",
                a: "We offer a wide range of properties including residential plots, luxury villas, apartments, and commercial spaces. All our properties are located in prime locations with excellent connectivity and amenities."
              },
              {
                q: "What are the payment options available?",
                a: "We offer flexible payment plans including down payment with EMI options, bank loans, and full payment schemes. We work with major banks to provide attractive interest rates for our customers."
              },
              {
                q: "What amenities are included in your projects?",
                a: "Our projects include premium amenities such as 24/7 security, landscaped gardens, clubhouse, sports facilities, children's play area, and community spaces. Each project has its unique set of world-class amenities."
              },
              {
                q: "How do I schedule a site visit?",
                a: "You can schedule a site visit through our website, by calling our customer service, or visiting our office. Our team will arrange a convenient time for your visit and provide complete project information."
              },
              {
                q: "What documents are required for booking?",
                a: "Required documents include government-issued ID proof, address proof, PAN card, and passport-size photographs. Our team will guide you through the complete documentation process."
              },
              {
                q: "Do you provide after-sales service?",
                a: "Yes, we provide comprehensive after-sales service including property maintenance, documentation support, and customer assistance. Our dedicated team ensures a hassle-free experience."
              }
            ].map((item, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
                className="bg-[#F8FAFF] p-6 rounded-xl hover:shadow-lg transition-all duration-300"
              >
                <h3 className="text-xl font-bold text-[#252362] mb-3">{item.q}</h3>
                <p className="text-gray-600">{item.a}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Final CTA */}
      <section className="py-16 sm:py-20 bg-gradient-to-r from-[#252362] to-[#3A3785] text-white">
        <div className="container mx-auto px-6 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="max-w-4xl mx-auto"
          >
            <h2 className="text-3xl sm:text-4xl font-bold mb-6">Ready to Transform Your Real Estate Career?</h2>
            <p className="text-xl mb-8 max-w-3xl mx-auto font-medium">
              Join our network of top-performing real estate professionals and start building your empire today.
            </p>
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="inline-block"
            >
              <Link
                to="/register"
                className="inline-flex items-center px-8 py-4 bg-gradient-to-r from-[#FFD700] to-[#DBE2F2] text-[#252362] font-semibold rounded-full shadow-xl hover:shadow-2xl transition-all duration-300 text-lg"
              >
                Get Started Now <FaArrowRight className="ml-3 transition-transform group-hover:translate-x-1" />
              </Link>
            </motion.div>
            <p className="mt-6 text-white/80">
              Limited spots available. Apply today to secure your position.
            </p>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default Home;