import React from "react";
import { motion } from "framer-motion";
import { FaShieldAlt, FaHandshake, FaBalanceScale, FaAward, FaCheckCircle, FaFileContract, FaUserShield, FaMoneyBillWave } from "react-icons/fa";

const LegalPage = () => {
  const certificates = [
    {
      title: "RERA Certification",
      number: "UPRERAPRJ12345",
      image: "/certificates/rera.jpg",
      issueDate: "2023"
    },
    {
      title: "ISO 9001:2015",
      number: "ISO9001-2023-12345",
      image: "/certificates/iso.jpg",
      issueDate: "2023"
    },
    {
      title: "MLM License",
      number: "MLM-UP-2023-789",
      image: "/certificates/mlm.jpg",
      issueDate: "2023"
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Hero Section */}
      <div className="relative bg-gradient-to-r from-blue-900 via-indigo-800 to-purple-900 py-24 px-5 overflow-hidden">
        <div className="absolute inset-0  opacity-20"></div>
        <div className="absolute -top-24 -right-24 w-96 h-96 bg-yellow-400 rounded-full blur-3xl opacity-20 animate-pulse"></div>
        <div className="absolute -bottom-24 -left-24 w-96 h-96 bg-blue-400 rounded-full blur-3xl opacity-20 animate-pulse"></div>
        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-7xl mx-auto text-center text-white relative"
        >
          <FaBalanceScale className="text-6xl mx-auto mb-8 text-yellow-400 transform hover:scale-110 transition-transform" />
          <h1 className="text-5xl md:text-6xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-white via-yellow-200 to-white">
            Legal Information
          </h1>
          <p className="text-xl text-gray-300 max-w-2xl mx-auto leading-relaxed">
            Ensuring transparency and compliance in every aspect of our real estate MLM program
          </p>
        </motion.div>
      </div>

      {/* Certificates Section with 3D effect */}
      <section className="py-20 px-5 relative">
        <div className="absolute inset-0 bg-gradient-to-b from-blue-50/50 to-transparent"></div>
        <div className="max-w-7xl mx-auto relative">
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <span className="text-blue-600 font-semibold mb-4 block">VERIFIED & CERTIFIED</span>
            <h2 className="text-4xl font-bold text-gray-800 mb-4">Our Certifications</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">Recognized and certified by leading authorities in the real estate and MLM industry</p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {certificates.map((cert, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
                className="bg-white rounded-2xl shadow-xl overflow-hidden group hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-2"
                style={{
                  perspective: '1000px',
                }}
              >
                <div className="relative h-56 overflow-hidden">
                  <img 
                    src={cert.image} 
                    alt={cert.title}
                    className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent"></div>
                  <FaAward className="absolute bottom-4 right-4 text-4xl text-yellow-400 transform group-hover:rotate-12 transition-transform" />
                </div>
                <div className="p-8 bg-gradient-to-b from-white to-gray-50">
                  <h3 className="text-2xl font-bold text-gray-800 mb-3">{cert.title}</h3>
                  <p className="text-gray-600 mb-2">Certificate No: {cert.number}</p>
                  <p className="text-yellow-600 font-semibold">Issued: {cert.issueDate}</p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Legal Terms Section with modern cards */}
      <section className="py-20 px-5 bg-gradient-to-b from-gray-50 to-white">
        <div className="max-w-5xl mx-auto">
          <div className="grid md:grid-cols-2 gap-8">
            {[
              {
                title: "1. Introduction",
                icon: FaHandshake,
                content: "By accessing or using our services, you agree to comply with and be bound by the following legal terms. These terms ensure fair and transparent business practices."
              },
              {
                title: "2. Compliance",
                icon: FaBalanceScale,
                content: "Our real estate MLM program strictly adheres to all applicable real estate and MLM laws. Users must ensure they comply with their local regulations."
              },
              {
                title: "3. Earnings Policy",
                icon: FaMoneyBillWave,
                content: "Income earned through this program depends on individual efforts, network growth, and external market conditions. We maintain complete transparency."
              },
              {
                title: "4. Data Protection",
                icon: FaUserShield,
                content: "We take your privacy seriously and implement strict measures to protect your personal and financial information in accordance with data protection laws."
              }
            ].map((section, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, x: index % 2 === 0 ? -20 : 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                className="bg-white rounded-2xl p-8 shadow-xl hover:shadow-2xl transition-all duration-300 border border-gray-100"
              >
                <div className="flex items-start space-x-6">
                  <div className="bg-gradient-to-br from-blue-500 to-indigo-600 p-4 rounded-xl text-white">
                    <section.icon className="text-2xl" />
                  </div>
                  <div>
                    <h3 className="text-xl font-bold text-gray-800 mb-3">{section.title}</h3>
                    <p className="text-gray-600 leading-relaxed">{section.content}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section with glass effect */}
      <section className="py-20 px-5 relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-r from-blue-500/10 to-purple-500/10"></div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="max-w-3xl mx-auto text-center relative backdrop-blur-sm bg-white/80 p-12 rounded-3xl shadow-2xl"
        >
          <FaFileContract className="text-5xl text-blue-600 mx-auto mb-8" />
          <h2 className="text-4xl font-bold text-gray-800 mb-6">Ready to Get Started?</h2>
          <p className="text-gray-600 mb-8 text-lg">
            Join our legally compliant and transparent real estate MLM network today.
          </p>
          <motion.button 
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-gradient-to-r from-blue-600 to-indigo-600 text-white px-10 py-4 rounded-full font-semibold text-lg hover:shadow-xl hover:shadow-blue-500/30 transition-all duration-300"
          >
            Register Now
          </motion.button>
        </motion.div>
      </section>
    </div>
  );
};

export default LegalPage;
