// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

import React from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faLock, faShieldHalved, faBuilding } from "@fortawesome/free-solid-svg-icons";
import { sendApiRequest } from "../utils";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "./Loader";
import { useDispatch } from "react-redux";
import { setValue } from '../features/UserInfo/userSlice';
import loginBg from '../assets/images/loginbg.jpg';
import logo from '../assets/images/logo.png';
import Swal from 'sweetalert2';
// import withReactContent from 'sweetalert2-react-content';

// const MySwal = withReactContent(Swal);

const Login = ({setIsRefresh}) => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitting },
    } = useForm();

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onSubmit = async (data) => {
        try {
            const payload = { loginId: data.username, password: data.password };
            const response = await sendApiRequest('api/auth/login', payload, "POST");

            if (response.data.success) {
                // Store token
                localStorage.setItem('auth_token', response.data.token);
                console.log(response.data.user)
                
                // Store user data in Redux
                dispatch(setValue(response.data.user));
                setIsRefresh("Rescheck");

                // Show success alert
                Swal.fire({
                    title: 'Login Successful!',
                    html: `Welcome back, ${response.data.user.fullName}!`,
                    icon: 'success',
                    confirmButtonText: 'Continue',
                    confirmButtonColor: '#2AADCC',
                    background: '#ffffff'
                }).then(() => {
                    // Navigate to dashboard
                    navigate('/');
                });
            } else {
                // Show error alert if login failed
                Swal.fire({
                    title: 'Login Failed',
                    text: response.data.message || 'Invalid credentials',
                    icon: 'error',
                    confirmButtonText: 'Try Again',
                    confirmButtonColor: '#2AADCC'
                });
            }
        } catch (error) {
            console.error("Login error:", error);
            Swal.fire({
                title: 'Error',
                text: error.response?.data?.message || 'Something went wrong. Please try again.',
                icon: 'error',
                confirmButtonText: 'OK',
                confirmButtonColor: '#2AADCC'
            });
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center p-10 relative">
            {/* Background Image */}
            <div
                className="absolute inset-0 bg-cover bg-center bg-no-repeat"
                style={{
                    backgroundImage: `url(${loginBg})`,
                    opacity: '1',  // Increased opacity
                }}
            >
            </div>

            {/* Floating Glass Card */}
            <div className="relative bg-white/95 backdrop-blur-lg rounded-2xl shadow-2xl p-10 w-full max-w-md mx-4 overflow-hidden border border-[#2AADCC]/20 transform transition-all duration-300 hover:shadow-3xl z-10">

                {/* Decorative Elements */}
                <div className="absolute -top-20 -right-20 w-48 h-48 rounded-full bg-[#30AEB8]/20 blur-3xl -z-10"></div>
                <div className="absolute -bottom-20 -left-20 w-48 h-48 rounded-full bg-[#2AADCC]/20 blur-3xl -z-10"></div>

                {/* Company Branding */}
                <div className="flex flex-col items-center mb-8">
                    <div className="mb-4">
                        <img
                            src={logo}
                            alt="Prayag Properties Logo"
                            className="h-28 w-auto object-contain"
                        />
                    </div>
                    <h1 className="text-3xl font-bold text-center mb-1">
                        <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#262362] to-[#2AADCC]">
                            Prayag Properties & Mishra Constructions
                        </span>
                    </h1>
                </div>

                <form className="space-y-5" onSubmit={handleSubmit(onSubmit)}>
                    {/* Input fields styling updated */}
                    <div className="group">
                        <label htmlFor="username" className="block text-sm font-medium text-[#262362] mb-2 ml-1 transition-all duration-200 group-focus-within:text-[#2AADCC]">
                            Username or User ID
                        </label>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-400 group-focus-within:text-indigo-500 transition-colors">
                                <FontAwesomeIcon icon={faUser} />
                            </div>
                            <input
                                id="username"
                                type="text"
                                placeholder="Enter username or ID"
                                className={`block w-full pl-10 pr-3 py-3 rounded-xl border-2 bg-white/80 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500/20 transition-all duration-200 ${errors.username ? "border-rose-300 focus:ring-rose-200" : "border-gray-200 hover:border-indigo-200"
                                    }`}
                                {...register("username", {
                                    required: "Username or User ID is required",
                                    minLength: {
                                        value: 3,
                                        message: "Username must be at least 3 characters",
                                    },
                                })}
                            />
                        </div>
                        {errors.username && (
                            <p className="mt-1.5 text-sm text-rose-600 animate-fade-in">{errors.username.message}</p>
                        )}
                    </div>

                    {/* Password field label colors updated similarly */}
                    <div className="group">
                        <div className="flex items-center justify-between mb-2 ml-1">
                            <label htmlFor="password" className="block text-sm font-medium text-[#262362] transition-all duration-200 group-focus-within:text-[#2AADCC]">
                                Password
                            </label>
                            <Link to="/forgot-password" className="text-sm font-medium text-[#2AADCC] hover:text-[#30AEB8] hover:underline transition-colors">
                                Forgot Password?
                            </Link>
                        </div>
                        <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-gray-400 group-focus-within:text-indigo-500 transition-colors">
                                <FontAwesomeIcon icon={faLock} />
                            </div>
                            <input
                                id="password"
                                type="password"
                                placeholder="Enter your password"
                                className={`block w-full pl-10 pr-3 py-3 rounded-xl border-2 bg-white/80 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500/20 transition-all duration-200 ${errors.password ? "border-rose-300 focus:ring-rose-200" : "border-gray-200 hover:border-indigo-200"
                                    }`}
                                {...register("password", {
                                    required: "Password is required",
                                    minLength: {
                                        value: 8,
                                        message: "Password must be at least 8 characters",
                                    },
                                })}
                            />
                        </div>
                        {errors.password && (
                            <p className="mt-1.5 text-sm text-rose-600 animate-fade-in">{errors.password.message}</p>
                        )}
                    </div>


                    {/* Submit Button */}
                    <button
                        type="submit"
                        disabled={isSubmitting}
                        className="w-full flex justify-center items-center py-3.5 px-4 rounded-xl shadow-lg text-white bg-gradient-to-r from-[#262362] to-[#2AADCC] hover:from-[#30AEB8] hover:to-[#33AEB5] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2AADCC]/30 transition-all duration-300 font-medium group overflow-hidden"
                    >
                        <span className="relative z-10 group-hover:scale-105 transition-transform">
                            {isSubmitting ? (
                                <Loader />
                            ) : (
                                "Login"
                            )}
                        </span>
                    </button>
                </form>

                {/* Footer Links */}
                <div className="mt-8 pt-5 border-t border-[#2AADCC]/10 text-center space-y-4">
                    <p className="text-sm text-[#262362]">
                        Are you a customer?{" "}
                        <Link
                            to="/login-customer"
                            className="font-medium text-[#2AADCC] hover:text-[#30AEB8] hover:underline transition-colors"
                        >
                            Login here
                        </Link>
                    </p>
                    <p className="text-sm text-[#262362]">
                        Don't have an account?{" "}
                        <Link
                            to="/register"
                            className="font-medium text-[#2AADCC] hover:text-[#30AEB8] hover:underline transition-colors"
                        >
                            Create now
                        </Link>
                    </p>
                    <p className="text-xs text-[#262362]/60 mt-3">
                        © {new Date().getFullYear()} Prayag Properties & Mishra Constructions. All rights reserved.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Login;