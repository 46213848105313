import React, { useState, useRef } from 'react';
import { FaPrint } from 'react-icons/fa';
import { useReactToPrint } from 'react-to-print';

const ListInvoices = () => {
  const [invoices] = useState([
    { id: 1, invoiceNo: '-0010811', date: '09-03-2025 06:32:44', amount: 2487.00, status: 'Delivered' },
    { id: 2, invoiceNo: '-0010809', date: '08-03-2025 10:22:46', amount: 2500.00, status: 'Delivered' },
    { id: 3, invoiceNo: '-0010790', date: '06-03-2025 09:41:41', amount: 2500.00, status: 'Delivered' },
    { id: 4, invoiceNo: '-0010789', date: '06-03-2025 09:41:07', amount: 2500.00, status: 'Delivered' },
    { id: 5, invoiceNo: '-0010779', date: '06-03-2025 06:08:51', amount: 3000.00, status: 'Delivered' },
    { id: 6, invoiceNo: '-0010770', date: '05-03-2025 05:47:12', amount: 8000.00, status: 'Delivered' },
    { id: 7, invoiceNo: '-0010728', date: '01-03-2025 11:19:39', amount: 8000.00, status: 'Delivered' },
    { id: 8, invoiceNo: '-0010638', date: '28-02-2025 08:35:05', amount: 3300.00, status: 'Delivered' },
  ]);
  

  // Add this state to track selected invoice

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#49c2c7]/5 via-[#25b2da]/10 to-[#0d61b5]/5 py-4 sm:py-6 md:py-8">

      <div className="container mx-auto px-3 sm:px-4 md:px-6">
        <div className="bg-white rounded-lg sm:rounded-xl md:rounded-2xl shadow-xl overflow-hidden border border-[#25b2da]/20">
          <div className="bg-gradient-to-r from-[#13407e] to-[#0d61b5] text-white px-4 sm:px-6 py-3 sm:py-4">
            <h1 className="text-lg sm:text-xl font-semibold">List Invoices</h1>
          </div>

          {/* Search Form */}
          <div className="p-4 sm:p-6 border-b border-gray-200">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-3 sm:gap-4">
              <div>
                <label className="block text-sm font-medium text-[#13407e] mb-1.5 sm:mb-2">Invoice no</label>
                <input
                  type="text"
                  className="w-full px-3 sm:px-4 py-2 rounded-lg border border-[#49c2c7] focus:border-[#0d61b5] focus:ring-2 focus:ring-[#25b2da]/20 outline-none text-sm"
                  placeholder="Enter invoice number"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-[#13407e] mb-1.5 sm:mb-2">Status</label>
                <select className="w-full px-3 sm:px-4 py-2 rounded-lg border border-[#49c2c7] focus:border-[#0d61b5] focus:ring-2 focus:ring-[#25b2da]/20 outline-none text-sm">
                  <option value="">Select Status</option>
                  <option value="delivered">Delivered</option>
                  <option value="pending">Pending</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-[#13407e] mb-1.5 sm:mb-2">From</label>
                <input
                  type="date"
                  className="w-full px-3 sm:px-4 py-2 rounded-lg border border-[#49c2c7] focus:border-[#0d61b5] focus:ring-2 focus:ring-[#25b2da]/20 outline-none text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-[#13407e] mb-1.5 sm:mb-2">To</label>
                <input
                  type="date"
                  className="w-full px-3 sm:px-4 py-2 rounded-lg border border-[#49c2c7] focus:border-[#0d61b5] focus:ring-2 focus:ring-[#25b2da]/20 outline-none text-sm"
                />
              </div>
              <div className="flex items-end">
                <button className="w-full sm:w-auto bg-[#0d61b5] text-white px-4 sm:px-6 py-2 rounded-lg hover:bg-[#13407e] transition-colors duration-300 text-sm sm:text-base">
                  SEARCH
                </button>
              </div>
            </div>
          </div>

          {/* Invoices Table */}
          <div className="overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-3 sm:px-6 py-2 sm:py-3 text-left text-xs font-medium text-[#13407e] uppercase tracking-wider">Sr.No</th>
                      <th className="px-3 sm:px-6 py-2 sm:py-3 text-left text-xs font-medium text-[#13407e] uppercase tracking-wider">Invoice No</th>
                      <th className="px-3 sm:px-6 py-2 sm:py-3 text-left text-xs font-medium text-[#13407e] uppercase tracking-wider hidden sm:table-cell">Invoice Date</th>
                      <th className="px-3 sm:px-6 py-2 sm:py-3 text-left text-xs font-medium text-[#13407e] uppercase tracking-wider">Amount</th>
                      <th className="px-3 sm:px-6 py-2 sm:py-3 text-center text-xs font-medium text-[#13407e] uppercase tracking-wider">Actions</th>
                      <th className="px-3 sm:px-6 py-2 sm:py-3 text-left text-xs font-medium text-[#13407e] uppercase tracking-wider hidden md:table-cell">Status</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {invoices.map((invoice) => (
                      <tr key={invoice.id} className="hover:bg-gray-50">
                        <td className="px-3 sm:px-6 py-2 sm:py-4 whitespace-nowrap text-xs sm:text-sm text-gray-900">{invoice.id}</td>
                        <td className="px-3 sm:px-6 py-2 sm:py-4 whitespace-nowrap text-xs sm:text-sm text-gray-900">{invoice.invoiceNo}</td>
                        <td className="px-3 sm:px-6 py-2 sm:py-4 whitespace-nowrap text-xs sm:text-sm text-gray-900 hidden sm:table-cell">{invoice.date}</td>
                        <td className="px-3 sm:px-6 py-2 sm:py-4 whitespace-nowrap text-xs sm:text-sm text-gray-900">{invoice.amount.toFixed(2)}</td>
                        <td className="px-3 sm:px-6 py-2 sm:py-4 whitespace-nowrap text-center">
                          <div className="flex items-center justify-center space-x-2 sm:space-x-3">
                            <button 
                              className="text-[#0d61b5] hover:text-[#13407e]"
                            >
                              <FaPrint size={16} className="sm:w-5 sm:h-5" />
                            </button>
                            <input
                              type="file"
                              className="w-24 sm:w-32 text-xs sm:text-sm text-gray-500
                              file:mr-2 sm:file:mr-4 file:py-1 sm:file:py-2 file:px-2 sm:file:px-4
                              file:rounded-full file:border-0
                              file:text-xs file:font-semibold
                              file:bg-[#0d61b5] file:text-white
                              hover:file:bg-[#13407e]"
                            />
                          </div>
                        </td>
                        <td className="px-3 sm:px-6 py-2 sm:py-4 whitespace-nowrap hidden md:table-cell">
                          <span className="px-2 sm:px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                            {invoice.status}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListInvoices;