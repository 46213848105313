import React, { useState } from "react";
import { motion } from "framer-motion";
import gallery1 from "../assets/images/gallery1.jpeg";
import gallery2 from "../assets/images/gallery2.jpeg";
import gallery3 from "../assets/images/gallery3.jpeg";
import gallery4 from "../assets/images/gallery4.jpg";
import gallery5 from "../assets/images/gallery5.jpg";
import gallery6 from "../assets/images/gallery6.jpg";

const allImages = [
  { src: gallery1, category: "ARCHITECTURE" },
  { src: gallery2, category: "ARCHITECTURE" },
  { src: gallery3, category: "ARCHITECTURE" },
  { src: gallery4, category: "ARCHITECTURE" },
  { src: gallery5, category: "ARCHITECTURE" },
  { src: gallery6, category: "ARCHITECTURE" },
];

const fadeIn = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.8 } }
};

const imageVariants = {
  hover: { scale: 1.03, transition: { duration: 0.3 } }
};

const OurGallery = () => {
  const [visibleImages, setVisibleImages] = useState(3);
  
  const loadMore = () => {
    setVisibleImages(prev => Math.min(prev + 3, allImages.length));
  };

  const displayedImages = allImages.slice(0, visibleImages);

  return (
    <div className="min-h-screen bg-gray-50 py-20 px-5 font-['Montserrat']">
      <style jsx global>{`
        @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
      `}</style>
      
      <div className="max-w-7xl mx-auto">
        {/* Gallery Header */}
        <motion.div 
          initial="hidden"
          animate="visible"
          variants={fadeIn}
          className="text-center mb-20"
        >
          <h2 className="text-5xl md:text-6xl font-semibold text-gray-900 mb-4 tracking-tighter">
            VISUAL GALLERY
          </h2>
          <div className="w-24 h-0.5 bg-gradient-to-r from-transparent via-gray-400 to-transparent mx-auto mb-6"></div>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto font-normal tracking-wide">
            A curated collection of our finest visual moments
          </p>
        </motion.div>

        {/* Gallery Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {displayedImages.map((image, index) => (
            <motion.div
              key={index}
              initial="hidden"
              animate="visible"
              variants={fadeIn}
              whileHover="hover"
              variants={imageVariants}
              className="relative overflow-hidden rounded-lg group"
            >
              <img
                src={image.src}
                alt={`Gallery image ${index + 1}`}
                className="w-full h-96 object-cover transition-all duration-500"
              />
              
              {/* Overlay */}
              <div className="absolute inset-0 bg-gradient-to-t from-black/70 via-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500 flex items-end p-6">
                <div className="transform translate-y-5 group-hover:translate-y-0 transition-transform duration-500">
                  <span className="text-xs text-white/80 font-medium tracking-widest">
                    {image.category}
                  </span>
                  <button className="mt-2 text-white text-sm font-medium tracking-wide flex items-center gap-2 hover:text-gray-200 transition-colors">
                    <span>EXPLORE</span>
                    <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                    </svg>
                  </button>
                </div>
              </div>
              
              {/* Corner Accents */}
              <div className="absolute top-4 right-4 w-8 h-8 border-t border-r border-white/40 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
              <div className="absolute bottom-4 left-4 w-8 h-8 border-b border-l border-white/40 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
            </motion.div>
          ))}
        </div>

        {/* Load More Button - Only shows if there are more images to load */}
        {visibleImages < allImages.length && (
          <motion.div 
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="text-center mt-12"
          >
            <button 
              onClick={loadMore}
              className="px-8 py-3.5 border border-gray-300 rounded-full text-gray-700 hover:bg-white transition-all duration-300 text-sm font-medium tracking-wider flex items-center gap-2 mx-auto"
            >
              LOAD MORE
              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
              </svg>
            </button>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default OurGallery;