import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthProvider from './components/AuthProvider';
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Header from "./pages/Header";
import Footer from "./pages/Footer";
import LoginModal from "./pages/LoginModal";
import RegisterModal from "./pages/PartnerRegistration";
import CustomerLogin from "./pages/CustomerLogin";
import CustomerDashboardLayout from "./pages/CustomerDashboard/CustomerDashboardLayout";
import CustomerOverview from "./pages/CustomerDashboard/Overview";
import CustomerProperties from "./pages/CustomerDashboard/Properties";
import CustomerPayments from "./pages/CustomerDashboard/Payments";
import CustomerProfile from "./pages/CustomerDashboard/Profile";
import ProtectedRoute from "./components/ProtectedRoute";
import OurPlan from "./pages/OurPlan";
import LegalPage from "./pages/LegalPage";
import OurGallery from "./pages/OurGallery";
import PlotAvailability from "./pages/PlotAvailability";
import CustomerRegistration from "./pages/CustomerRegistration";
import RegistrationPage from "./pages/RegistrationPage";
import DashboardLayout from "./pages/DashboardLayout";
import Overview from "./pages/Overview";
import ShowProfile from "./pages/ShowProfile";
import UpdateProfile from "./pages/UpdateProfile";
import UpdateLoginPassword from "./pages/UpdateLoginPassword";
import UpdateKYC from "./pages/UpdateKYC";
import BusinessPlanChart from "./pages/BusinessPlanChart";
import BuyPackage from "./pages/BuyPackage";
import ListInvoices from "./pages/ListInvoices";
import InvoicePage from "./pages/InvoicePage";
import AddTeamMember from "./components/admin/AddTeamMember";
import TeamMemberList from "./components/admin/TeamMemberList";
import AddPlan from "./components/admin/AddPlan";
import PlanList from "./components/admin/PlanList";
import AdminLayout from "./components/admin/AdminLayout";
import { useSelector, useDispatch } from "react-redux";
import { getValue, setValue } from "./features/UserInfo/userSlice";
import { sendApiRequest } from "./utils";

function App() {
  const dispatch = useDispatch();
  const user = useSelector(getValue);
  const userData = user.payload?.user_info?.[0];
  const [isRefresh, setIsRefresh] = useState("yes");

  const getUserInfo = async () => {
    try {
      const response = await sendApiRequest('api/auth/verify-token', {}, "GET");
      console.log(response)
      if (response.data.success) {
        dispatch(setValue(response.data));
      }
    } catch (error) {
      console.error('Error fetching user info:', error);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, [isRefresh]);

  return (
    <Router>
      <AuthProvider>
        <AppContent isRefresh={isRefresh} setIsRefresh={setIsRefresh}  />
      </AuthProvider>
    </Router>
  );
}

function AppContent({ isRefresh, setIsRefresh }) {
  const [showBackToTop, setShowBackToTop] = useState(false);
  const location = useLocation();
  const isDashboardRoute =
    location.pathname.startsWith("/dashboard") ||
    location.pathname.startsWith("/print-invoice") ||
    location.pathname.startsWith("/customer") ||
    location.pathname.startsWith("/admin");

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowBackToTop(true);
      } else {
        setShowBackToTop(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="font-['Montserrat']">
      {!isDashboardRoute && <Header isRefresh={isRefresh} setIsRefresh={setIsRefresh} />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/login-customer" element={<CustomerLogin isRefresh={isRefresh} setIsRefresh={setIsRefresh}/>} />
        <Route
          path="/customer"
          element={
            <ProtectedRoute>
              <CustomerDashboardLayout />
            </ProtectedRoute>
          }
        >
          <Route path="dashboard" element={<CustomerOverview />} />
          <Route path="properties" element={<CustomerProperties />} />
          <Route path="payments" element={<CustomerPayments />} />
          <Route path="profile" element={<CustomerProfile />} />
        </Route>
        <Route path="/register" element={<RegistrationPage />} />
        <Route path="/login" element={<LoginModal isRefresh={isRefresh} setIsRefresh={setIsRefresh}/>} />
        <Route path="/plan" element={<OurPlan />} />
        <Route path="/legal" element={<LegalPage />} />
        <Route path="/dashboard" element={<DashboardLayout />}>
          <Route index element={<Overview />} />
          <Route path="show-profile" element={<ShowProfile />} />
          <Route path="update-profile" element={<UpdateProfile />} />
          <Route
            path="update-login-password"
            element={<UpdateLoginPassword />}
          />
          <Route path="update-kyc" element={<UpdateKYC />} />
          <Route path="show-business-plan" element={<BusinessPlanChart />} />
          <Route path="buy-package" element={<BuyPackage />} />
          <Route path="list-invoices" element={<ListInvoices />} />
          {/* Add other dashboard routes here */}
          <Route path="team-member/add" element={<AddTeamMember />} />
          <Route path="team-member/list" element={<TeamMemberList />} />

          {/* Add other dashboard routes here */}
          <Route path="plan/add" element={<AddPlan />} />
          <Route path="plan/list" element={<PlanList />} />
        </Route>

        {/* ADMIN LAYOUT */}
        <Route path="/admin" element={<AdminLayout />}>
          {/* Add other dashboard routes here */}
          <Route path="team-member/add" element={<AddTeamMember />} />
          <Route path="team-member/list" element={<TeamMemberList />} />

          {/* Add other dashboard routes here */}
          <Route path="plan/add" element={<AddPlan />} />
          <Route path="plan/list" element={<PlanList />} />
        </Route>
        {/* ADMIN END */}

        <Route path="/print-invoice" element={<InvoicePage />} />
        <Route path="/gallery" element={<OurGallery />} />
        <Route path="/plot-availability" element={<PlotAvailability />} />
      </Routes>
      {!isDashboardRoute && <Footer />}
      {showBackToTop && (
        <button
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          className="fixed bottom-8 right-8 bg-[#2AADCC] text-white p-4 rounded-full shadow-lg hover:bg-[#30AEB8] transition-colors z-50"
          title="Back to Top"
        >
          ↑
        </button>
      )}
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </div>
  );
}

export default App;
