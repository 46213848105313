import React from 'react';
import { useForm } from 'react-hook-form';

const UpdateProfile = () => {
  const { register, handleSubmit } = useForm({
    defaultValues: {
      name: 'ARCHANA DEVI',
      mobile: '8808613763',
      email: 'SUNILKR.ALLD@GMAIL.COM',
      dob: '20/10/1988',
      address: 'TILHAPUR URF NAHARPUR POST ISMAILGANJ',
      city: 'PRAYAGRAJ',
      state: 'Uttar Pradesh',
      country: 'INDIA',
      pinCode: '211013',
      fatherName: '',
      nomineeName: '',
      nomineeAge: '0',
      nomineeRelation: '',
      nomineeMobile: '',
      bankName: 'BANK OF BARODA',
      accountHolderName: 'SUNIL KUMAR',
      branchName: 'PADILA',
      accountNumber: '39490100006988',
      ifscCode: 'BARB0PADILA',
      panNumber: 'GTXPD5269J',
      mobileNumber: '913682918698',
      accountType: 'Saving',
      phonepeId: '0',
      googlePayId: '0'
    }
  });

  const onSubmit = (data) => {
    console.log(data);
    // Handle form submission
  };

  const inputClasses = "w-full px-3 sm:px-4 py-2.5 sm:py-3 text-sm sm:text-base rounded-lg border border-[#49c2c7] focus:border-[#0d61b5] focus:ring-2 focus:ring-[#25b2da]/20 transition-all duration-200 outline-none";
  const readOnlyClasses = "w-full px-3 sm:px-4 py-2.5 sm:py-3 text-sm sm:text-base rounded-lg bg-[#49c2c7]/10 text-[#13407e] font-medium border-none";
  const sectionClasses = "bg-white rounded-xl shadow-md p-4 sm:p-6 hover:shadow-lg transition-shadow duration-300 border border-[#49c2c7]/20";
  const headingClasses = "text-lg sm:text-xl font-semibold text-[#13407e] mb-4 sm:mb-6 pb-2 border-b-2 border-[#25b2da]/30";

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#49c2c7]/10 to-[#25b2da]/10 py-4 sm:py-8">
      <div className="container mx-auto px-3 sm:px-4 max-w-6xl">
        <div className="bg-white rounded-xl sm:rounded-2xl shadow-xl overflow-hidden border border-[#49c2c7]/20">
          <div className="bg-gradient-to-r from-[#13407e] to-[#0d61b5] text-white px-4 sm:px-8 py-4 sm:py-5 flex justify-between items-center">
            <h1 className="text-xl sm:text-2xl font-semibold">Update Profile</h1>
            <div className="flex space-x-2 sm:space-x-3">
              <span className="w-2 sm:w-3 h-2 sm:h-3 rounded-full bg-[#2ba974]"></span>
              <span className="w-2 sm:w-3 h-2 sm:h-3 rounded-full bg-[#25b2da]"></span>
              <span className="w-2 sm:w-3 h-2 sm:h-3 rounded-full bg-[#49c2c7]"></span>
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="p-4 sm:p-8 space-y-6 sm:space-y-8">
            {/* Personal Details Section */}
            <div className={sectionClasses}>
              <h2 className={headingClasses}>Personal Details</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
                <input {...register('name')} className={readOnlyClasses} readOnly />
                <input {...register('mobile')} className={readOnlyClasses} readOnly />
                <input {...register('email')} className={readOnlyClasses} readOnly />
                <input {...register('dob')} type="date" className={inputClasses} />
                <input {...register('address')} className={inputClasses} placeholder="Address" />
                <input {...register('city')} className={inputClasses} placeholder="City" />
                <input {...register('state')} className={inputClasses} placeholder="State" />
                <input {...register('country')} className={inputClasses} placeholder="Country" />
                <input {...register('pinCode')} className={inputClasses} placeholder="Pin Code" />
              </div>
            </div>

            {/* Profile Image Section */}
            <div className={sectionClasses}>
              <h2 className={headingClasses}>Profile Image</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
                <div className="relative group">
                  <input
                    type="file"
                    className="file:mr-3 sm:file:mr-4 file:py-1.5 sm:file:py-2 file:px-3 sm:file:px-4 file:rounded-full file:border-0 
                    file:text-xs sm:file:text-sm file:font-medium file:bg-[#0d61b5] file:text-white hover:file:bg-[#13407e] 
                    cursor-pointer w-full text-xs sm:text-sm text-gray-500 border border-[#49c2c7] rounded-lg p-1.5 sm:p-2"
                  />
                </div>
                <input {...register('fatherName')} className={inputClasses} placeholder="Father's Name" />
              </div>
            </div>

            {/* Nominee Details Section */}
            <div className={sectionClasses}>
              <h2 className={headingClasses}>Nominee Details</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
                <input {...register('nomineeName')} className={inputClasses} placeholder="Nominee Name" />
                <input {...register('nomineeAge')} className={inputClasses} placeholder="Age" type="number" />
                <select {...register('nomineeRelation')} className={`${inputClasses} cursor-pointer`}>
                  <option value="">Select Relation</option>
                  <option value="spouse">Spouse</option>
                  <option value="child">Child</option>
                  <option value="parent">Parent</option>
                  <option value="sibling">Sibling</option>
                </select>
                <input {...register('nomineeMobile')} className={inputClasses} placeholder="Nominee Mobile" />
              </div>
            </div>

            {/* Bank Details Section */}
            <div className={sectionClasses}>
              <h2 className={headingClasses}>Bank Details</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
                <input {...register('bankName')} className={inputClasses} placeholder="Bank Name" />
                <input {...register('accountHolderName')} className={inputClasses} placeholder="Account Holder Name" />
                <input {...register('branchName')} className={inputClasses} placeholder="Branch Name" />
                <input {...register('accountNumber')} className={inputClasses} placeholder="Account Number" />
                <input {...register('ifscCode')} className={inputClasses} placeholder="IFSC Code" />
                <input {...register('panNumber')} className={inputClasses} placeholder="PAN Number" />
                <input {...register('mobileNumber')} className={inputClasses} placeholder="Mobile Number" />
                <select {...register('accountType')} className={`${inputClasses} cursor-pointer`}>
                  <option value="Saving">Saving</option>
                  <option value="Current">Current</option>
                </select>
              </div>
            </div>

            {/* Wallet Details Section */}
            <div className={sectionClasses}>
              <h2 className={headingClasses}>Wallet Details</h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
                <input {...register('phonepeId')} className={inputClasses} placeholder="PhonePe ID/Mobile no" />
                <input {...register('googlePayId')} className={inputClasses} placeholder="Google pay ID/Mobile no" />
              </div>
            </div>

            <div className="flex justify-start pt-2 sm:pt-4">
              <button
                type="submit"
                className="w-full sm:w-auto bg-gradient-to-r from-[#13407e] to-[#0d61b5] text-white px-6 sm:px-8 py-2.5 sm:py-3 
                text-sm sm:text-base rounded-lg hover:from-[#0d61b5] hover:to-[#13407e] transition-all duration-300 
                transform hover:scale-105 focus:ring-4 focus:ring-[#25b2da]/20 shadow-md hover:shadow-lg"
              >
                Update Profile
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateProfile;