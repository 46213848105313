import React, { useState } from 'react';

const ShowProfile = () => {
  const [activeTab, setActiveTab] = useState('personal');

  const userDetails = {
    name: "ARCHANA DEVI",
    id: "RVI865271",
    mobile: "8808613763",
    sponsorId: "RVI258471",
    guardianName: "",
    nomineeName: "",
    email: "SUNILKR.ALLD@GMAIL.COM",
    address: "TILHAPUR URF NAHARPUR POST ISMAILGANJ",
    city: "PRAYAGRAJ",
    state: "Uttar Pradesh",
    country: "INDIA",
    pinCode: "211013",
    bankDetails: {
      bankName: "BANK OF BARODA",
      accountHolderName: "SUNIL KUMAR",
      branchName: "PADILA",
      accountNo: "39490100006988",
      ifscCode: "BARB0PADILA",
      panNo: "GTXPD5269J"
    },
    walletDetails: {
      phonepeId: "0",
      googlePayId: "0"
    }
  };

  const PersonalProfileTab = () => (
    <div className="p-3 sm:p-4 md:p-6 lg:p-8">
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-3 sm:gap-4 md:gap-6 lg:gap-8">
        {/* Profile Card */}
        <div className="flex flex-col items-center bg-[#49c2c7]/10 rounded-lg sm:rounded-xl lg:rounded-2xl shadow-lg p-4 sm:p-5 lg:p-6 border border-[#25b2da]/20">
          <div className="w-28 h-28 xs:w-32 xs:h-32 sm:w-36 sm:h-36 md:w-40 md:h-40 lg:w-48 lg:h-48 bg-gradient-to-r from-[#0d61b5]/5 to-[#25b2da]/10 rounded-full flex items-center justify-center mb-3 sm:mb-4 lg:mb-6 border-4 border-white shadow-md">
            <span className="text-[#13407e] text-center px-3 text-xs sm:text-sm lg:text-base">No Photo Available</span>
          </div>
          <h2 className="text-lg sm:text-xl lg:text-2xl font-bold text-[#13407e] mb-1 sm:mb-2 text-center">{userDetails.name}</h2>
          <p className="text-[#0d61b5] font-medium mb-1 text-xs sm:text-sm lg:text-base">{userDetails.id}</p>
          <p className="text-[#13407e]/70 text-xs sm:text-sm lg:text-base">{userDetails.mobile}</p>
        </div>

        {/* Details Grid */}
        <div className="lg:col-span-3 bg-[#49c2c7]/10 rounded-lg sm:rounded-xl lg:rounded-2xl shadow-lg p-3 sm:p-4 md:p-6 lg:p-8 border border-[#25b2da]/20">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4 md:gap-6">
            {Object.entries({
              'Sponsor ID': userDetails.sponsorId,
              'Email': userDetails.email,
              'Address': userDetails.address,
              'City': userDetails.city,
              'State': userDetails.state,
              'Country': userDetails.country,
              'Pin Code': userDetails.pinCode
            }).map(([key, value]) => (
              <div key={key} className={`bg-white p-3 sm:p-4 lg:p-5 rounded-lg sm:rounded-xl border border-[#25b2da]/20 hover:shadow-md transition-shadow duration-300 ${key === 'Address' ? 'sm:col-span-2' : ''}`}>
                <p className="text-xs sm:text-sm font-semibold text-[#13407e]/70 mb-1 sm:mb-2">{key}</p>
                <p className="text-sm sm:text-base lg:text-lg text-[#13407e]">{value}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  const BankProfileTab = () => (
    <div className="p-3 sm:p-4 md:p-6 lg:p-8">
      <div className="bg-[#49c2c7]/10 rounded-lg sm:rounded-xl lg:rounded-2xl shadow-lg p-3 sm:p-4 md:p-6 lg:p-8 border border-[#25b2da]/20">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4 md:gap-6">
          {Object.entries(userDetails.bankDetails).map(([key, value]) => (
            <div key={key} className="bg-white p-3 sm:p-4 lg:p-5 rounded-lg sm:rounded-xl border border-[#25b2da]/20 hover:shadow-md transition-shadow duration-300">
              <p className="text-xs sm:text-sm font-semibold text-[#13407e]/70 mb-1 sm:mb-2">
                {key.split(/(?=[A-Z])/).join(' ')}
              </p>
              <p className="text-sm sm:text-base lg:text-lg text-[#13407e]">{value}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const WalletDetailsTab = () => (
    <div className="p-3 sm:p-4 md:p-6 lg:p-8">
      <div className="bg-[#49c2c7]/10 rounded-lg sm:rounded-xl lg:rounded-2xl shadow-lg p-3 sm:p-4 md:p-6 lg:p-8 border border-[#25b2da]/20">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4 md:gap-6">
          {Object.entries(userDetails.walletDetails).map(([key, value]) => (
            <div key={key} className="bg-white p-3 sm:p-4 lg:p-5 rounded-lg sm:rounded-xl border border-[#25b2da]/20 hover:shadow-md transition-shadow duration-300">
              <p className="text-xs sm:text-sm font-semibold text-[#13407e]/70 mb-1 sm:mb-2">
                {key === 'phonepeId' ? 'PhonePe ID/Mobile no' : 'Google pay ID/Mobile no'}
              </p>
              <p className="text-sm sm:text-base lg:text-lg text-[#13407e]">{value}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#49c2c7]/10 to-[#25b2da]/10 py-3 sm:py-4 md:py-6 lg:py-8">
      <div className="container mx-auto px-2 sm:px-3 md:px-4 lg:px-6">
        <h1 className="text-xl sm:text-2xl lg:text-3xl font-bold text-[#13407e] mb-3 sm:mb-4 md:mb-6 lg:mb-8 px-1">Show Profile</h1>
        <div className="bg-white rounded-lg sm:rounded-xl lg:rounded-2xl shadow-lg overflow-hidden border border-[#25b2da]/20">
          {/* Header with Tabs */}
          <div className="bg-gradient-to-r from-[#13407e] to-[#0d61b5] text-white px-2 sm:px-4 md:px-6">
            <div className="flex flex-col xs:flex-row items-start xs:items-center justify-between gap-2 sm:gap-3">
              {/* Tabs */}
              <div className="flex flex-wrap gap-1 sm:gap-0 w-full xs:w-auto">
                {['personal', 'bank', 'wallet'].map((tab) => (
                  <button
                    key={tab}
                    className={`py-2 sm:py-3 md:py-4 px-3 sm:px-6 md:px-8 font-medium transition-all duration-200 relative text-xs sm:text-sm md:text-base flex-1 xs:flex-none ${
                      activeTab === tab
                        ? 'text-white border-b-2 border-[#2ba974]'
                        : 'text-white/70 hover:text-white'
                    }`}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab.charAt(0).toUpperCase() + tab.slice(1)} Profile
                    {activeTab === tab && (
                      <span className="absolute bottom-0 left-0 w-full h-0.5 bg-gradient-to-r from-[#2ba974] to-[#21783b]"></span>
                    )}
                  </button>
                ))}
              </div>
              {/* Status Badge */}
              <span className="bg-[#2ba974] text-white px-3 sm:px-4 md:px-6 py-1 sm:py-1.5 md:py-2 rounded-full text-xs sm:text-sm font-medium shadow-sm ml-2">
                Active
              </span>
            </div>
          </div>
          
          {/* Content Tabs */}
          {activeTab === 'personal' && <PersonalProfileTab />}
          {activeTab === 'bank' && <BankProfileTab />}
          {activeTab === 'wallet' && <WalletDetailsTab />}
        </div>
      </div>
    </div>
  );
};

export default ShowProfile;