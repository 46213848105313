import React from 'react';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faPhone, faEnvelope, faMapMarkerAlt, faUserPlus, faCheckCircle, faCity, faLock } from "@fortawesome/free-solid-svg-icons";
import { sendApiRequest } from "../utils";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";

const CustomerRegistration = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    try {
      const response = await sendApiRequest('api/customers/register', data, 'POST');
      
      if (response.data.success) {
        Swal.fire({
          title: 'Registration Successful',
          text: 'Customer has been registered successfully',
          icon: 'success',
          confirmButtonText: 'Continue',
          confirmButtonColor: '#2AADCC',
          background: '#ffffff'
        }).then(() => {
          navigate('/');
        });
      } else {
        Swal.fire({
          title: 'Registration Failed',
          text: response.data.message || 'Error registering customer',
          icon: 'error',
          confirmButtonText: 'Try Again',
          confirmButtonColor: '#2AADCC'
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.response?.data?.message || 'Something went wrong',
        icon: 'error',
        confirmButtonText: 'Try Again',
        confirmButtonColor: '#2AADCC'
      });
    }
  };

  const states = [
    "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh",
    "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand",
    "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur",
    "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab",
    "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura",
    "Uttar Pradesh", "Uttarakhand", "West Bengal"
  ];

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-50 via-white to-purple-50 p-6 bg-pattern">
      <div className="bg-white/95 backdrop-blur-2xl shadow-[0_8px_60px_rgb(0,0,0,0.1)] rounded-[2.5rem] p-12 w-full max-w-2xl border border-white/80 transform hover:scale-[1.01] transition-all duration-500">
        <div className="mb-12 text-center relative">
          <div className="absolute -top-24 left-1/2 transform -translate-x-1/2">
          </div>
          <h2 className="text-5xl font-bold bg-gradient-to-r from-blue-600 via-purple-600 to-indigo-600 bg-clip-text text-transparent mt-10">
            Customer Registration
          </h2>
          <p className="text-gray-600 mt-4 text-lg">Join our growing community today</p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
          <div className="bg-gradient-to-br from-blue-50/30 to-purple-50/30 p-10 rounded-[2rem] border border-blue-100/30 space-y-8 shadow-inner">
            {/* Name Field */}
            <div className="relative group transform transition-all duration-300 hover:-translate-y-1 hover:shadow-lg rounded-2xl">
              <FontAwesomeIcon icon={faUser} className="absolute top-4 left-4 text-gray-400 group-hover:text-blue-600 transition-colors duration-300 text-lg" />
              <input
                type="text"
                placeholder="Customer Name"
                {...register("customerName", { required: "Customer name is required" })}
                className="w-full pl-12 pr-4 py-4 rounded-2xl border border-gray-200 bg-white/80 focus:bg-white focus:ring-4 focus:ring-blue-100 focus:border-blue-600 transition-all duration-300 text-gray-700 text-lg hover:border-blue-400 placeholder:text-gray-400 shadow-sm"
              />
              {errors.customerName && 
                <p className="text-red-500 text-sm mt-2 ml-2 flex items-center animate-fadeIn">
                  <span className="mr-1">⚠</span> {errors.customerName.message}
                </p>
              }
            </div>

            {/* Phone Field */}
            <div className="relative group transform transition-all duration-300 hover:-translate-y-1">
              <FontAwesomeIcon icon={faPhone} className="absolute top-4 left-4 text-gray-400 group-hover:text-blue-600 transition-colors duration-300 text-lg" />
              <input
                type="tel"
                placeholder="Phone Number"
                {...register("customerPhone", { 
                  required: "Phone number is required",
                  pattern: { value: /^\d{10}$/, message: "Please enter a valid 10-digit phone number" }
                })}
                className="w-full pl-12 pr-4 py-4 rounded-2xl border border-gray-200 bg-white/80 focus:bg-white focus:ring-4 focus:ring-blue-100 focus:border-blue-600 transition-all duration-300 text-gray-700 text-lg hover:border-blue-400 placeholder:text-gray-400 shadow-sm"
              />
              {errors.customerPhone && 
                <p className="text-red-500 text-sm mt-2 ml-2 flex items-center animate-fadeIn">
                  <span className="mr-1">⚠</span> {errors.customerPhone.message}
                </p>
              }
            </div>

            {/* Email Field */}
            <div className="relative group transform transition-all duration-300 hover:-translate-y-1">
              <FontAwesomeIcon icon={faEnvelope} className="absolute top-4 left-4 text-gray-400 group-hover:text-blue-600 transition-colors duration-300 text-lg" />
              <input
                type="email"
                placeholder="Email Address"
                {...register("customerEmail", { 
                  required: "Email is required",
                  pattern: { value: /^\S+@\S+$/i, message: "Invalid email address" }
                })}
                className="w-full pl-12 pr-4 py-4 rounded-2xl border border-gray-200 bg-white/80 focus:bg-white focus:ring-4 focus:ring-blue-100 focus:border-blue-600 transition-all duration-300 text-gray-700 text-lg hover:border-blue-400 placeholder:text-gray-400 shadow-sm"
              />
              {errors.customerEmail && 
                <p className="text-red-500 text-sm mt-2 ml-2 flex items-center animate-fadeIn">
                  <span className="mr-1">⚠</span> {errors.customerEmail.message}
                </p>
              }
            </div>

            {/* Address Field */}
            <div className="relative group transform transition-all duration-300 hover:-translate-y-1">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="absolute top-4 left-4 text-gray-400 group-hover:text-blue-600 transition-colors duration-300 text-lg" />
              <textarea
                placeholder="Complete Address"
                {...register("customerAddress", { required: "Address is required" })}
                className="w-full pl-12 pr-4 py-4 rounded-2xl border border-gray-200 bg-white/80 focus:bg-white focus:ring-4 focus:ring-blue-100 focus:border-blue-600 transition-all duration-300 text-gray-700 text-lg hover:border-blue-400 placeholder:text-gray-400 shadow-sm min-h-[120px] resize-none"
              />
              {errors.customerAddress && 
                <p className="text-red-500 text-sm mt-2 ml-2 flex items-center animate-fadeIn">
                  <span className="mr-1">⚠</span> {errors.customerAddress.message}
                </p>
              }
            </div>

            {/* City Field */}
            <div className="relative group transform transition-all duration-300 hover:-translate-y-1">
              <FontAwesomeIcon icon={faCity} className="absolute top-4 left-4 text-gray-400 group-hover:text-blue-600 transition-colors duration-300 text-lg" />
              <input
                type="text"
                placeholder="City"
                {...register("city", { required: "City is required" })}
                className="w-full pl-12 pr-4 py-4 rounded-2xl border border-gray-200 bg-white/80 focus:bg-white focus:ring-4 focus:ring-blue-100 focus:border-blue-600 transition-all duration-300 text-gray-700 text-lg hover:border-blue-400 placeholder:text-gray-400 shadow-sm"
              />
              {errors.city && 
                <p className="text-red-500 text-sm mt-2 ml-2 flex items-center animate-fadeIn">
                  <span className="mr-1">⚠</span> {errors.city.message}
                </p>
              }
            </div>

            {/* Password Field */}
            <div className="relative group transform transition-all duration-300 hover:-translate-y-1">
              <FontAwesomeIcon icon={faLock} className="absolute top-4 left-4 text-gray-400 group-hover:text-blue-600 transition-colors duration-300 text-lg" />
              <input
                type="password"
                placeholder="Password"
                {...register("password", { 
                  required: "Password is required",
                  minLength: { value: 6, message: "Password must be at least 6 characters" }
                })}
                className="w-full pl-12 pr-4 py-4 rounded-2xl border border-gray-200 bg-white/80 focus:bg-white focus:ring-4 focus:ring-blue-100 focus:border-blue-600 transition-all duration-300 text-gray-700 text-lg hover:border-blue-400 placeholder:text-gray-400 shadow-sm"
              />
              {errors.password && 
                <p className="text-red-500 text-sm mt-2 ml-2 flex items-center animate-fadeIn">
                  <span className="mr-1">⚠</span> {errors.password.message}
                </p>
              }
            </div>

            {/* State Field */}
            <div className="relative group transform transition-all duration-300 hover:-translate-y-1">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="absolute top-4 left-4 text-gray-400 group-hover:text-blue-600 transition-colors duration-300 text-lg" />
              <select
                {...register("state", { required: "State is required" })}
                className="w-full pl-12 pr-4 py-4 rounded-2xl border border-gray-200 bg-white/80 focus:bg-white focus:ring-4 focus:ring-blue-100 focus:border-blue-600 transition-all duration-300 text-gray-700 text-lg hover:border-blue-400 placeholder:text-gray-400 shadow-sm appearance-none cursor-pointer"
              >
                <option value="">Select State</option>
                {states.map((state, index) => (
                  <option key={index} value={state}>{state}</option>
                ))}
              </select>
              {errors.state && 
                <p className="text-red-500 text-sm mt-2 ml-2 flex items-center animate-fadeIn">
                  <span className="mr-1">⚠</span> {errors.state.message}
                </p>
              }
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="relative w-full group overflow-hidden rounded-2xl bg-white border-2 border-blue-100 p-4 flex items-center justify-center space-x-3 hover:border-blue-400 transition-all duration-300"
            >
              <div className="absolute inset-0 w-full h-full bg-gradient-to-r from-blue-500 to-indigo-600 transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left duration-500"></div>
              <FontAwesomeIcon icon={isSubmitting ? faCheckCircle : faUserPlus} className="text-2xl relative z-10 text-blue-600 group-hover:text-white transition-colors duration-300" />
              <span className="relative z-10 text-lg font-semibold text-blue-600 group-hover:text-white transition-colors duration-300">
                {isSubmitting ? 'Processing...' : 'Register Customer'}
              </span>
            </button>
          </div>
        </form>

        <div className="mt-10 text-center">
          <p className="text-gray-500 text-sm">
            By registering, you agree to our{' '}
            <a href="#" className="text-blue-600 hover:text-blue-800 font-semibold underline decoration-2 underline-offset-4 hover:decoration-4 transition-all duration-300">
              Terms of Service
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CustomerRegistration;