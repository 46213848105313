import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    getValue: (state) => {
      return state;
    },
    setValue: (state, action) => {
      if (action.payload === "Logout") {
        state.length = 0; // Reset the state (clear the array)
      } else {
        state.length = 0; // Clear the previous state
        state.push(action.payload); // Push the new value to the state
      }
    },
  },
});

export const { getValue, setValue } = userInfoSlice.actions;

export default userInfoSlice.reducer;
