import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  FiCopy,
  FiUserPlus,
  FiCheck,
  FiChevronLeft,
  FiChevronRight,
  FiDownload,
  FiMenu,
  FiX
} from 'react-icons/fi';
import {
  BsGraphUp,
  BsPersonCheck,
  BsPeople,
  BsWallet2,
  BsStarFill
} from 'react-icons/bs';

const Overview = () => {
  const [isCopied, setIsCopied] = useState(false);
  const [activeTab, setActiveTab] = useState('account');
  const [currentNewsPage, setCurrentNewsPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(3);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 1024);

  // Stats Data
  const stats = [
    {
      title: 'Direct Active/Inactive',
      value: '9/5',
      bgColor: 'from-violet-500 to-violet-600',
      icon: <BsPersonCheck className="w-5 h-5 sm:w-6 sm:h-6" />,
      trend: 'up'
    },
    {
      title: 'Team Active/Inactive',
      value: '26/16',
      bgColor: 'from-orange-400 to-orange-500',
      icon: <BsPeople className="w-5 h-5 sm:w-6 sm:h-6" />,
      trend: 'up'
    },
    {
      title: 'Business Self/Team',
      value: '1782380.00/5084586.00',
      bgColor: 'from-red-400 to-red-500',
      icon: <BsGraphUp className="w-5 h-5 sm:w-6 sm:h-6" />,
      trend: 'up'
    },
    {
      title: 'Main Wallet/Total Income',
      value: '0.91/361752.75',
      bgColor: 'from-cyan-400 to-cyan-500',
      icon: <BsWallet2 className="w-5 h-5 sm:w-6 sm:h-6" />,
      trend: 'down'
    },
  ];

  // Account Details
  const accountDetails = {
    memberID: 'RVI865271',
    registrationDate: '11-06-2023',
    sponsorID: 'RVI258471',
    mobileNo: '8808613763',
    activationPackage: '1100',
    memberName: 'ARCHANA DEVI',
    sponsorName: 'PRAYAGRAJ PROPERTIES AND DEVELOPERS',
    emailId: 'SUNILKR.ALLD@GMAIL.COM',
  };

  const webLink = 'https://rivinfraheights.com/clog/signup.php?sponsor=RVI865271';

  // News Data
  const newsItems = [
    {
      id: 1,
      date: '1st January',
      title: 'NEW YEAR 2025',
      content: 'HAPPY NEW YEAR to all our valued members! Wishing you prosperity and success in the coming year.',
      isNew: true,
      icon: <BsStarFill className="text-yellow-400" />
    },
    {
      id: 2,
      date: '15th December',
      title: 'Policy Update',
      content: 'Important update: Purane plot Jo book hue hain unki ful payment per 1% ka cashback jab tak position nahin hota. Abhi bhi chalu hai vah chalta rahega abhi bhi jo plot book honge UN per bhi 1% ka cashback position Tak milega. This offer has been extended until further notice.',
      isNew: false
    },
    {
      id: 3,
      date: '10th December',
      title: 'Transfer Rules',
      content: 'Reminder: Koi bhi vyakti purane plots ko usi rate per transfer karne ya use rate per Kisi dusre ko transfer karne ke liye bhi mujhse call appointment ki baat nahin Karega. Please follow the proper procedures.',
      isNew: false
    },
    {
      id: 4,
      date: '5th December',
      title: 'Plot Cancellation',
      content: 'Jo bhi purane plot Ham cancel karne ja rahe hain jinki MI nahin a rahi hain unko ab naye rate per hi becha jaega.',
      isNew: false
    },
    {
      id: 5,
      date: '1st December',
      title: 'New Investment Plans',
      content: 'Exciting new investment opportunities will be launched next month with higher returns.',
      isNew: true
    },
    {
      id: 6,
      date: '25th November',
      title: 'Annual Meet Update',
      content: 'Mark your calendars for our annual meet happening in February 2025 at our corporate headquarters. All members are invited to attend this special event.',
      isNew: false
    }
  ];

  // Transactions Data
  const transactions = [
    { id: 1, refUserId: '', date: '15-07-2023', description: 'Fund withdraw', amount: '9250.00' },
    { id: 2, refUserId: '', date: '01-08-2023', description: 'Fund withdraw', amount: '28300.00' },
    { id: 3, refUserId: '', date: '02-09-2023', description: 'Fund withdraw', amount: '31000.00' },
    { id: 4, refUserId: '', date: '03-10-2023', description: 'Fund withdraw', amount: '8200.00' },
    { id: 5, refUserId: '', date: '03-10-2023', description: 'Fund withdraw', amount: '3100.00' },
  ];

  const handleCopyLink = () => {
    navigator.clipboard.writeText(webLink);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  const handleNewJoining = () => {
    window.open(webLink, '_blank', 'noopener,noreferrer');
  };

  // Handle responsiveness
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setWindowWidth(width);

      // Calculate items per page for news
      if (width < 640) setItemsPerPage(1);
      else if (width < 1024) setItemsPerPage(2);
      else setItemsPerPage(3);

      // Close mobile menu if screen is large
      if (width >= 768) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // News pagination
  const totalNewsPages = Math.ceil(newsItems.length / itemsPerPage);
  const currentNewsItems = newsItems.slice(
    currentNewsPage * itemsPerPage,
    (currentNewsPage + 1) * itemsPerPage
  );

  const goToNextNews = () => {
    setCurrentNewsPage((prev) => (prev + 1) % totalNewsPages);
  };

  const goToPrevNews = () => {
    setCurrentNewsPage((prev) => (prev - 1 + totalNewsPages) % totalNewsPages);
  };

  // Auto-rotate news
  useEffect(() => {
    const interval = setInterval(() => {
      goToNextNews();
    }, 8000);
    return () => clearInterval(interval);
  }, [currentNewsPage, itemsPerPage]);

  // Mobile menu toggle
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Mobile Header */}
      <div className="md:hidden bg-gradient-to-r from-indigo-600 to-blue-600 text-white p-4 flex justify-between items-center">
        <h1 className="text-xl font-bold">Dashboard</h1>
        <button onClick={toggleMobileMenu} className="p-2">
          {isMobileMenuOpen ? <FiX className="w-6 h-6" /> : <FiMenu className="w-6 h-6" />}
        </button>
      </div>

      {/* Mobile Menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden bg-white shadow-lg">
          <div className="flex flex-col p-4 space-y-2">
            <button
              onClick={() => { setActiveTab('account'); setIsMobileMenuOpen(false); }}
              className={`px-4 py-2 rounded-lg font-medium text-left ${activeTab === 'account' ? 'bg-blue-600 text-white' : 'bg-gray-100 text-gray-700'}`}
            >
              Account
            </button>
            <button
              onClick={() => { setActiveTab('news'); setIsMobileMenuOpen(false); }}
              className={`px-4 py-2 rounded-lg font-medium text-left ${activeTab === 'news' ? 'bg-blue-600 text-white' : 'bg-gray-100 text-gray-700'}`}
            >
              News ({newsItems.length})
            </button>
            <button
              onClick={() => { setActiveTab('transactions'); setIsMobileMenuOpen(false); }}
              className={`px-4 py-2 rounded-lg font-medium text-left ${activeTab === 'transactions' ? 'bg-blue-600 text-white' : 'bg-gray-100 text-gray-700'}`}
            >
              Transactions ({transactions.length})
            </button>
          </div>
        </div>
      )}

      <div className="container mx-auto px-4 sm:px-6 py-6">
        {/* Header for Desktop */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="hidden md:flex flex-col md:flex-row md:items-center justify-between gap-4 mb-8"
        >
          <div>
            <h1 className="text-2xl md:text-3xl font-bold text-gray-800">Dashboard Overview</h1>
            <p className="text-gray-500 mt-1">Welcome back, {accountDetails.memberName}</p>
          </div>
          <div className="flex gap-3">
            <motion.button
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.97 }}
              onClick={() => setActiveTab('account')}
              className={`px-4 py-2 rounded-lg font-medium ${activeTab === 'account' ? 'bg-blue-600 text-white' : 'bg-gray-100 text-gray-700'}`}
            >
              Account
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.97 }}
              onClick={() => setActiveTab('news')}
              className={`px-4 py-2 rounded-lg font-medium ${activeTab === 'news' ? 'bg-blue-600 text-white' : 'bg-gray-100 text-gray-700'}`}
            >
              News ({newsItems.length})
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.97 }}
              onClick={() => setActiveTab('transactions')}
              className={`px-4 py-2 rounded-lg font-medium ${activeTab === 'transactions' ? 'bg-blue-600 text-white' : 'bg-gray-100 text-gray-700'}`}
            >
              Transactions ({transactions.length})
            </motion.button>
          </div>
        </motion.div>

        {/* Stats Grid */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6 mb-8"
        >
          {stats.map((stat, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1, type: 'spring', stiffness: 100 }}
              className={`bg-gradient-to-r ${stat.bgColor} rounded-xl sm:rounded-2xl p-4 sm:p-6 text-white relative overflow-hidden shadow-lg hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 min-h-[120px] sm:min-h-[160px] flex flex-col justify-between`}
            >
              <div className="absolute -right-4 -top-4 sm:-right-6 sm:-top-6 w-24 h-24 sm:w-32 sm:h-32 rounded-full bg-white/10 backdrop-blur-sm" />
              <div className="relative z-10">
                <div className="flex justify-between items-start">
                  <div className="p-2 sm:p-3 bg-white/20 rounded-lg sm:rounded-xl backdrop-blur-sm inline-block mb-2 sm:mb-4">
                    {stat.icon}
                  </div>
                  <div className={`text-xs font-semibold px-2 py-1 rounded-full ${stat.trend === 'up' ? 'bg-green-500/30 text-green-100' : 'bg-red-500/30 text-red-100'}`}>
                    {stat.trend === 'up' ? '↑ 12%' : '↓ 5%'}
                  </div>
                </div>
                <h3 className="text-xs sm:text-sm font-medium opacity-90 mb-1 sm:mb-2">{stat.title}</h3>
                <div className="flex flex-wrap items-center gap-1">
                  {stat.value.split('/').map((part, i) => (
                    <React.Fragment key={i}>
                      <span className="text-xl sm:text-2xl font-bold">{part}</span>
                      {i === 0 && <span className="text-lg sm:text-xl font-bold opacity-80">/</span>}
                    </React.Fragment>
                  ))}
                </div>
              </div>
              <div className="absolute -bottom-4 -right-4 sm:-bottom-6 sm:-right-6 w-16 h-16 sm:w-24 sm:h-24 rounded-full bg-white/5 backdrop-blur-sm" />
            </motion.div>
          ))}
        </motion.div>

        {/* Account Summary Section */}
        {activeTab === 'account' && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="bg-white rounded-xl sm:rounded-2xl shadow-lg overflow-hidden border border-gray-100 mb-8 w-full max-w-4xl mx-auto"
          >
            <div className="flex items-center justify-between bg-gradient-to-r from-indigo-600 to-blue-600 text-white p-4 sm:p-6">
              <div>
                <h2 className="text-lg sm:text-xl font-semibold">Account Summary</h2>
                <p className="text-blue-100 text-xs sm:text-sm mt-1">Your complete account details</p>
              </div>
              <span className="px-3 py-1 sm:px-4 sm:py-1.5 bg-white/20 rounded-full text-white text-xs sm:text-sm font-medium flex items-center gap-1 sm:gap-2">
                <BsStarFill className="text-yellow-300 w-3 h-3 sm:w-4 sm:h-4" />
                <span>5.M</span>
              </span>
            </div>

            <div className="p-4 sm:p-6 grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6 md:gap-8">
              <div className="space-y-4 sm:space-y-6">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
                  <div className="space-y-1">
                    <p className="text-gray-500 text-xs sm:text-sm font-medium">Member ID</p>
                    <p className="font-semibold text-gray-800 text-sm sm:text-base">{accountDetails.memberID}</p>
                  </div>
                  <div className="space-y-1">
                    <p className="text-gray-500 text-xs sm:text-sm font-medium">Registration Date</p>
                    <p className="font-semibold text-gray-800 text-sm sm:text-base">{accountDetails.registrationDate}</p>
                  </div>
                  <div className="space-y-1">
                    <p className="text-gray-500 text-xs sm:text-sm font-medium">Sponsor ID</p>
                    <p className="font-semibold text-gray-800 text-sm sm:text-base">{accountDetails.sponsorID}</p>
                  </div>
                  <div className="space-y-1">
                    <p className="text-gray-500 text-xs sm:text-sm font-medium">Mobile No.</p>
                    <p className="font-semibold text-gray-800 text-sm sm:text-base">{accountDetails.mobileNo}</p>
                  </div>
                </div>
              </div>

              <div className="space-y-4 sm:space-y-6">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6">
                  <div className="space-y-1">
                    <p className="text-gray-500 text-xs sm:text-sm font-medium">Activation Package</p>
                    <p className="font-semibold text-gray-800 text-sm sm:text-base">₹{accountDetails.activationPackage}</p>
                  </div>
                  <div className="space-y-1">
                    <p className="text-gray-500 text-xs sm:text-sm font-medium">Member Name</p>
                    <p className="font-semibold text-gray-800 text-sm sm:text-base">{accountDetails.memberName}</p>
                  </div>
                  <div className="space-y-1">
                    <p className="text-gray-500 text-xs sm:text-sm font-medium">Sponsor Name</p>
                    <p className="font-semibold text-gray-800 text-sm sm:text-base">{accountDetails.sponsorName}</p>
                  </div>
                  <div className="space-y-1">
                    <p className="text-gray-500 text-xs sm:text-sm font-medium">Email ID</p>
                    <p className="font-semibold text-gray-800 text-sm sm:text-base break-all">{accountDetails.emailId}</p>
                  </div>
                </div>
              </div>

              <div className="col-span-1 md:col-span-2">
                <div className="space-y-3 sm:space-y-4">
                  <p className="text-gray-700 font-medium text-sm sm:text-base">Your Referral Link</p>
                  <div className="flex flex-col sm:flex-row gap-3 sm:gap-4">
                    <div className="flex-1 relative">
                      <input
                        type="text"
                        value={webLink}
                        readOnly
                        className="w-full p-2 sm:p-3 pr-10 text-xs sm:text-sm border border-gray-200 rounded-lg sm:rounded-xl bg-gray-50 focus:ring-2 focus:ring-blue-500/20 focus:outline-none"
                      />
                      <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        onClick={handleCopyLink}
                        className="absolute right-2 top-2 sm:right-3 sm:top-3 text-gray-500 hover:text-blue-600 transition-colors"
                        aria-label="Copy link"
                      >
                        {isCopied ? <FiCheck className="w-4 h-4 sm:w-5 sm:h-5 text-green-500" /> : <FiCopy className="w-4 h-4 sm:w-5 sm:h-5" />}
                      </motion.button>
                    </div>
                    <motion.button
                      whileHover={{ scale: 1.03 }}
                      whileTap={{ scale: 0.97 }}
                      onClick={handleNewJoining}
                      className="flex items-center justify-center gap-2 bg-gradient-to-r from-teal-500 to-teal-600 text-white px-4 py-2 sm:px-6 sm:py-3 rounded-lg sm:rounded-xl hover:shadow-lg transition-all duration-300 shadow-md text-sm sm:text-base"
                    >
                      <FiUserPlus className="w-4 h-4 sm:w-5 sm:h-5" />
                      <span>New Joining</span>
                    </motion.button>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}

        {/* News and Events Section */}
        {activeTab === 'news' && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="bg-white rounded-xl sm:rounded-2xl shadow-lg overflow-hidden border border-gray-100 mb-8"
          >
            <div className="bg-gradient-to-r from-indigo-600 to-blue-600 text-white p-4 sm:p-6">
              <h2 className="text-lg sm:text-xl font-semibold">News and Events</h2>
              <p className="text-blue-100 text-xs sm:text-sm mt-1">
                Showing {currentNewsPage * itemsPerPage + 1}-{Math.min((currentNewsPage + 1) * itemsPerPage, newsItems.length)} of {newsItems.length} updates
              </p>
            </div>

            <div className="relative min-h-[250px] sm:min-h-[300px] md:min-h-[400px] px-4 sm:px-6 pb-12 sm:pb-16">
              <AnimatePresence mode="wait">
                <motion.div
                  key={currentNewsPage}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -50 }}
                  transition={{ duration: 0.5 }}
                  className="grid gap-4 sm:gap-6 py-4 sm:py-6"
                  style={{
                    gridTemplateColumns: windowWidth < 640 ? '1fr' : windowWidth < 1024 ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)',
                    gridTemplateRows: 'auto',
                  }}
                >
                  {currentNewsItems.map((item) => (
                    <motion.div
                      key={item.id}
                      whileHover={{ scale: 1.01 }}
                      className="flex flex-col gap-3 sm:gap-4 p-3 sm:p-4 bg-gray-50 rounded-lg border border-gray-200 h-full"
                    >
                      <div className="flex items-center gap-2 sm:gap-3">
                        <div className="w-10 h-10 sm:w-12 sm:h-12 rounded-lg bg-blue-50 flex items-center justify-center text-blue-600 font-semibold">
                          {item.icon || <BsStarFill className="w-4 h-4 sm:w-5 sm:h-5" />}
                        </div>
                        <div>
                          <span className="text-xs sm:text-sm font-medium text-gray-500">{item.date}</span>
                          {item.isNew && (
                            <span className="ml-1 sm:ml-2 bg-red-500 text-white text-xxs sm:text-xs px-1.5 py-0.5 sm:px-2 sm:py-0.5 rounded-full">NEW</span>
                          )}
                        </div>
                      </div>
                      <div className="flex-1">
                        <h3 className="text-sm sm:text-lg font-semibold text-blue-600 mb-1 sm:mb-2">{item.title}</h3>
                        <p className="text-gray-700 text-xs sm:text-sm">
                          {item.content}
                        </p>
                      </div>
                    </motion.div>
                  ))}
                </motion.div>
              </AnimatePresence>

              {/* Navigation Controls */}
              {totalNewsPages > 1 && (
                <div className="absolute bottom-2 sm:bottom-4 left-0 right-0 flex justify-center items-center gap-3 sm:gap-4">
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={goToPrevNews}
                    className="p-1 sm:p-2 rounded-full bg-gray-100 text-gray-700 hover:bg-gray-200 shadow-sm"
                    aria-label="Previous news"
                  >
                    <FiChevronLeft className="w-4 h-4 sm:w-5 sm:h-5" />
                  </motion.button>

                  <div className="flex gap-1 sm:gap-2">
                    {Array.from({ length: totalNewsPages }).map((_, index) => (
                      <button
                        key={index}
                        onClick={() => setCurrentNewsPage(index)}
                        className={`w-2 h-2 sm:w-3 sm:h-3 rounded-full transition-all ${currentNewsPage === index ? 'bg-blue-600 scale-110' : 'bg-gray-300'}`}
                        aria-label={`Go to page ${index + 1}`}
                      />
                    ))}
                  </div>

                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={goToNextNews}
                    className="p-1 sm:p-2 rounded-full bg-gray-100 text-gray-700 hover:bg-gray-200 shadow-sm"
                    aria-label="Next news"
                  >
                    <FiChevronRight className="w-4 h-4 sm:w-5 sm:h-5" />
                  </motion.button>
                </div>
              )}
            </div>
          </motion.div>
        )}

        {/* Transactions Section */}
        {activeTab === 'transactions' && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="bg-white rounded-xl sm:rounded-2xl shadow-lg overflow-hidden border border-gray-100 mb-8"
          >
            <div className="flex items-center justify-between bg-gradient-to-r from-indigo-600 to-blue-600 text-white p-4 sm:p-6">
              <h2 className="text-lg sm:text-xl font-semibold">Latest Transactions</h2>
            </div>

            <div className="overflow-x-auto">
              <table className="w-full">
                <thead className="bg-gray-50">
                  <tr className="text-left text-gray-500 text-xs sm:text-sm font-medium border-b border-gray-200">
                    <th className="px-3 py-2 sm:px-6 sm:py-3">S.No.</th>
                    <th className="px-3 py-2 sm:px-6 sm:py-3">Ref User Id</th>
                    <th className="px-3 py-2 sm:px-6 sm:py-3">Date</th>
                    <th className="px-3 py-2 sm:px-6 sm:py-3">Description</th>
                    <th className="px-3 py-2 sm:px-6 sm:py-3 text-right">Amount</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {transactions.map((transaction) => (
                    <motion.tr
                      key={transaction.id}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.3 }}
                      className="hover:bg-gray-50 transition-colors"
                    >
                      <td className="px-3 py-2 sm:px-6 sm:py-4 font-medium text-gray-900 text-xs sm:text-sm">{transaction.id}</td>
                      <td className="px-3 py-2 sm:px-6 sm:py-4 text-gray-500 text-xs sm:text-sm">{transaction.refUserId || '-'}</td>
                      <td className="px-3 py-2 sm:px-6 sm:py-4 text-gray-500 text-xs sm:text-sm">{transaction.date}</td>
                      <td className="px-3 py-2 sm:px-6 sm:py-4 text-gray-500 text-xs sm:text-sm">{transaction.description}</td>
                      <td className="px-3 py-2 sm:px-6 sm:py-4 text-right font-medium text-gray-900 text-xs sm:text-sm">
                        ₹{transaction.amount}
                      </td>
                    </motion.tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="p-3 sm:p-4 border-t border-gray-200 bg-gray-50 text-right">
              <motion.button
                whileHover={{ x: 3 }}
                className="text-xs sm:text-sm text-blue-600 hover:text-blue-800 font-medium"
              >
                View All Transactions →
              </motion.button>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default Overview;