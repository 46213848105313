import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setValue } from '../features/UserInfo/userSlice';
import { checkAuthStatus } from '../utils/auth';

const AuthProvider = ({ children }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const initAuth = async () => {
            const { isAuthenticated, user } = await checkAuthStatus();
            if (isAuthenticated && user) {
                dispatch(setValue(user));
            }
        };

        initAuth();
    }, [dispatch]);

    return <>{children}</>;
};

export default AuthProvider;
