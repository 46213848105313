import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

const UpdateKYC = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [kycHistory, setKycHistory] = useState([
    {
      panNo: 'GTXPD5269J',
      adhaarNo: '913682918698',
      cancelCheque: 'cheque.pdf',
      status: 'Approved'
    }
  ]);

  const onSubmit = (data) => {
    console.log(data);
    // Handle form submission
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#49c2c7]/5 via-[#25b2da]/10 to-[#0d61b5]/5 py-4 sm:py-6 md:py-8">
      <div className="container mx-auto px-3 sm:px-4 md:px-6 max-w-4xl">
        <div className="bg-white/90 backdrop-blur-sm rounded-lg sm:rounded-xl md:rounded-2xl shadow-xl overflow-hidden border border-[#25b2da]/30">
          <div className="bg-gradient-to-r from-[#13407e] via-[#0d61b5] to-[#25b2da] text-white px-4 sm:px-6 py-4 sm:py-5 flex justify-between items-center">
            <h1 className="text-lg sm:text-xl font-semibold">Update KYC</h1>
            <div className="flex space-x-1.5 sm:space-x-2">
              <span className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-[#2ba974] shadow-inner"></span>
              <span className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-[#21783b] shadow-inner"></span>
              <span className="w-2 h-2 sm:w-3 sm:h-3 rounded-full bg-[#49c2c7] shadow-inner"></span>
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="p-4 sm:p-6 md:p-8 space-y-6 sm:space-y-8">
            {/* Personal Details */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6 bg-[#49c2c7]/5 p-4 sm:p-6 rounded-lg sm:rounded-xl border border-[#25b2da]/20">
              <div className="md:col-span-2">
                <h2 className="text-base sm:text-lg font-semibold text-[#13407e] mb-3 sm:mb-4">Personal Information</h2>
              </div>
              <div>
                <label className="block text-sm font-medium text-[#13407e] mb-2">
                  Full Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  value="ARCHANA DEVI"
                  disabled
                  className="w-full px-4 py-3 rounded-lg border bg-gray-50 text-gray-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-[#13407e] mb-2">
                  Mobile <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  value="8808613763"
                  disabled
                  className="w-full px-4 py-3 rounded-lg border bg-gray-50 text-gray-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-[#13407e] mb-2">
                  User ID <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  value="RVI865271"
                  disabled
                  className="w-full px-4 py-3 rounded-lg border bg-gray-50 text-gray-500"
                />
              </div>
            </div>

            {/* KYC Documents */}
            <div className="space-y-4 sm:space-y-6 bg-[#25b2da]/5 p-4 sm:p-6 rounded-lg sm:rounded-xl border border-[#25b2da]/20">
              <h2 className="text-base sm:text-lg font-semibold text-[#13407e] mb-3 sm:mb-4">KYC Documents</h2>
              
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6">
                <div className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-[#13407e] mb-2">
                      PAN No. <span className="text-[#21783b]">*</span>
                    </label>
                    <input
                      type="text"
                      {...register("panNo", { required: "PAN number is required" })}
                      className="w-full px-4 py-3 rounded-lg border border-[#49c2c7]/30 focus:border-[#0d61b5] focus:ring-2 focus:ring-[#25b2da]/20 transition-all duration-300 outline-none bg-white/50"
                      placeholder="Enter PAN number"
                    />
                    {errors.panNo && (
                      <p className="mt-2 text-sm text-[#21783b]">{errors.panNo.message}</p>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-[#13407e] mb-2">
                      PAN Scan Copy <span className="text-[#21783b]">*</span>
                    </label>
                    <div className="relative group">
                      <input
                        type="file"
                        {...register("panScanCopy")}
                        className="w-full px-4 py-3 rounded-lg border border-[#49c2c7]/30 focus:border-[#0d61b5] focus:ring-2 focus:ring-[#25b2da]/20 transition-all duration-300 outline-none bg-white/50 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-medium file:bg-[#25b2da]/10 file:text-[#13407e] hover:file:bg-[#25b2da]/20"
                      />
                    </div>
                  </div>
                </div>

                {/* Similar styling for Aadhaar and Cancel Cheque sections */}
                <div className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-[#13407e] mb-2">
                      Aadhaar No. <span className="text-[#21783b]">*</span>
                    </label>
                    <input
                      type="text"
                      {...register("aadhaarNo", { required: "Aadhaar number is required" })}
                      className="w-full px-4 py-3 rounded-lg border border-[#49c2c7]/30 focus:border-[#0d61b5] focus:ring-2 focus:ring-[#25b2da]/20 transition-all duration-300 outline-none bg-white/50"
                      placeholder="Enter Aadhaar number"
                    />
                    {errors.aadhaarNo && (
                      <p className="mt-2 text-sm text-[#21783b]">{errors.aadhaarNo.message}</p>
                    )}
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-[#13407e] mb-2">
                      Aadhaar Scan Copy <span className="text-[#21783b]">*</span>
                    </label>
                    <div className="relative group">
                      <input
                        type="file"
                        {...register("aadhaarScanCopy")}
                        className="w-full px-4 py-3 rounded-lg border border-[#49c2c7]/30 focus:border-[#0d61b5] focus:ring-2 focus:ring-[#25b2da]/20 transition-all duration-300 outline-none bg-white/50 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-medium file:bg-[#25b2da]/10 file:text-[#13407e] hover:file:bg-[#25b2da]/20"
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-[#13407e] mb-2">
                      Cancel Cheque Copy <span className="text-[#21783b]">*</span>
                    </label>
                    <div className="relative group">
                      <input
                        type="file"
                        {...register("cancelChequeCopy")}
                        className="w-full px-4 py-3 rounded-lg border border-[#49c2c7]/30 focus:border-[#0d61b5] focus:ring-2 focus:ring-[#25b2da]/20 transition-all duration-300 outline-none bg-white/50 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-medium file:bg-[#25b2da]/10 file:text-[#13407e] hover:file:bg-[#25b2da]/20"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-end pt-2 sm:pt-4">
              <button
                type="submit"
                className="w-full sm:w-auto bg-gradient-to-r from-[#13407e] via-[#0d61b5] to-[#25b2da] text-white px-6 sm:px-8 py-2.5 sm:py-3 rounded-lg 
                hover:from-[#0d61b5] hover:via-[#25b2da] hover:to-[#13407e] transition-all duration-500 transform hover:scale-105 
                focus:ring-4 focus:ring-[#25b2da]/20 shadow-md hover:shadow-lg active:scale-95 text-sm sm:text-base"
              >
                Update KYC
              </button>
            </div>
          </form>

          {/* KYC History Table */}
          <div className="p-4 sm:p-6 md:p-8 border-t border-[#25b2da]/20 bg-gradient-to-b from-white to-[#49c2c7]/5">
            <h2 className="text-base sm:text-lg font-semibold text-[#13407e] mb-4 sm:mb-6">KYC History</h2>
            <div className="overflow-x-auto rounded-lg sm:rounded-xl border border-[#25b2da]/20">
              <table className="w-full min-w-[600px]">
                <thead>
                  <tr className="bg-gradient-to-r from-[#49c2c7]/10 to-[#25b2da]/10">
                    <th className="px-4 sm:px-6 py-3 sm:py-4 text-left text-xs font-medium text-[#13407e] uppercase tracking-wider">Document Type</th>
                    <th className="px-4 sm:px-6 py-3 sm:py-4 text-left text-xs font-medium text-[#13407e] uppercase tracking-wider">Number</th>
                    <th className="px-4 sm:px-6 py-3 sm:py-4 text-left text-xs font-medium text-[#13407e] uppercase tracking-wider">Document</th>
                    <th className="px-4 sm:px-6 py-3 sm:py-4 text-left text-xs font-medium text-[#13407e] uppercase tracking-wider">Status</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-[#25b2da]/10">
                  {kycHistory.map((kyc, index) => (
                    <tr key={index} className="hover:bg-[#49c2c7]/5 transition-colors duration-150">
                      <td className="px-4 sm:px-6 py-3 sm:py-4 whitespace-nowrap text-xs sm:text-sm text-[#13407e]">
                        PAN Card
                        <button className="ml-2 text-[#0d61b5] hover:text-[#13407e] font-medium">View</button>
                      </td>
                      <td className="px-4 sm:px-6 py-3 sm:py-4 whitespace-nowrap text-xs sm:text-sm text-[#13407e]">{kyc.panNo}</td>
                      <td className="px-4 sm:px-6 py-3 sm:py-4 whitespace-nowrap text-xs sm:text-sm">
                        <button className="text-[#0d61b5] hover:text-[#13407e] font-medium">View Document</button>
                      </td>
                      <td className="px-4 sm:px-6 py-3 sm:py-4 whitespace-nowrap">
                        <span className="px-2 sm:px-3 py-1 inline-flex text-xs leading-5 font-semibold rounded-full bg-[#2ba974]/10 text-[#21783b]">
                          {kyc.status}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateKYC;