import React from 'react';
import { motion } from 'framer-motion';
import { FaBuilding, FaUsers, FaHandshake, FaChartLine, FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';

// Import images (you'll need to add these to your assets)
import aboutHero from '../assets/images/about-hero.jpg';
import teamMember1 from '../assets/images/team1.jpg';
import teamMember2 from '../assets/images/team2.png';
import teamMember3 from '../assets/images/team3.png';

const About = () => {
  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <section className="relative h-[60vh] bg-cover bg-center" style={{ backgroundImage: `url(${aboutHero})` }}>
        <div className="absolute inset-0 bg-gradient-to-r from-blue-900/90 to-black/80"></div>
        <div className="relative h-full max-w-7xl mx-auto px-4 flex items-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-white"
          >
            <h1 className="text-5xl font-bold mb-6">About Our Network</h1>
            <p className="text-xl text-gray-300 max-w-2xl">
              Leading Real Estate MLM Network in Prayagraj, connecting professionals and creating opportunities for growth and success.
            </p>
          </motion.div>
        </div>
      </section>

      {/* Vision & Mission */}
      <section className="py-20 bg-gradient-to-b from-gray-50 to-white">
        <div className="max-w-7xl mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-16 items-center">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
            >
              <span className="text-yellow-500 font-semibold mb-4 block">Our Vision</span>
              <h2 className="text-4xl font-bold mb-8 text-gray-800">
                Transforming Real Estate Business in Prayagraj
              </h2>
              <p className="text-gray-600 mb-6 leading-relaxed">
                We envision becoming the most trusted and innovative real estate network in Prayagraj,
                creating opportunities for professionals to build successful careers in real estate.
              </p>
              <div className="grid grid-cols-2 gap-6">
                {[
                  { number: '500+', label: 'Network Partners' },
                  { number: '1000+', label: 'Properties' },
                  { number: '50Cr+', label: 'Property Value' },
                  { number: '100+', label: 'Successful Deals' }
                ].map((stat, index) => (
                  <div key={index} className="bg-white p-6 rounded-xl shadow-lg">
                    <div className="text-2xl font-bold text-yellow-500">{stat.number}</div>
                    <div className="text-gray-600">{stat.label}</div>
                  </div>
                ))}
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              className="grid grid-cols-2 gap-6"
            >
              {[
                { icon: FaBuilding, title: 'Premium Properties' },
                { icon: FaUsers, title: 'Expert Network' },
                { icon: FaHandshake, title: 'Trusted Partners' },
                { icon: FaChartLine, title: 'Growth Focused' }
              ].map((feature, index) => (
                <div key={index} className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow">
                  <feature.icon className="text-4xl text-yellow-500 mb-4" />
                  <h3 className="text-lg font-semibold text-gray-800">{feature.title}</h3>
                </div>
              ))}
            </motion.div>
          </div>
        </div>
      </section>

      {/* Team Section */}
      <section className="py-20 bg-gray-900">
        <div className="max-w-7xl mx-auto px-4">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <span className="text-yellow-400 font-semibold mb-4 block">Our Leadership</span>
            <h2 className="text-4xl font-bold text-white mb-6">Meet Our Team</h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
              Our experienced leadership team brings decades of real estate expertise to guide our network partners towards success.
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {[
              { name: 'Rajesh Kumar', role: 'Founder & CEO', image: teamMember1 },
              { name: 'Priya Sharma', role: 'Operations Director', image: teamMember2 },
              { name: 'Amit Patel', role: 'Network Growth Head', image: teamMember3 }
            ].map((member, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.2 }}
                className="group relative overflow-hidden rounded-2xl bg-gradient-to-b from-gray-800 to-gray-900"
              >
                <div className="aspect-w-3 aspect-h-4">
                  <img src={member.image} alt={member.name} className="object-cover group-hover:scale-110 transition-transform duration-500" />
                </div>
                <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent">
                  <div className="absolute bottom-0 p-6">
                    <h3 className="text-xl font-bold text-white mb-2">{member.name}</h3>
                    <p className="text-yellow-400">{member.role}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="py-20 bg-gradient-to-b from-gray-50 to-white">
        <div className="max-w-7xl mx-auto px-4">
          <div className="bg-white rounded-2xl shadow-2xl p-12">
            <div className="grid md:grid-cols-2 gap-12 items-center">
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
              >
                <span className="text-yellow-500 font-semibold mb-4 block">Get In Touch</span>
                <h2 className="text-4xl font-bold mb-8 text-gray-800">Visit Our Office</h2>
                <div className="space-y-6">
                  <div className="flex items-start">
                    <FaMapMarkerAlt className="text-yellow-500 text-2xl mt-1 mr-4" />
                    <div>
                      <h3 className="font-semibold text-gray-800 mb-1">Office Address</h3>
                      <p className="text-gray-600">Civil Lines, Prayagraj, Uttar Pradesh, 211001</p>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <FaPhone className="text-yellow-500 text-2xl mt-1 mr-4" />
                    <div>
                      <h3 className="font-semibold text-gray-800 mb-1">Phone</h3>
                      <p className="text-gray-600">+91 XXXXX XXXXX</p>
                    </div>
                  </div>
                  <div className="flex items-start">
                    <FaEnvelope className="text-yellow-500 text-2xl mt-1 mr-4" />
                    <div>
                      <h3 className="font-semibold text-gray-800 mb-1">Email</h3>
                      <p className="text-gray-600">contact@realestate-mlm.com</p>
                    </div>
                  </div>
                </div>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                className="h-[400px] rounded-xl overflow-hidden"
              >
                <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d1801.627566092389!2d81.76728193837714!3d25.429732516729665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1s%20567%20Saha%20Urf%20Pipalgaon%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20Near%20IIIT-A%20Chouraha%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20%20210112!5e0!3m2!1sen!2sin!4v1743835419597!5m2!1sen!2sin" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </motion.div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;