import React from 'react';
import { useForm } from 'react-hook-form';

const BuyPackage = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    // Handle form submission
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#49c2c7]/5 via-[#25b2da]/10 to-[#0d61b5]/5 py-8">
      <div className="container mx-auto px-4 max-w-3xl">
        <div className="bg-white rounded-2xl shadow-xl overflow-hidden border border-[#25b2da]/20">
          <div className="bg-gradient-to-r from-[#13407e] to-[#0d61b5] text-white px-6 py-4">
            <h1 className="text-xl font-semibold">Buy Package</h1>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="p-6 space-y-6">
            {/* Personal Details */}
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-[#13407e] mb-2">
                  Full Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  value="ARCHANA DEVI"
                  disabled
                  className="w-full px-4 py-3 rounded-lg border bg-gray-50 text-gray-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-[#13407e] mb-2">
                  Mobile <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  value="8808613763"
                  disabled
                  className="w-full px-4 py-3 rounded-lg border bg-gray-50 text-gray-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-[#13407e] mb-2">
                  User ID <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  value="RVI865271"
                  disabled
                  className="w-full px-4 py-3 rounded-lg border bg-gray-50 text-gray-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-[#13407e] mb-2">
                  Package <span className="text-red-500">*</span>
                </label>
                <select
                  {...register("package", { required: "Please select a package" })}
                  className="w-full px-4 py-3 rounded-lg border border-[#49c2c7] focus:border-[#0d61b5] focus:ring-2 focus:ring-[#25b2da]/20 transition-all duration-200 outline-none"
                >
                  <option value="">Select Project</option>
                  <option value="basic">Basic Package</option>
                  <option value="premium">Premium Package</option>
                  <option value="elite">Elite Package</option>
                </select>
                {errors.package && (
                  <p className="mt-1 text-sm text-red-500">{errors.package.message}</p>
                )}
              </div>
            </div>

            <div className="flex justify-center pt-4">
              <button
                type="submit"
                className="bg-gradient-to-r from-[#13407e] via-[#0d61b5] to-[#25b2da] text-white px-8 py-3 rounded-lg 
                hover:from-[#0d61b5] hover:via-[#25b2da] hover:to-[#13407e] transition-all duration-500 transform hover:scale-105 
                focus:ring-4 focus:ring-[#25b2da]/20 shadow-md hover:shadow-lg active:scale-95"
              >
                Send Request
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BuyPackage;