import React from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faPhone, faEnvelope, faLock, faMapMarkerAlt, faCheckCircle, faUserFriends, faCity, faGlobe } from "@fortawesome/free-solid-svg-icons";
import { sendApiRequest } from "../utils";
import Swal from 'sweetalert2'
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";

const SignUpForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const navigate = useNavigate()

  const onSubmit = async (data) => {
    const response = await sendApiRequest('api/auth/register', data, "POST")
    console.log(response);
    if (response.data.success) {
      // Show success alert
      // Replace MySwal.fire() with Swal.fire()
      Swal.fire({
        title: 'User Registered Successfully',
        icon: 'success',
        confirmButtonText: 'Continue',
        confirmButtonColor: '#2AADCC',
        background: '#ffffff'
      }).then(() => {
        // Store token and user data
        localStorage.setItem('token', response.data.data.token);
        // localStorage.setItem('user', JSON.stringify(response.data.user));

        // Navigate to dashboard
        navigate('/login');
      });
    } else {
      // Show error alert if login failed
      Swal.fire({
        title: 'Login Failed',
        text: response.data.message || 'Invalid credentials',
        icon: 'error',
        confirmButtonText: 'Try Again',
        confirmButtonColor: '#2AADCC'
      });
    }
  };

  const states = [
    "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh",
    "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand",
    "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur",
    "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab",
    "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura",
    "Uttar Pradesh", "Uttarakhand", "West Bengal"
  ];

  const ranks = ["Associate"];
  const countries = ["India", "USA", "UK", "Canada", "Australia"];

  return (
    <div className="min-h-screen flex items-center justify-center bg-[#FFFFFF] bg-cover bg-center p-6">
      <div className="bg-white/95 backdrop-blur-xl shadow-[0_8px_40px_rgba(42,173,204,0.12)] rounded-3xl p-10 w-full max-w-xl border border-[#2AADCC]/20 transform transition-all duration-300">
        <div className="mb-10 text-center">
          <h2 className="text-4xl font-bold bg-gradient-to-r from-[#262362] to-[#2AADCC] bg-clip-text text-transparent">
            Welcome to Our Community
          </h2>
          <p className="text-[#000000] mt-3 text-lg">Create your account and get started</p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
          {/* Update all input and select fields with new colors */}
          <div className="relative group">
            <FontAwesomeIcon icon={faUser} className="absolute top-4 left-4 text-gray-400 group-hover:text-[#2AADCC] transition-colors duration-300 text-lg" />
            <input
              type="text"
              placeholder="Full Name"
              {...register("fullName", { required: "Full Name is required" })}
              className="w-full pl-12 pr-4 py-4 rounded-2xl border border-gray-200 bg-white/50 focus:bg-white focus:ring-4 focus:ring-blue-100 focus:border-blue-600 transition-all duration-300 text-gray-700 text-lg hover:border-blue-400 placeholder:text-gray-400"
            />
            {errors.fullName && <p className="text-red-500 text-sm mt-2 ml-2 flex items-center"><span className="mr-1">⚠</span> {errors.fullName.message}</p>}
          </div>

          <div className="relative group">
            <FontAwesomeIcon icon={faEnvelope} className="absolute top-4 left-4 text-gray-400 group-hover:text-blue-600 transition-colors duration-300 text-lg" />
            <input
              type="email"
              placeholder="Email Address"
              {...register("email", {
                required: "Email is required",
                pattern: { value: /^\S+@\S+$/i, message: "Invalid email address" }
              })}
              className="w-full pl-12 pr-4 py-4 rounded-2xl border border-gray-200 bg-white/50 focus:bg-white focus:ring-4 focus:ring-blue-100 focus:border-blue-600 transition-all duration-300 text-gray-700 text-lg hover:border-blue-400 placeholder:text-gray-400"
            />
            {errors.email && <p className="text-red-500 text-sm mt-2 ml-2 flex items-center"><span className="mr-1">⚠</span> {errors.email.message}</p>}
          </div>

          <div className="relative group">
            <FontAwesomeIcon icon={faUserFriends} className="absolute top-4 left-4 text-gray-400 group-hover:text-blue-600 transition-colors duration-300 text-lg" />
            <input
              type="text"
              placeholder="Sponsor ID"
              {...register("sponsor",)}
              className="w-full pl-12 pr-4 py-4 rounded-2xl border border-gray-200 bg-white/50 focus:bg-white focus:ring-4 focus:ring-blue-100 focus:border-blue-600 transition-all duration-300 text-gray-700 text-lg hover:border-blue-400 placeholder:text-gray-400"
            />
            {errors.sponsor && <p className="text-red-500 text-sm mt-2 ml-2 flex items-center"><span className="mr-1">⚠</span> {errors.sponsor.message}</p>}
          </div>

          <div className="relative group">
            <FontAwesomeIcon icon={faPhone} className="absolute top-4 left-4 text-gray-400 group-hover:text-blue-600 transition-colors duration-300 text-lg" />
            <input
              type="tel"
              placeholder="Phone Number"
              {...register("phone", {
                required: "Phone number is required",
                pattern: { value: /^\d{10}$/, message: "Please enter a valid 10-digit phone number" }
              })}
              className="w-full pl-12 pr-4 py-4 rounded-2xl border border-gray-200 bg-white/50 focus:bg-white focus:ring-4 focus:ring-blue-100 focus:border-blue-600 transition-all duration-300 text-gray-700 text-lg hover:border-blue-400 placeholder:text-gray-400"
            />
            {errors.phone && <p className="text-red-500 text-sm mt-2 ml-2 flex items-center"><span className="mr-1">⚠</span> {errors.phone.message}</p>}
          </div>

          <div className="relative group">
            <FontAwesomeIcon icon={faCity} className="absolute top-4 left-4 text-gray-400 group-hover:text-blue-600 transition-colors duration-300 text-lg" />
            <input
              type="text"
              placeholder="City"
              {...register("city", { required: "City is required" })}
              className="w-full pl-12 pr-4 py-4 rounded-2xl border border-gray-200 bg-white/50 focus:bg-white focus:ring-4 focus:ring-blue-100 focus:border-blue-600 transition-all duration-300 text-gray-700 text-lg hover:border-blue-400 placeholder:text-gray-400"
            />
            {errors.city && <p className="text-red-500 text-sm mt-2 ml-2 flex items-center"><span className="mr-1">⚠</span> {errors.city.message}</p>}
          </div>

          <div className="relative group">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="absolute top-4 left-4 text-gray-400 group-hover:text-blue-600 transition-colors duration-300 text-lg" />
            <select
              {...register("state", { required: "State is required" })}
              className="w-full pl-12 pr-12 py-4 rounded-2xl border border-gray-200 bg-white/50 focus:bg-white focus:ring-4 focus:ring-blue-100 focus:border-blue-600 transition-all duration-300 text-gray-700 text-lg hover:border-blue-400 appearance-none cursor-pointer"
            >
              <option value="">Select State</option>
              {states.map((state, index) => (
                <option key={index} value={state}>{state}</option>
              ))}
            </select>
            <div className="absolute right-4 top-4 pointer-events-none">
              <svg className="w-6 h-6 text-gray-400 group-hover:text-blue-600 transition-colors duration-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
            {errors.state && <p className="text-red-500 text-sm mt-2 ml-2 flex items-center"><span className="mr-1">⚠</span> {errors.state.message}</p>}
          </div>

          <div className="relative group">
            <FontAwesomeIcon icon={faGlobe} className="absolute top-4 left-4 text-gray-400 group-hover:text-blue-600 transition-colors duration-300 text-lg" />
            <select
              {...register("country", { required: "Country is required" })}
              className="w-full pl-12 pr-12 py-4 rounded-2xl border border-gray-200 bg-white/50 focus:bg-white focus:ring-4 focus:ring-blue-100 focus:border-blue-600 transition-all duration-300 text-gray-700 text-lg hover:border-blue-400 appearance-none cursor-pointer"
            >
              <option value="">Select Country</option>
              {countries.map((country, index) => (
                <option key={index} value={country}>{country}</option>
              ))}
            </select>
            <div className="absolute right-4 top-4 pointer-events-none">
              <svg className="w-6 h-6 text-gray-400 group-hover:text-blue-600 transition-colors duration-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
            {errors.country && <p className="text-red-500 text-sm mt-2 ml-2 flex items-center"><span className="mr-1">⚠</span> {errors.country.message}</p>}
          </div>

          <div className="relative group">
            <FontAwesomeIcon icon={faCheckCircle} className="absolute top-4 left-4 text-gray-400 group-hover:text-blue-600 transition-colors duration-300 text-lg" />
            <select
              {...register("rank", { required: "Rank is required" })}
              className="w-full pl-12 pr-12 py-4 rounded-2xl border border-gray-200 bg-white/50 focus:bg-white focus:ring-4 focus:ring-blue-100 focus:border-blue-600 transition-all duration-300 text-gray-700 text-lg hover:border-blue-400 appearance-none cursor-pointer"
            >
              <option value="">Select Rank</option>
              {ranks.map((rank, index) => (
                <option key={index} value={rank}>{rank}</option>
              ))}
            </select>
            <div className="absolute right-4 top-4 pointer-events-none">
              <svg className="w-6 h-6 text-gray-400 group-hover:text-blue-600 transition-colors duration-300" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
            {errors.rank && <p className="text-red-500 text-sm mt-2 ml-2 flex items-center"><span className="mr-1">⚠</span> {errors.rank.message}</p>}
          </div>

          <div className="relative group">
            <FontAwesomeIcon icon={faUser} className="absolute top-4 left-4 text-gray-400 group-hover:text-blue-600 transition-colors duration-300 text-lg" />
            <input
              type="text"
              placeholder="Username"
              {...register("username", { required: "Username is required" })}
              className="w-full pl-12 pr-4 py-4 rounded-2xl border border-gray-200 bg-white/50 focus:bg-white focus:ring-4 focus:ring-blue-100 focus:border-blue-600 transition-all duration-300 text-gray-700 text-lg hover:border-blue-400 placeholder:text-gray-400"
            />
            {errors.username && <p className="text-red-500 text-sm mt-2 ml-2 flex items-center"><span className="mr-1">⚠</span> {errors.username.message}</p>}
          </div>

          <div className="relative group">
            <FontAwesomeIcon icon={faLock} className="absolute top-4 left-4 text-gray-400 group-hover:text-blue-600 transition-colors duration-300 text-lg" />
            <input
              type="password"
              placeholder="Password"
              {...register("password", {
                required: "Password is required",
                minLength: { value: 8, message: "Password must be at least 8 characters long" }
              })}
              className="w-full pl-12 pr-4 py-4 rounded-2xl border border-gray-200 bg-white/50 focus:bg-white focus:ring-4 focus:ring-blue-100 focus:border-blue-600 transition-all duration-300 text-gray-700 text-lg hover:border-blue-400 placeholder:text-gray-400"
            />
            {errors.password && <p className="text-red-500 text-sm mt-2 ml-2 flex items-center"><span className="mr-1">⚠</span> {errors.password.message}</p>}
          </div>

          <div className="flex items-center space-x-3 bg-blue-50/50 p-5 rounded-2xl border border-blue-100">
            <input
              type="checkbox"
              {...register("terms", { required: "You must accept the terms" })}
              className="w-5 h-5 text-blue-600 rounded-lg focus:ring-4 focus:ring-blue-200 border-gray-300 cursor-pointer"
            />
            <label className="text-gray-700 text-base">
              I agree to the <a href="#" className="text-blue-600 hover:text-blue-800 font-semibold underline decoration-2 underline-offset-2">Terms of Usage</a>
            </label>
            {errors.terms && <p className="text-red-500 text-sm mt-2 ml-2 flex items-center"><span className="mr-1">⚠</span> {errors.terms.message}</p>}
          </div>

          {isSubmitting ? <Loader /> : <><button
            type="submit"
            className="w-full bg-gradient-to-r from-blue-600 to-indigo-600 text-white py-4 rounded-2xl hover:from-blue-700 hover:to-indigo-700 transition-all duration-300 transform hover:-translate-y-1 font-semibold text-lg shadow-[0_8px_25px_rgb(37,99,235,0.3)] hover:shadow-[0_8px_30px_rgb(37,99,235,0.45)] focus:ring-4 focus:ring-blue-100 active:scale-[0.98]"
          >
            Create Account
          </button></>}


        </form>
      </div>
    </div>
  );
};

export default SignUpForm;