import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa6";

const UpdateLoginPassword = () => {
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const [showPassword, setShowPassword] = useState({
    current: false,
    new: false,
    confirm: false
  });

  const onSubmit = (data) => {
    console.log(data);
    // Handle password update logic here
  };

  const togglePasswordVisibility = (field) => {
    setShowPassword(prev => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-[#49c2c7]/5 via-[#25b2da]/10 to-[#0d61b5]/5 py-4 sm:py-8">
      <div className="container mx-auto px-3 sm:px-4 max-w-2xl">
        <div className="bg-white/90 backdrop-blur-sm rounded-xl sm:rounded-2xl shadow-xl overflow-hidden border border-[#25b2da]/30">
          <div className="bg-gradient-to-r from-[#13407e] via-[#0d61b5] to-[#25b2da] text-white px-4 sm:px-6 py-4 sm:py-5 flex justify-between items-center">
            <h1 className="text-lg sm:text-xl font-semibold">Update Login Password</h1>
            <div className="flex space-x-1.5 sm:space-x-2">
              <span className="w-2 sm:w-3 h-2 sm:h-3 rounded-full bg-[#2ba974] shadow-inner"></span>
              <span className="w-2 sm:w-3 h-2 sm:h-3 rounded-full bg-[#21783b] shadow-inner"></span>
              <span className="w-2 sm:w-3 h-2 sm:h-3 rounded-full bg-[#49c2c7] shadow-inner"></span>
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)} className="p-4 sm:p-6 md:p-8 space-y-4 sm:space-y-6">
            <div className="space-y-4 sm:space-y-6">
              {/* Password fields */}
              <div>
                <label className="block text-sm font-medium text-[#13407e] mb-1.5 sm:mb-2">
                  Current Password
                </label>
                <div className="relative group">
                  <input
                    type={showPassword.current ? "text" : "password"}
                    {...register("currentPassword", { required: "Current password is required" })}
                    className="w-full px-3 sm:px-4 py-2.5 sm:py-3 text-sm sm:text-base rounded-lg border border-[#49c2c7]/30 focus:border-[#0d61b5] focus:ring-2 focus:ring-[#25b2da]/20 transition-all duration-300 outline-none pr-12 bg-[#49c2c7]/5 group-hover:bg-[#49c2c7]/10"
                    placeholder="Enter current password"
                  />
                  <button
                    type="button"
                    onClick={() => togglePasswordVisibility('current')}
                    className="absolute right-2 sm:right-3 top-1/2 -translate-y-1/2 text-[#13407e]/60 hover:text-[#0d61b5] transition-colors duration-200"
                  >
                    {showPassword.current ? (
                      <FaEyeSlash className="w-5 h-5" />
                    ) : (
                      <FaEye className="w-5 h-5" />
                    )}
                  </button>
                </div>
                {errors.currentPassword && (
                  <p className="mt-1.5 sm:mt-2 text-xs sm:text-sm text-[#21783b]">{errors.currentPassword.message}</p>
                )}
              </div>

              {/* Update similar styling for New Password and Confirm Password fields */}
              <div>
                <label className="block text-sm font-medium text-[#13407e] mb-2">
                  New Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword.new ? "text" : "password"}
                    {...register("newPassword", { 
                      required: "New password is required",
                      minLength: { value: 8, message: "Password must be at least 8 characters" }
                    })}
                    className="w-full px-3 sm:px-4 py-2.5 sm:py-3 text-sm sm:text-base rounded-lg border border-[#49c2c7]/30 focus:border-[#0d61b5] focus:ring-2 focus:ring-[#25b2da]/20 transition-all duration-300 outline-none pr-12"
                    placeholder="Enter new password"
                  />
                  <button
                    type="button"
                    onClick={() => togglePasswordVisibility('new')}
                    className="absolute right-2 sm:right-3 top-1/2 -translate-y-1/2 text-[#13407e]/60 hover:text-[#0d61b5] transition-colors duration-200"
                  >
                    {showPassword.new ? (
                      <FaEyeSlash className="w-5 h-5" />
                    ) : (
                      <FaEye className="w-5 h-5" />
                    )}
                  </button>
                </div>
                {errors.newPassword && (
                  <p className="mt-1 text-sm text-red-500">{errors.newPassword.message}</p>
                )}
              </div>

              {/* Confirm New Password */}
              <div>
                <label className="block text-sm font-medium text-[#13407e] mb-2">
                  Re-Type New Password
                </label>
                <div className="relative">
                  <input
                    type={showPassword.confirm ? "text" : "password"}
                    {...register("confirmPassword", {
                      required: "Please confirm your password",
                      validate: value => value === watch('newPassword') || "Passwords do not match"
                    })}
                    className="w-full px-3 sm:px-4 py-2.5 sm:py-3 text-sm sm:text-base rounded-lg border border-[#49c2c7]/30 focus:border-[#0d61b5] focus:ring-2 focus:ring-[#25b2da]/20 transition-all duration-300 outline-none pr-12"
                    placeholder="Confirm new password"
                  />
                  <button
                    type="button"
                    onClick={() => togglePasswordVisibility('confirm')}
                    className="absolute right-2 sm:right-3 top-1/2 -translate-y-1/2 text-[#13407e]/60 hover:text-[#0d61b5] transition-colors duration-200"
                  >
                    {showPassword.confirm ? (
                      <FaEyeSlash className="w-5 h-5" />
                    ) : (
                      <FaEye className="w-5 h-5" />
                    )}
                  </button>
                </div>
                {errors.confirmPassword && (
                  <p className="mt-1 text-sm text-red-500">{errors.confirmPassword.message}</p>
                )}
              </div>
            </div>

            <div className="pt-4 sm:pt-6">
              <button
                type="submit"
                className="w-full bg-gradient-to-r from-[#13407e] via-[#0d61b5] to-[#25b2da] text-white px-6 sm:px-8 py-2.5 sm:py-3 text-sm sm:text-base rounded-lg 
                hover:from-[#0d61b5] hover:via-[#25b2da] hover:to-[#13407e] transition-all duration-500 transform hover:scale-[1.02] 
                focus:ring-4 focus:ring-[#25b2da]/20 shadow-md hover:shadow-lg active:scale-[0.98]"
              >
                Update Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateLoginPassword;